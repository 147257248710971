export const House6Icon = () => (
  <svg
    width="362"
    height="280"
    viewBox="0 0 362 280"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10589_48547)">
      <g clip-path="url(#clip1_10589_48547)">
        <path
          d="M89.7321 109.725L75.8686 113.186C75.4201 111.36 75.325 109.466 75.5968 107.598C75.8686 105.731 76.521 103.946 77.486 102.338C78.451 100.73 79.7286 99.3258 81.2373 98.2083C82.746 97.0907 84.4585 96.2866 86.2798 95.8232L89.7321 109.725Z"
          fill="#008CE7"
        />
        <path
          d="M10.2073 279.006H187.633V165.97L94.4075 91.98L10.2073 165.97V279.006Z"
          fill="#E9F6FF"
        />
        <path
          d="M187.646 279.006H310.08V172.294H187.646V279.006Z"
          fill="#C7DEEE"
        />
        <path
          opacity="0.8"
          d="M35.7461 205.711V279.007H42.1749H72.1717H79.8646V205.711H35.7461Z"
          fill="#B8D0E7"
        />
        <path
          d="M10.2072 178.222L94.4075 110.024L187.633 189.739V172.294L94.4075 91.98L5.11035 164.321L10.2072 178.222Z"
          fill="#C7DEEE"
        />
        <path
          d="M221.218 81.8813H94.4076L85.043 89.6497L0 162.972V178.223H10.2073V169.473L94.2309 97.5135L187.633 177.527L316.617 177.868V165.97L221.218 81.8813Z"
          fill="#D4E6F0"
        />
        <path
          opacity="0.8"
          d="M108.679 205.766H98.5259V243.218V251.545H108.679H130.833V243.218V205.766H108.679Z"
          fill="#B8D0E7"
        />
        <path
          opacity="0.8"
          d="M145.906 205.766H135.767V243.218V251.545H145.906H168.074V243.218V205.766H145.906Z"
          fill="#B8D0E7"
        />
        <path
          d="M361.973 278.843H276.549C276.359 275.681 276.305 272.506 276.903 269.398C277.623 265.623 279.444 261.902 282.584 259.708C285.71 257.5 290.277 257.132 293.294 259.49C296.04 261.643 296.95 265.378 297.304 268.867C298.187 256.901 302.958 245.712 311.357 237.166C313.152 235.34 315.136 233.609 317.514 232.642C319.866 231.66 322.693 231.51 324.922 232.764C328.13 234.563 329.271 238.611 329.557 242.277C330.291 251.844 327.178 261.235 324.093 270.311C326.444 263.947 329.964 258.836 335.578 255.047C338.867 252.839 343.108 251.218 346.723 252.839C350.066 254.338 351.793 258.264 351.752 261.943C351.697 265.609 350.243 269.098 348.734 272.437C350.08 270.161 352.309 269.562 354.932 269.862C357.569 270.161 359.934 271.947 361.103 274.332C361.782 275.736 362.082 277.303 361.973 278.843Z"
          fill="#B8D0E7"
        />
        <path
          d="M251.092 279.551H325.819C324.066 274.522 321.538 269.752 317.813 265.977C313.219 261.344 306.614 258.4 300.171 259.354C291.364 260.648 284.922 268.676 281.918 277.071C278.289 270.543 270.093 267.299 262.862 269.098C257.562 270.407 253.144 274.509 251.092 279.538V279.551Z"
          fill="#E1EEF5"
        />
      </g>
      <g clip-path="url(#clip2_10589_48547)">
        <path
          d="M162.704 149.832L162.794 149.594C162.761 149.662 162.713 149.735 162.678 149.796L162.712 149.83L162.704 149.832Z"
          fill="white"
        />
        <path
          d="M137.281 130.207L129.748 139.426L140.758 148.498L148.291 139.279L137.281 130.207Z"
          fill="#BBD3EA"
        />
        <path
          d="M54.1044 70.9806C36.3243 93.815 40.381 126.831 63.1685 144.728C85.9559 162.624 118.84 158.624 136.62 135.789C154.4 112.955 150.343 79.9387 127.556 62.0422C104.768 44.1457 71.8845 48.1462 54.1044 70.9806ZM130.056 130.632C115.103 149.832 87.4537 153.201 68.2926 138.15C49.1315 123.1 45.7159 95.3389 60.6684 76.1383C75.6209 56.9377 103.271 53.5693 122.432 68.6197C141.593 83.6701 145.008 111.431 130.056 130.632Z"
          fill="#46B6FF"
        />
        <path
          d="M137.505 152.492L151.554 135.299C151.841 134.948 152.362 134.897 152.713 135.186L202.401 176.127C203.459 176.998 203.613 178.557 202.747 179.617L190.785 194.257C189.919 195.317 188.366 195.469 187.309 194.597L137.621 153.656C137.27 153.367 137.218 152.844 137.505 152.492Z"
          fill="#273653"
        />
        <path
          d="M141.728 127.846L141.794 127.649C141.767 127.707 141.73 127.76 141.703 127.819L141.728 127.846Z"
          fill="white"
        />
        <path
          d="M127.556 62.0422C111.979 49.8065 91.6801 47.8153 74.7128 55.0752C72.2962 56.108 69.9692 57.3478 67.711 58.749C62.6337 61.907 57.9961 65.9745 54.1044 70.9806C36.3243 93.815 40.381 126.831 63.1685 144.728C85.9559 162.624 118.84 158.624 136.62 135.789C138.598 133.248 140.26 130.577 141.695 127.821C141.722 127.762 141.759 127.71 141.786 127.651C142.819 125.654 143.704 123.611 144.46 121.533C145.278 119.277 145.942 116.984 146.444 114.659C150.568 95.569 143.931 74.9029 127.556 62.0422ZM135.613 121.216C134.175 124.503 132.358 127.678 130.058 130.64C115.105 149.84 87.4559 153.209 68.2948 138.158C49.1337 123.108 45.718 95.3468 60.6705 76.1463C64.576 71.1279 69.3684 67.2244 74.6359 64.4263C76.6365 63.3681 78.6916 62.4493 80.8117 61.71C94.4677 56.9467 110.183 59.0102 122.434 68.6277C137.071 80.1223 142.44 99.0138 137.521 115.868C136.992 117.679 136.383 119.477 135.615 121.224L135.613 121.216Z"
          fill="#008CE7"
        />
        <path
          d="M127.556 62.0422C111.979 49.8065 91.6801 47.8153 74.7128 55.0752C72.2962 56.108 69.9692 57.3478 67.711 58.749C62.6337 61.907 57.9961 65.9745 54.1044 70.9806C36.3243 93.815 40.381 126.831 63.1685 144.728C85.9559 162.624 118.84 158.624 136.62 135.789C138.598 133.248 140.26 130.577 141.695 127.821C141.722 127.762 141.759 127.71 141.786 127.651C142.819 125.654 143.704 123.611 144.46 121.533C145.278 119.277 145.942 116.984 146.444 114.659C150.568 95.569 143.931 74.9029 127.556 62.0422ZM135.613 121.216C134.175 124.503 132.358 127.678 130.058 130.64C115.105 149.84 87.4559 153.209 68.2948 138.158C49.1337 123.108 45.718 95.3468 60.6705 76.1463C64.576 71.1279 69.3684 67.2244 74.6359 64.4263C76.6365 63.3681 78.6916 62.4493 80.8117 61.71C94.4677 56.9467 110.183 59.0102 122.434 68.6277C137.071 80.1223 142.44 99.0138 137.521 115.868C136.992 117.679 136.383 119.477 135.615 121.224L135.613 121.216Z"
          fill="#46B6FF"
        />
        <ellipse
          cx="95.6322"
          cy="102.961"
          rx="45.476"
          ry="45.2794"
          transform="rotate(-105 95.6322 102.961)"
          fill="white"
          fill-opacity="0.5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10589_48547">
        <rect width="362" height="280" fill="white" />
      </clipPath>
      <clipPath id="clip1_10589_48547">
        <rect width="362" height="279.552" fill="white" />
      </clipPath>
      <clipPath id="clip2_10589_48547">
        <rect
          width="210"
          height="188"
          fill="white"
          transform="translate(74.0569 226.015) rotate(-105)"
        />
      </clipPath>
    </defs>
  </svg>
);
