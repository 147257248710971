import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: ['production', 'staging'].includes(process.env.ENVIRONMENT_NAME),
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  environment: process.env.ENVIRONMENT_NAME,
});
