import { keyframes } from 'styled-components';

export const slideInFromTop = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-25px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const slideInFromRight = keyframes`
  0% {
    transform: translateX(25px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const popIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const fadeGrowIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.99);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const animations = {
  slideInFromTop,
  slideInFromBottom,
  slideInFromLeft,
  slideInFromRight,
  popIn,
  fadeGrowIn,
  fadeIn,
};
