import styled from 'styled-components';

import { media } from '@hl-portals/helpers';

export const LogoWrapper = styled.a`
  line-height: 60px;
  font-size: 0;
  cursor: pointer;
`;

export const Logo = styled.img<{
  isElite?: boolean;
  shouldUseTextLogo?: boolean;
}>`
  display: inline-block;
  vertical-align: middle;
  max-width: ${({ isElite }) => (isElite ? '200px' : '140px')};
  height: auto;
  ${media.tablet`
    max-width: 30px;
  `}
  ${media.desktop`
    max-width: ${({ isElite }) => (isElite ? '240px' : '140px')};;
  `}

  ${({ shouldUseTextLogo }) =>
    !shouldUseTextLogo &&
    `
    height: 32px;
    width: 32px;
  `}
`;
