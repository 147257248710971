import { useRef } from 'react';
import { SizingProps, SpacingProps } from '@mui/system';
import { RadioInput, RadioNative } from './styles';

export type RadioProps = {
  checked?: boolean;
  onChange?: (value: string) => void;
  name: string;
  value: string;
  disabled?: boolean;
  id?: string;
  'data-test'?: string;
} & SizingProps &
  SpacingProps;

export const Radio = ({
  checked,
  onChange,
  name,
  value,
  disabled = false,
  id,
  ...props
}: RadioProps): React.ReactElement => {
  const ref = useRef<HTMLInputElement>(null);

  const changeRadio = () => {
    if (!disabled && ref.current && onChange) {
      ref.current.checked = true;
      onChange(ref.current.value);
    }
  };

  const onKeyDown = (event: any) => {
    if ([' ', 'Enter'].includes(event.key)) {
      event.preventDefault();
      changeRadio();
    }
  };

  return (
    <>
      <RadioNative
        type="radio"
        name={name}
        checked={checked}
        value={value}
        disabled={disabled}
        id={id}
        ref={ref}
        onChange={changeRadio}
        data-test={`radio-native-input-${name}-${value}`}
        {...props}
      />
      <RadioInput
        disabled={disabled}
        role={disabled ? undefined : 'radio'}
        tabIndex={disabled ? undefined : 0}
        onClick={changeRadio}
        onKeyDown={onKeyDown}
        {...props}
        data-test={`radio-input-${name}-${value}`}
      />
    </>
  );
};
