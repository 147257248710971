import { Provider } from 'next-auth/providers';

type OAuth = 'oauth';

const getProfileAttributes = (profile) => ({
  name: profile.full_name,
  email: profile.email,
  id: profile.id,
});

const homelight: Provider = {
  id: 'homelight',
  name: 'HomeLight',
  type: 'oauth' as OAuth,
  authorization: `${process.env['NEXTAUTH_HOMELIGHT_HOST']}/auth/application/${process.env['CLIENT_ID']}?state=:state`,
  token: `${process.env['NEXTAUTH_HOMELIGHT_HOST']}/oauth/token`,
  userinfo: {
    url: `${process.env['NEXTAUTH_HOMELIGHT_HOST']}/oauth/users/current`,
    async request({ client, tokens }) {
      const profile = await client.userinfo(tokens as any);

      return getProfileAttributes(profile);
    },
  },
  profile(profile) {
    return profile;
  },
  options: {
    clientId: process.env['CLIENT_ID'],
    clientSecret: process.env['CLIENT_SECRET'],
  },
  httpOptions: {
    timeout: 5000,
  },
};

export default homelight;
