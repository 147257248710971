import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { EDIT_PROFILE_AGENT_ENDPOINT } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useScreenSize } from '../useScreenSize';

type AgentData = {
  cellphoneNumber: string;
  officePhoneNumber: string;
  secondaryEmail: string;
};

const updateAgentData = async (agentData: AgentData) => {
  const agent = {
    phone_mobile: agentData.cellphoneNumber,
    phone_office: agentData.officePhoneNumber,
    email2: agentData.secondaryEmail,
  };

  return enhancedAxios({
    method: 'PUT',
    url: EDIT_PROFILE_AGENT_ENDPOINT,
    data: { agent },
    translateJsona: true,
  });
};

export const useEditAgentData = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();

  return useMutation({
    mutationFn: updateAgentData,
    onSuccess: () => {
      queryClient.invalidateQueries('current');
      toast.success(`You've updated your profile information!`, {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 150 },
      });
    },
    onError: () => {
      toast.error('Sorry, something went wrong. Please try again.', {
        position: 'bottom-center',
        autoClose: 4000,
      });
    },
  });
};
