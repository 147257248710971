import styled, { css } from 'styled-components';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';

type ProgressItemProps = {
  title: string;
  complete: boolean;
};

const CheckIconWrapper = styled(Box)<{ complete: boolean }>`
  ${({ theme }) => css`
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
  `}
`;

const StyledBox = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const ProgressItem = ({
  title,
  complete,
}: ProgressItemProps): React.ReactElement => {
  return (
    <StyledBox mb="28px" alignItems="center" justifyContent="flex-start">
      <CheckIconWrapper
        complete={complete}
        alignItems="center"
        justifyContent="center"
        ml="7px"
        mr="13px"
      >
        <Icon
          type="check"
          size={10}
          color={complete ? 'electricBlue' : 'coolGray4'}
        />
      </CheckIconWrapper>
      <Paragraph color="darkBlue" variant="text-small">
        {title}
      </Paragraph>
    </StyledBox>
  );
};

export default ProgressItem;
