import Image from 'next/image';
import Link from 'next/link';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import Layout from '../Layout';
import { IconContainer, ProgramFooter, ProgramHeader } from './styles';

type ServiceKeys =
  | 'closing-services'
  | 'trade-in'
  | 'cash-offer'
  | 'simple-sale';

export type ProgramLogo = 'homelight' | ServiceKeys;
export { ProgramFooter, ProgramHeader, IconContainer as ProgramIconContainer };

type ProgramLayoutProps = {
  program: ProgramLogo;
  children: React.ReactElement | Array<React.ReactElement>;
  closeLink?: string;
  onClose?: () => void;
  hideLogo?: boolean;
  showFooter?: boolean;
  fixedFooter?: boolean;
  footerContent?: React.ReactElement | Array<React.ReactElement>;
} & BoxTypes;

const PROGRAM_IMAGES: { [key in ProgramLogo]: string } = {
  homelight: '/logo-text.svg',
  'cash-offer': '/programs/cash-offer.svg',
  'closing-services': '/programs/closing-services.svg',
  'trade-in': '/programs/trade-in.svg',
  'simple-sale': '/programs/simple-sale.svg',
};

export const ProgramLayout = ({
  program,
  children,
  closeLink,
  onClose,
  hideLogo = false,
  showFooter = false,
  fixedFooter = false,
  footerContent,
  ...otherProps
}: ProgramLayoutProps): React.ReactElement => (
  <Box flexDirection="column" width="100%">
    <ProgramHeader>
      <Layout mt={0} py="12px" position="relative">
        {!hideLogo ? (
          <Image
            src={PROGRAM_IMAGES[program]}
            height="43px"
            width="160px"
            alt={program}
          />
        ) : (
          <Box height="32px" />
        )}
        {(closeLink || onClose) && (
          <Link href={closeLink ?? ''} passHref>
            <IconContainer
              as="a"
              {...(onClose && { onClick: onClose })}
              data-test="programHeader-close-icon"
            >
              <Icon type="times" size={20} />
            </IconContainer>
          </Link>
        )}
      </Layout>
    </ProgramHeader>
    <Layout {...otherProps}>{children}</Layout>
    {showFooter && (
      <ProgramFooter fixedFooter={fixedFooter}>
        <Layout mt={0} py="12px" position="relative">
          {footerContent}
        </Layout>
      </ProgramFooter>
    )}
  </Box>
);
