import { UseMutationOptions, useMutation } from 'react-query';

import { VALIDATE_TASK_TOKEN } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

export type ValidateTokenParams = {
  token: string;
  template_slug?: string;
};

export type ValidateTokenData = {
  token: string;
  task: {
    id: number;
    title: string;
    note: string;
    due_at: string | null;
    expires_at: string | null;
    deferred_at: string | null;
    completed_at: string | null;
    completion_note: string | null;
    assigner_id: number;
    assignee_id: number;
    attachable_type: 'Lead';
    attachable_id: number;
    created_at: string;
    updated_at: string;
    deferred_until: string | number;
    dismissed_at: string | number;
    assigned_at: string;
    template_slug: string;
    ready_at: string;
    sub_attachable_type: 'BbysLead';
    sub_attachable_id: number;
    task_category_id: number | null;
    completed_by_id: number | null;
    priority: number;
    metadata: unknown;
  };
  departing_property_full_address: string;
  property_type: string;
  client_details: {
    first_name: string;
    last_name: string;
  };
  agent_details: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string | null;
  };
  property_city: string | null;
  property_state: string | null;
  property_zip: string | null;
  property_address: string | null;
};

type Options = UseMutationOptions<
  ValidateTokenData,
  unknown,
  ValidateTokenParams,
  unknown
>;

export const useValidateToken = (options: Options = {}) =>
  useMutation(
    async ({ token, template_slug }: ValidateTokenParams) =>
      enhancedAxios<ValidateTokenData>({
        url: VALIDATE_TASK_TOKEN,
        method: 'POST',
        data: {
          token,
          template_slug: template_slug,
        },
      }),
    options
  );
