import { useCallback, useEffect, useRef } from 'react';

type ResetFn = (key?: string | string[]) => void;
type FN = (resetFn: ResetFn) => void | Promise<void>;

type Options =
  | {
      enabled?: boolean;
      persist?: never;
      key?: never;
    }
  | {
      enabled?: boolean;
      persist?: boolean;
      key: string;
    };

const defaultOptions = {
  enabled: true,
  persist: false,
  key: '',
};

export const useRunOnce = (fn: FN, options: Options = defaultOptions) => {
  const count = useRef(0);

  const clear = useCallback(() => {
    if (options.key) localStorage.removeItem(options.key);
    count.current = 0;
  }, [options.key]);

  const run = useCallback(() => {
    if (options.persist) {
      const value = localStorage.getItem(options.key);

      if (options.enabled && !Number(value)) {
        count.current = 1;
        localStorage.setItem(options.key, '1');
        fn(clear);
      }
      return;
    }

    if (options.enabled && count.current === 0) {
      count.current = 1;
      fn(clear);
    }
  }, [fn, options, clear]);

  useEffect(() => {
    run();
  }, [run]);

  return { clear, run };
};
