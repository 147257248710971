import styled from 'styled-components';

import { PropsWithChildren } from 'react';

import Image from 'next/image';

import { FadeIn } from '../Animations';
import { Box, BoxTypes } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';

const SplashBox = styled(Box)`
  width: 2px;
  height: 2px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  border-radius: 50%;
  background-color: #e8f5fd;
  animation: splash 800ms ease-in-out forwards;

  @keyframes splash {
    0% {
      opacity: 1;
    }
    50% {
      transform: scale(1000, 1000);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1, 1);
    }
  }
`;

const SplashScreen = ({ children, ...props }: PropsWithChildren<BoxTypes>) => {
  return (
    <>
      <SplashBox />
      <FadeIn {...props}>{children}</FadeIn>
    </>
  );
};

type SuccessProps = {
  onClose: () => void;
  address: string;
};

export const Success = ({ onClose, address }: SuccessProps) => {
  return (
    <SplashScreen flexDirection="column">
      <Box
        p="12px"
        justifyContent="flex-end"
        bgcolor="white"
        cursor="pointer"
        onClick={onClose}
      >
        <Icon type="close" />
      </Box>

      <Box
        width="100%"
        height="calc(100vh - 44px)"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image src="/assets/check.svg" width="96px" height="96px" />
        <Paragraph variant="heading-4" textAlign="center" mb="16px" mt="16px">
          Property photos submitted
        </Paragraph>
        <Paragraph textAlign="center" mb="16px">
          Photos {address ? `for ${address} ` : ''}were successfully uploaded.
        </Paragraph>
        <Paragraph textAlign="center" mb="64px">
          Our team will review the photos and follow up with you regarding the
          home's valuation.
        </Paragraph>
        <Button size="large" width="176px" onClick={onClose}>
          Close
        </Button>
      </Box>
    </SplashScreen>
  );
};
