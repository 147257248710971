import { SizingProps, SpacingProps } from '@mui/system';

import { useScreenSize } from '@hl-portals/hooks';

import RadioTab, { RadioTabType } from './RadioTab';
import { TabWrapper } from './styles';

export type RadioTabsProps = {
  selected: string;
  tabs: RadioTabType[];
  onChange: (value: string) => void;
  name: string;
  maxRowLength?: number;
} & SizingProps &
  SpacingProps;

export const RadioTabs = ({
  selected,
  tabs,
  onChange,
  name,
  maxRowLength = 2,
  ...props
}: RadioTabsProps): React.ReactElement => {
  const { isDesktop } = useScreenSize();

  const onTabClick = (tab: RadioTabType) => () => {
    onChange(tab.value);
  };

  const columnFormat = !isDesktop && tabs.length > maxRowLength;

  return (
    <TabWrapper columnFormat={columnFormat} {...props}>
      {tabs.map((tab) => (
        <RadioTab
          {...tab}
          columnFormat={columnFormat}
          selected={selected === tab.value}
          key={tab.value}
          onClick={onTabClick(tab)}
        />
      ))}
    </TabWrapper>
  );
};
