import { createContext, useContext, useEffect, useState } from 'react';
import { Colors } from '@hl-portals/constants';

type AddSkeleton<T extends string = string> = (args: {
  className?: T;
  color?: Colors;
}) => {
  className: `${T} --${Colors} --skeleton`;
};

let IS_DISPLAYING_FIRST_RENDER = true;

type GetSkeletons = (skeletonKey: string) =>
  | {
      skeleton: { className: '--skeleton' };
      addSkeleton: AddSkeleton;
    }
  | {
      skeleton: Record<string, unknown>;
      addSkeleton: (args: { className?: string }) => { className: string };
    };

type GetSkeletonActions = (skeletonKey: string) => {
  showSkeleton: () => void;
  hideSkeleton: () => void;
};

type SkeletonConfigs = { defaultEnabled?: boolean };

type RegisterSkeleton = (
  skeletonKey: string,
  config?: SkeletonConfigs
) => () => void;

type TSkeletonContext = {
  registerSkeleton: RegisterSkeleton;
  getSkeletons: GetSkeletons;
  getSkeletonActions: GetSkeletonActions;
  shouldDisplaySkeleton: (skeletonKey: string) => boolean;
};

const INITIAL_SKELETON_VALUES: TSkeletonContext = {
  getSkeletonActions: () => ({
    showSkeleton: () => {},
    hideSkeleton: () => {},
  }),
  getSkeletons: () => ({
    addSkeleton: (className) => ({ className }),
    skeleton: {},
  }),
  registerSkeleton: () => () => {},
  shouldDisplaySkeleton: () => false,
};

export const SkeletonContext = createContext<TSkeletonContext>(
  INITIAL_SKELETON_VALUES
);
SkeletonContext.displayName = 'SkeletonContext';

export const SkeletonProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [skeletonRegistry, setSkeletonRegistry] = useState<
    Record<string, boolean>
  >({});

  const shouldDisplaySkeleton = (skeletonKey: string) =>
    skeletonKey
      ? skeletonRegistry[skeletonKey] ?? IS_DISPLAYING_FIRST_RENDER
      : false;

  const registerSkeleton: RegisterSkeleton = (
    skeletonKey: string,
    config: SkeletonConfigs = {}
  ) => {
    if (skeletonKey in skeletonRegistry) {
      // eslint-disable-next-line no-console
      console.warn(
        `you already registered a skeleton with the key ${skeletonKey}`
      );
    }

    setSkeletonRegistry((v) => ({
      ...v,
      [skeletonKey]: config.defaultEnabled ?? false,
    }));

    return () => {
      setSkeletonRegistry((registry) =>
        Object.fromEntries(
          Object.entries(registry).filter(([key]) => key !== skeletonKey)
        )
      );
    };
  };

  const getSkeletonActions = (skeletonKey: string) => ({
    hideSkeleton: () =>
      setSkeletonRegistry((v) => ({ ...v, [skeletonKey]: false })),
    showSkeleton: () =>
      setSkeletonRegistry((v) => ({ ...v, [skeletonKey]: true })),
  });

  const getSkeletons: GetSkeletons = (skeletonKey: string) => {
    if (!shouldDisplaySkeleton(skeletonKey)) {
      return INITIAL_SKELETON_VALUES.getSkeletons(skeletonKey);
    }

    const addSkeleton: AddSkeleton = (args) => ({
      className: `${args.className ?? ''} --${args.color} --skeleton` as const,
    });

    return {
      skeleton: { className: '--skeleton' },
      addSkeleton,
    };
  };

  return (
    <SkeletonContext.Provider
      value={{
        registerSkeleton,
        getSkeletons,
        getSkeletonActions,
        shouldDisplaySkeleton,
      }}
    >
      {children}
    </SkeletonContext.Provider>
  );
};

const SkeletonText: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, ...props }) =>
  props.className ? <span {...props}>{children}</span> : <>{children}</>;

export const useSkeleton = (skeletonKey: string) => {
  const { getSkeletons, shouldDisplaySkeleton } = useContext(SkeletonContext);

  const skeletonDecorators = getSkeletons(skeletonKey);
  return {
    ...skeletonDecorators,
    shouldDisplaySkeleton: shouldDisplaySkeleton(skeletonKey),
    Skeleton: SkeletonText,
  };
};

export const useSkeletonRegister = (
  skeletonKey: string,
  config: SkeletonConfigs = {}
) => {
  const {
    registerSkeleton,
    getSkeletonActions,
    getSkeletons,
    shouldDisplaySkeleton,
  } = useContext(SkeletonContext);

  useEffect(() => {
    IS_DISPLAYING_FIRST_RENDER = false;
    const unregister = registerSkeleton(skeletonKey, config);
    return () => {
      unregister();
    };
  }, []);

  const skeletonDecorators = getSkeletons(skeletonKey);
  return {
    ...skeletonDecorators,
    ...getSkeletonActions(skeletonKey),
    shouldDisplaySkeleton: shouldDisplaySkeleton(skeletonKey),
    Skeleton: SkeletonText,
  };
};
