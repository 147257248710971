import styled from 'styled-components';

import { Box } from '../Box';

export const Tab = styled(Box)<{
  selected?: boolean;
  disabled?: boolean;
  columnFormat?: boolean;
}>`
  ${({ theme, columnFormat, selected }) => `
    cursor: pointer;
    border-left: 0px;
    flex: 1;
    justify-content: center;
    transition: all .2s ease-out;

    ${
      columnFormat
        ? `
          border-radius: 4px;
          margin-bottom: 4px;
          border: 1px solid ${
            selected ? theme.colors.electricBlue : theme.colors.coolGray4
          };
          ${selected ? `background-color: #F2F9FE;` : ``}
        `
        : `
         ${
           selected
             ? `
          border: 1px solid ${theme.colors.electricBlue};
          background-color: #F2F9FE;
        `
             : ''
         }

      &:first-child {
        border-left: 1px solid ${
          selected ? theme.colors.electricBlue : theme.colors.coolGray4
        };
        border-radius: 4px 0px 0px 4px;
      }

      &:last-child {
        border-left: ${
          selected ? `1px solid ${theme.colors.electricBlue}` : '0px'
        };
        border-radius: 0px 4px 4px 0px;
      }
        `
    }

  `}
`;

export const TabWrapper = styled(Box)<{ columnFormat?: boolean }>`
  ${({ columnFormat }) => `
  width: 100%;

  ${
    columnFormat &&
    `
    flex-direction: column;
  `
  }
`}
`;
