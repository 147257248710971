import { AxiosError } from 'axios';

import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { STATE_ELIGIBILITY_PATH } from '@hl-portals/constants';

import { handleErrors } from '@hl-portals/helpers';

export type UseStateEligibilityCheckData = {
  is_eligible: boolean;
};

type UseStateEligibilityOptions = UseQueryOptions<UseStateEligibilityCheckData>;

export const UseStateEligibilityQueryKey = 'STATE_ELIGIBILITY';

export const STATE_ELIGIBILITY_ERROR = (state: string) =>
  `Unable to check eligibility for state: ${state}`;

export const useStateEligibilityCheck = (
  state: string,
  partner: string,
  options?: UseStateEligibilityOptions
) =>
  useQuery<UseStateEligibilityCheckData>(
    [UseStateEligibilityQueryKey, state],
    async () => {
      const res = await http.public.get(STATE_ELIGIBILITY_PATH(state, partner));
      return res.data;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        handleErrors(args[0] as AxiosError);
      },
    }
  );
