import styled, { css } from 'styled-components';

import { Box } from '../Box';

interface HorizontalScrollProps {
  bordered?: boolean;
}

const HorizontalScroll = styled(Box)<HorizontalScrollProps>`
  ${({ theme, bordered }) =>
    bordered &&
    css`
      border: 1px solid ${theme.colors.coolGray4};
      border-radius: 8px;
      background-color: ${theme.colors.white};
    `};

  overflow-x: auto;
  white-space: no-wrap;
  scroll-snap-type: x mandatory;
  > * {
    scroll-snap-align: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default HorizontalScroll;
