import styled from 'styled-components';

import { Box } from '../Box';

export const PrintBox = styled(Box)`
  display: none;
  -webkit-print-color-adjust: exact;

  @media print {
    display: block;
  }
`;

export default PrintBox;
