import { PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useScrollIntoView } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../../Box';
import UICheckbox from '../../Checkbox';
import { InputError } from '../components/input-error';

type CheckboxProps = {
  name: string;
  testId?: string;
} & BoxTypes;

export const Checkbox = ({
  name,
  testId = 'input-checkbox',
  children,
  ...props
}: PropsWithChildren<CheckboxProps>) => {
  const { targetRef, scrollIntoView } = useScrollIntoView();
  const { formState, setValue, watch, clearErrors, setFocus } =
    useFormContext();

  const error = formState.errors[name];
  const value = watch(name);

  useEffect(() => {
    const isFirstError = Object.keys(formState.errors)[0] === name;

    if (error && isFirstError) {
      setFocus(name);
      scrollIntoView();
    }
  }, [name, error, formState.errors, setFocus, scrollIntoView]);

  return (
    <Box flexDirection="column" gap="8px" ref={targetRef} {...props}>
      <Box
        as="label"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap="12px"
        cursor="pointer"
      >
        <UICheckbox
          onChange={(e) => {
            setValue(name, e.target.checked);
            clearErrors(name);
          }}
          checked={value || false}
          data-test={testId}
        />
        {children}
      </Box>
      {!!error && <InputError error={error} />}
    </Box>
  );
};
