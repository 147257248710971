import {
  BordersProps,
  PaletteProps,
  SizingProps,
  SpacingProps,
} from '@mui/system';

import { useEffect, useState } from 'react';

import { Box } from '../Box';
import { Icon, IconTypeProp } from '../Icon';
import { AccordionButton, AccordionContent, AccordionIcon } from './styles';

type AccordionProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
  icon?: IconTypeProp;
  iconOpen?: IconTypeProp;
  iconPosition?: 'left' | 'right';
  fontColor?: string;
  fontWeight?: string;
  onOpen?: () => void;
  isOpen?: boolean;
};

const Accordion = ({
  label,
  children,
  icon = 'angleRight',
  iconOpen = 'angleDown',
  iconPosition = 'left',
  onOpen,
  isOpen = false,
  fontColor,
  fontWeight,
  ...otherProps
}: AccordionProps &
  SizingProps &
  SpacingProps &
  BordersProps &
  PaletteProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [open, onOpen]);

  return (
    <Box width={1} flexDirection="column" {...otherProps}>
      <AccordionButton
        selected={open}
        onClick={setOpen}
        iconPosition={iconPosition}
        fontColor={fontColor}
        fontWeight={fontWeight}
      >
        {iconPosition === 'left' && (
          <AccordionIcon type="arrowTriangleRight" open={open} size={12} />
        )}
        {label}
        {iconPosition === 'right' && (
          <Icon type={open ? iconOpen ?? 'angleUp' : icon} />
        )}
      </AccordionButton>
      <AccordionContent open={open} iconPosition={iconPosition}>
        {children}
      </AccordionContent>
    </Box>
  );
};

export default Accordion;
