import { EnvMarker, EnvMarkerTextContainer, EnvMarkerText } from './styles';

const EnvironmentMarker = () => {
  return (
    <EnvMarker>
      <EnvMarkerTextContainer>
        <EnvMarkerText>
          Staging
        </EnvMarkerText>
      </EnvMarkerTextContainer>
    </EnvMarker>
  );
};

export default EnvironmentMarker;
