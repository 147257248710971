import { toast } from 'react-toastify';

export function copyToClipboard(
  value: string,
  toastText?: string,
) {
  if (!navigator?.clipboard?.writeText) return;

  navigator.clipboard.writeText(value).then(() => {
    toastText && toast(toastText, { autoClose: 2000, type: 'success' });
  });
}
