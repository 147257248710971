import { Buttons } from '@hl-portals/constants';

import Dialog from '../Dialog';
import { Paragraph } from '../Typography';

export interface WarningDialogProps {
  acceptFn?: () => void;
  declineFn?: () => void;
  acceptText?: string;
  declineText?: string;
  warningText?: string;
  warningDescription?: string;
  redirectUrl?: string;
  preventDeclineFnDefault?: boolean;
  buttonsProps?: {
    color?: Buttons;
  }[];
  hideDeclineButton?: boolean;
}

export const WarningDialog = ({
  acceptFn,
  declineFn = () => {},
  acceptText = 'Leave',
  warningText = 'Are you sure you want to leave?',
  declineText = 'Cancel',
  warningDescription = 'Your progress will be lost',
  buttonsProps = [],
  hideDeclineButton = false,
}: WarningDialogProps): React.ReactElement => {
  const buttons = [
    {
      action: ({ closeDialog }: { closeDialog: () => void }) => {
        closeDialog();
        setTimeout(() => {
          return acceptFn ? acceptFn() : null;
        }, 400);
      },
      text: acceptText,
      color: 'primary' as Buttons,
      ...(buttonsProps[1] || {}),
    },
  ];

  if (!hideDeclineButton) {
    buttons.unshift({
      action: ({ closeDialog }: { closeDialog: () => void }) => {
        closeDialog();
        setTimeout(() => {
          declineFn();
        }, 400);
      },
      text: declineText,
      color: 'tertiary' as Buttons,
      ...(buttonsProps[0] || {}),
    });
  }

  return (
    <Dialog
      buttons={buttons}
      alignItems="flex-start"
      buttonsPosition="right"
      buttonWidth="152px"
    >
      <Paragraph variant="heading-3" color="darkBlue" mb="12px">
        {warningText}
      </Paragraph>

      <Paragraph variant="text" color="coolGray1">
        {warningDescription}
      </Paragraph>
    </Dialog>
  );
};
