import { http } from '@hl-portals/libs/http';

import { EQUITY_CALCULATOR_PUBLIC_PATH } from '@hl-portals/constants';

import { normalizeCurrency } from '@hl-portals/helpers';

export type CalculateEquityLenderArgs = {
  lenderName?: string;
  lenderEmail?: string;
  lenderPhone?: string;
  lenderCompany?: string;
  uuid: string;
  state: string;
  value: string;
  mortgage?: string;
  targetAmount?: string;
  targetAmountRadio?: string;
  sourceForm?: string | undefined;
  snapshotUuid?: string;
  snapshotId?: string;
  partnerSlug?: string;
  source?: string | undefined;
  agentName?: string;
  agentEmail?: string;
  agentPhone?: string;
  salesConsultantName?: string;
  salesConsultantEmail?: string;
  salesConsultantPhone?: string;
  liens?: string;
};

export async function calculateLenderEquity({
  lenderName,
  lenderEmail,
  lenderPhone,
  lenderCompany,
  uuid,
  state,
  value,
  mortgage,
  targetAmount,
  targetAmountRadio,
  sourceForm,
  source,
  snapshotUuid,
  snapshotId,
  partnerSlug,
  agentName,
  agentEmail,
  agentPhone,
  salesConsultantName,
  salesConsultantEmail,
  salesConsultantPhone,
  liens,
}: CalculateEquityLenderArgs) {
  const targetValue =
    targetAmountRadio === '0' && !targetAmount ? '0' : targetAmount;

  const res = await http.public.get(EQUITY_CALCULATOR_PUBLIC_PATH, {
    params: {
      lender_name: lenderName,
      lender_email: lenderEmail,
      lender_phone: lenderPhone,
      lender_company: lenderCompany,
      property_uuid: uuid,
      property_state: state,
      agent_valuation: value && normalizeCurrency(value),
      target_unlock_amount: targetValue && normalizeCurrency(targetValue),
      agent_estimated_loan_payoff: mortgage && normalizeCurrency(mortgage),
      snapshot_uuid: snapshotUuid,
      lender_lead_snapshot_id: snapshotId,
      source:
        source === undefined || source == null ? 'public_lender_euc' : source,
      source_form: sourceForm,
      partner_slug: partnerSlug,
      agent_name: agentName,
      agent_email: agentEmail,
      agent_phone: agentPhone,
      sales_consultant_name: salesConsultantName,
      sales_consultant_email: salesConsultantEmail,
      sales_consultant_phone: salesConsultantPhone,
      liens,
    },
  });

  return res?.data?.data?.attributes;
}
