import { theme } from '@hl-portals/constants';

import { BoxTypes } from '../Box';
import { Tab } from './styles';

export type RadioTabType = {
  value: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  columnFormat?: boolean;
} & BoxTypes;

export const RadioTab = ({
  value,
  label,
  disabled,
  selected,
  columnFormat,
  onClick,
  ...props
}: RadioTabType & { onClick: () => void }) => {
  return (
    <Tab
      data-test={`radio-tab-${value}-${selected ? 'active' : 'inactive'}`}
      selected={selected}
      disabled={disabled}
      onClick={onClick}
      fontSize="16px"
      padding="12px"
      color={theme.colors.darkBlue}
      border={`1px solid ${theme.colors.coolGray4}`}
      columnFormat={columnFormat}
      fontWeight={selected ? 'bold' : 'normal'}
      {...props}
    >
      {label}
    </Tab>
  );
};

export default RadioTab;
