import styled, { css } from 'styled-components';

import { media, slideInFromBottom } from '@hl-portals/helpers';

import { Box } from '../Box';

export const TabsWrapper = styled(Box)`
  display: block;
`;

export const TabsActiveMarker = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.darkBlue};
    bottom: 0;
    height: 3px;
    opacity: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: all 0.2s ease-out;
    width: 0;
  `}
`;

export const Tab = styled.li<{
  active: boolean;
}>`
  ${({ active, theme }) => css`
    border-bottom-color: ${theme.colors.darkBlue};
    border-bottom-width: 0px;
    color: ${active ? theme.colors.darkBlue : theme.colors.coolGray2};
    cursor: pointer;
    font-size: 16px;
    font-weight: ${active ? 700 : 400};
    line-height: 24px;
    margin: 0 28px;
    padding-bottom: 16px;
    position: relative;
    transition: all 0.2s ease-out;
    transition: border-bottom-width 0.2s ease-out;

    ${active
      ? css`
          ${TabsActiveMarker} {
            animation: ${slideInFromBottom} 0.2s ease-out forwards;
            width: 100%;
          }
        `
      : ''}

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    ${media.untilDesktop`
      font-size: 14px;
      line-height: 20px;
      margin: 0 8px;
      text-wrap: nowrap;
      text-align: center;
    `}
  `}
`;

export const TabsHead = styled.ul`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.coolGray5};
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  `}
`;
