import styled from 'styled-components';

import { slideInFromTop } from '@hl-portals/helpers';

import { Box } from '../Box';

export const BannerWrapper = styled(Box)`
  animation: 0.3s ease 0s 1 normal ${slideInFromTop};
`;

export const BannerContent = styled(Box)`
  ${({ theme }) => `
    width: 100%;
    margin: 0 auto;
    max-width: ${theme.breakpointsValue.lg}px;
  `}
`;
