import config from '../config';

interface UrlBuilder {
  protocol?: 'http' | 'https';
  host?: string;
  path?: string;
  queryString?:
    | string[][]
    | {
        [key: string]: Array<string>;
      };
}

const hapiHost = config.hapiHost;

const urlBuilder = ({
  protocol = 'https',
  host = hapiHost,
  path = '/',
  queryString = [],
}: UrlBuilder): string => {
  const params = new URLSearchParams();

  if (Array.isArray(queryString)) {
    queryString.forEach(([key, value]) => {
      params.append(key, value);
    });
  } else {
    Object.entries(queryString).forEach(([key, values]) => {
      params.append(key, values.join(','));
    });
  }

  const formattedQueryString = `${decodeURI(params.toString())}`;
  const protocolString = host?.includes('http', 0) ? '' : `${protocol}://`;
  return `${protocolString}${host}${path}${
    formattedQueryString.length > 0 ? '?' : ''
  }${formattedQueryString}`;
};

export default urlBuilder;
