import { config, urlBuilder } from '@hl-portals/helpers';

import { PropertyMappingType } from './referrals';

export const TEXT_INTRO_COMM_TYPE = 'CoreServices.agent_intro_over_text';

//
// Agent Dashboard API
//
export const LOGOUT_PATH = `/api/logout`;
export const FULL_LOGOUT_PATH = urlBuilder({
  path: `/api/user-auth-service/user-login/logout`,
});
export const hapiLogoutPath = urlBuilder({
  path: '/api/user-auth-service/user-login/un-impersonate-user',
});

//
// Hapi endpoints
//
// User token
//
// Create
export const CREATE_USER_TOKEN_PATH =
  '/api/user-auth-service/user-login/create_token';

// Revoke
export const revokeUserTokenPath = ({ token }: { token: string }): string =>
  urlBuilder({
    path: `/api/user-auth-service/user-login/revoke_token/${token}`,
  });

// Feed
export const feedListPath = urlBuilder({
  path: '/api/user-feed-service/feed',
  queryString: [
    ['filter[hidden_at]', 'NULL'],
    ['include', 'activity'],
  ],
});
export const dismissItemPath = '/api/user-feed-service/feed/dismiss-item';
export const markDisplayedPath =
  '/api/user-feed-service/feed/mark-displayed-at';

export const updateClickedFeedPath =
  '/api/user-feed-service/feed/update-clicked-at';

// User Events
export const RECORD_USER_EVENT_PATH =
  '/api/events-service/user-events/record-user-event';

// Agent Data Service
export const USER_CURRENT_PATH = urlBuilder({
  path: '/api/user-auth-service/agent-dashboard/users/current',
  queryString: {
    'fields[user]': [
      'first_name',
      'last_name',
      'email',
      'active_agent_team_memberships',
      'agent',
      'agent_team',
      'coordinator',
      'pending_agent_team_invitations',
      'impersonated',
      'confirmed_at',
      'authentication_devices',
      'secure_session,',
      'phone_numbers',
    ],
    'fields[agent]': [
      'agreement_signed_at',
      'broker_image_url',
      'should_sign_agreement',
      'force_re_sign',
      'in_contract',
      'email1',
      'email2',
      'elite_agent',
      'first_name',
      'last_name',
      'full_name',
      'languages',
      'education',
      'new_reviews_url',
      'state',
      'state_code',
      'show_closings',
      'show_trade_ins',
      'show_cash_offers',
      'show_cash_offer_terms',
      'show_home_loans',
      'show_hlss',
      'show_disclosuresio',
      'show_metrics',
      'show_offers_management',
      'show_progress_tracker',
      'show_property_questionnaire',
      'show_referrals',
      'slug',
      'team_leader',
      'biography',
      'image_url',
      'license_number',
      'license_issue_date',
      'phone_mobile',
      'phone_office',
      'fax',
      'onboarding_progress',
      'office_name_to_display',
      'gender',
      'agent_certifications',
      'testimonials',
      'reviews',
      'agent_awards',
      'agent_service_providers',
      'service_provider',
      'marketplace_program_agents',
      'marketplace_program',
      'agent_service_provider_review',
      'partner_program',
      'agent_agreements',
      'ppl_provider_agreements',
      'current_office',
      'service_office',
      'preferred_escrow_officer',
      'agent_agreement_changes',
      'ultra_subscribed',
      'mls_identifier',
    ],
    'fields[agent_team]': [
      'agent',
      'agent_team_features',
      'name',
      'lead_routing_seats',
    ],
    'fields[agreement]': ['version', 'effective_at'],
    'fields[active_agent_team_memberships]': ['name', 'agent_team'],
    'fields[authentication_devices]': ['id'],
    'fields[agent_agreement_changes]': [
      'id',
      'display_agreement_changes',
      'agreement_changes_status',
      'banner_copy',
      'modal_copy_title',
      'modal_copy_body',
      'resource_url',
    ],
    include: [
      'agent',
      'agent.partner_program',
      'agent.current_office',
      'agent.service_office',
      'agent.preferred_escrow_officer',
      'agent.preferred_escrow_officer.phone_numbers',
      'agent.preferred_escrow_officer.phone_numbers.number',
      'agent.agent_awards',
      'agent.agent_agreements',
      'agent.agent_agreements.agreement',
      'agent.ppl_provider_agreements',
      'agent.specialties',
      'agent.agent_certifications',
      'agent.marketplace_program_agents',
      'agent.marketplace_program_agents.marketplace_program',
      'agent.testimonials',
      'agent.reviews',
      'agent.languages',
      'agent_team',
      'agent.agent_service_providers.service_provider',
      'agent.agent_service_providers.agent_service_provider_review',
      'agent.onboarding_progress',
      'agent.agent_agreement_changes',
      'agent_team.agent.state',
      'authentication_devices',
      'coordinator',
      'coordinator.agent_teams',
      'pending_agent_team_invitations',
      'pending_agent_team_invitations.agent_team',
      'active_agent_team_memberships',
      'active_agent_team_memberships.agent_team',
      'active_agent_team_memberships.agent_team.agent_team_features',
    ],
  },
});

export const USER_ACHIEVEMENTS_PATH = urlBuilder({
  path: '/api/agent-data-service/agent-dashboard/agents/current/achievements',
});

export const BBYS_REQUIREMENTS_LINK =
  'https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements';

export const UPDATE_ENROLLMENTS_FROM_COURSES_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/update-enrollments-from-courses';

export const RESPONSIVENESS_PATH =
  '/api/agent-data-service/agent-dashboard/agents/current/responsiveness-stats';

export const ASM_PATH = `/api/agent-data-service/agent-dashboard/agents/current/asm`;

// Messaging
export const UNREAD_MESSAGES_COUNT_PATH = urlBuilder({
  path: '/api/communications-service/communication-threads/unread-communications-count',
  queryString: [['filter[template_name]', TEXT_INTRO_COMM_TYPE]],
});

export const MESSAGES_LIST_PATH = ({
  page = '1',
  perPage = '10',
}: {
  page?: string;
  perPage?: string;
}): string =>
  urlBuilder({
    path: '/api/messaging-service/messages/inbox-messages',
    queryString: [
      [
        'include',
        'message_participants,message_classification,message_participants.user,message_participants.user.roles,message_participants.message_delivery',
      ],
      ['filter[message_classification]', '1,2'],
      ['page[number]', page],
      ['page[size]', perPage],
    ],
  });

export const COMMUNICATION_THREADS_PATH = ({
  page = '1',
  perPage = '100',
  user_id,
}: {
  page?: string;
  perPage?: string;
  user_id: string;
}): string =>
  urlBuilder({
    path: '/api/communications-service/communication-threads',
    queryString: [
      [
        'include',
        'last_communication,last_communication.communication_content,last_communication.metadata,communication_thread_attachables',
      ],
      ['filter[template_name]', TEXT_INTRO_COMM_TYPE],
      ['filter[user_id]', user_id],
      ['sort', '-created_at'],
      ['fields[last_communication]', 'content,created_at,recipients'],
      ['page[number]', page],
      ['page[size]', perPage],
    ],
  });

export const AGENT_LEADS_BY_ID_PATH = ({
  agent_lead_ids,
}: {
  agent_lead_ids: string;
}): string =>
  urlBuilder({
    path: '/api/lead-data-service/agent-dashboard/leads/agent-leads-by-id',
    queryString: [['filter[ids]', agent_lead_ids]],
  });

export const EQUITY_CALCULATOR_PATH =
  '/api/property-service/v2/eligibility/equity-unlock';
export const EQUITY_CALCULATOR_PUBLIC_PATH =
  '/api/property-service/v2/public-eligibility/public-equity-unlock';

export const MESSAGES_PATH = ({
  messageId,
  token,
}: {
  messageId: string;
  token?: string;
}): string =>
  urlBuilder({
    path: '/api/messaging-service/messages/message-thread',
    queryString: [
      ['page[size]', '50'],
      ['sort', '-created_at'],
      ['include', 'message_participants,message_participants.user'],
      ['filter[message_id]', messageId],
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const COMMUNICATIONS_PATH = ({
  messageId,
  token,
}: {
  messageId: string;
  token?: string;
}): string =>
  urlBuilder({
    path: '/api/communications-service/communications',
    queryString: [
      ['page[size]', '50'],
      ['sort', '-created_at'],
      ['filter[communication_thread_id]', messageId],
      ['include', 'communication_content,metadata'],
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const searchLeadPath = ({
  term,
  leadFields = 'name,client',
  userTypes,
}: {
  term: string;
  leadFields?: string;
  userTypes?: string;
}): string =>
  urlBuilder({
    path: '/api/lead-data-service/agent-dashboard/leads',
    queryString: [
      ['include', 'provider_leads,provider_leads.providable'],
      ['sort', 'name.keyword'],
      ['filter[_search]', term],
      ['fields[lead]', leadFields],
      ...(userTypes ? [['filters[user_type]', userTypes]] : []),
    ],
  });

export const SEARCH_CLIENT_PATH = ({ term }: { term: string }): string =>
  urlBuilder({
    path: '/api/lead-data-service/agent-dashboard/leads',
    queryString: [
      ['sort', 'name.keyword'],
      [
        'fields[lead]',
        'name,email,phone,client,stage,user_type,address,city_name,state_code,zip_code,current_agent_lead_id',
      ],
      ['filter[_search]', term],
      ['filter[user_type]', 'seller,buyer'],
    ],
  });

export const CLIENT_LEAD_PATH = ({ clientId }: { clientId: string }): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/clients/${clientId}/leads`,
    queryString: [
      ['include', 'client,order,order.leads'],
      ['sort', '-created_at'],
      [
        'fields[lead]',
        'created_at,email,email_alt,full_address,address,name,phone,phone_alt,stage,user_type,city_name,state_code,zip_code,current_agent_lead_id,client,order',
      ],
      ['fields[client]', 'name,email'],
    ],
  });

export const PUBLIC_EVENT_PATH =
  config.hapiHost + '/api/lead-data-service/client-page/public-user-event';

export const BBYS_DROPOUTS_PATH =
  config.hapiHost + '/api/order-data-service/public-bbys/euc-dropouts/check';

export const CASH_OFFER_TO_LEAD_PATH = ({
  leadId,
  cashOfferId,
}: {
  leadId: string;
  cashOfferId?: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/${leadId}/cash-offers${
      cashOfferId ? `/${cashOfferId}` : ''
    }`,
  });

export const GET_CASH_OFFER_LEADS = ({ leadId }: { leadId: string }): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/${leadId}/cash-offers?include=file_attachments,file_attachments.file,provider_lead`,
  });

export const AGENT_REFERRAL_AGREEMENT_ESIGN = `/api/agent-data-service/agent-dashboard/agents/current/agreements/esign`;
export const AGENT_REFERRAL_AGREEMENT_DOWNLOAD = `/api/agent-data-service/agent-dashboard/agents/current/agreements/download`;
export const AGENT_REFERRAL_AGREEMENT_SIGNED = `/api/agent-data-service/agent-dashboard/agents/current/agreements/signed`;

export const VALIDATE_MAGIC_LINK_TOKEN_PATH = (token: string) =>
  `/api/user-auth-service/user-login/validate-magic-link-token?token=${token}`;

export const CANCEL_CASH_OFFER_LEAD = ({
  leadId,
  cashOfferId,
}: {
  leadId: string;
  cashOfferId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/${leadId}/cash-offers/${cashOfferId}/cancel`,
  });

export const HLSS_PROPERTY_PHOTO_UPLOAD_PATH = ({
  taskId,
}: {
  taskId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/simple-sale-property-photo/${taskId}`,
  });

export const NOTIFY_ATTACHABLE_TASK = `/api/task-service/v2/tasks/notify-task-update`;
export const COMPLETE_ATTACHABLE_TASK = `/api/task-service/v2/tasks/complete-attachable-task`;
export const CHECK_TASK_STATUS =
  '/api/task-service/v2/tasks/status-attachable-task';

export const HLSS_PROPERTY_CMA_UPLOAD_PATH = ({
  taskId,
}: {
  taskId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/simple-sale-property-cma/${taskId}`,
  });

export const TASK_COMPLETE_PATH = ({ taskId }: { taskId: string }): string =>
  urlBuilder({
    path: `/api/task-service/tasks/${taskId}/complete`,
  });

export const INTRO_OVER_TEXT_PATH = (): string =>
  urlBuilder({
    path: '/api/lead-data-service/agent-dashboard/leads/agent-intro-over-text-message',
  });

export const MESSAGE_LEAD_CONTEXT_PATH = ({
  communicationThreadId,
  token,
}: {
  communicationThreadId: string;
  token?: string;
}): string =>
  urlBuilder({
    path: `/api/communications-service/communication-threads/${communicationThreadId}`,
    queryString: [
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
      ['include', 'communication_thread_attachables'],
    ],
  });

export const NEW_MESSAGE_REPLY_PATH = ({ token }: { token?: string }): string =>
  urlBuilder({
    path: `/api/messaging-service/messages/new-message-reply`,
    queryString: [
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const COMMUNICATION_REQUEST_PATH = ({
  token,
}: {
  token?: string;
}): string =>
  urlBuilder({
    path: `/api/communications-service/communication-requests`,
    queryString: [
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const MARK_MESSAGE_AS_READ_PATH = ({
  token,
}: {
  token?: string;
}): string =>
  urlBuilder({
    path: `/api/messaging-service/messages/update-delivery-by-parent`,
    queryString: [
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const MARK_COMMUNICATION_AS_READ_PATH = ({
  token,
}: {
  token?: string;
}): string =>
  urlBuilder({
    path: '/api/communications-service/communications/events',
    queryString: [
      ...(typeof token !== 'undefined' ? [['agent_lead_token', token]] : []),
    ],
  });

export const SEND_APP_LINKS_OVER_TEXT_PATH = (): string =>
  urlBuilder({
    path: '/api/agent-data-service/agent-dashboard/agents/current/send-app-links',
  });

export const SEND_TEAM_APP_LINKS_OVER_TEXT_PATH = (): string =>
  urlBuilder({
    path: '/api/agent-team-service/team-memberships/send-app-links',
  });

// Metrics
export const CURRENT_AGENT_METRICS_PATH = (): string =>
  urlBuilder({
    path: `/api/agent-search-service/agent-stats/current`,
  });

export const ACTIVITY_FEED_AGGREGATIONS_PATH = ({
  slug,
  eventKeys,
  startingDate,
}: {
  slug: string;
  eventKeys: string[];
  startingDate: string;
}): string =>
  urlBuilder({
    path: `/api/activity-feed-service/portals/aggregations`,
    queryString: [
      ['filter[aggregate_on_field]', 'meta.event_action.keyword'],
      ['filter[meta.event_payload.agent_slug]', slug],
      ['filter[meta.event_actions]', eventKeys.join(',')],
      ['filter[>=occurred_at]', startingDate],
    ],
  });

// Profile
export const profileRecommendationCreatePath = (): string =>
  urlBuilder({
    path: `/api/agent-data-service/agent-dashboard/create-testimonials`,
  });

// Transaction
export const TRANSACTION_ACTIVITY_PATH = ({
  orderId,
  type,
  subType,
}: {
  orderId: number | string;
  type?: string;
  subType?: string;
}): string =>
  urlBuilder({
    path: `/api/order-data-service/agent-dashboard/orders/${orderId}/activity_feed`,
    ...((type || subType) && {
      queryString: [
        ...(type && [['type', type]]),
        ...(subType && [['sub_type', subType]]),
      ],
    }),
  });

export const TRANSACTION_DOCUMENTS_PATH = ({
  leadId,
}: {
  leadId: number | string;
}): string =>
  urlBuilder({
    path: `/api/file-service/documents`,
    queryString: [
      ['filter[attachable_type]', 'Lead'],
      ['filter[attachable_id]', `${leadId}`],
      ['compat', '1'],
    ],
  });

export const TRANSACTION_FILES_PATH = ({
  leadIds,
}: {
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files`,
    queryString: [
      ['include', 'file_attachment.attachable'],
      ['filter[attachable_type]', 'Lead'],
      ['filter[attachable_id]', leadIds.join(',')],
    ],
  });

// TODO: This is a temporary change, please remove it as soon as core-services has a endpoint for it.
export const TEMP_TRANSACTION_FILES_PATH = ({
  leadIds,
}: {
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files`,
    queryString: [
      ['filter[attachable_type]', 'Lead'],
      ['filter[attachable_id]', leadIds.join(',')],
    ],
  });

export const TRANSACTION_FILES_DOWNLOAD_PATH = ({
  docId,
  leadIds,
}: {
  docId: number | string;
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files/${docId}/download`,
    queryString: [['lead_ids', leadIds.join(',')]],
  });

export const TRANSACTION_FILES_VIEW_PATH = ({
  docId,
  leadIds,
}: {
  docId: number | string;
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files/${docId}/preview`,
    queryString: [['lead_ids', leadIds.join(',')]],
  });

export const CLIENT_TRANSACTION_FILES_DOWNLOAD_PATH = ({
  docId,
  leadIds,
}: {
  docId: number | string;
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files/${docId}/download`,
    queryString: [['lead_ids', leadIds.join(',')]],
  });

export const CLIENT_TRANSACTION_FILES_VIEW_PATH = ({
  docId,
  leadIds,
}: {
  docId: number | string;
  leadIds: Array<number | string>;
}): string =>
  urlBuilder({
    path: `/api/file-service/files/${docId}/preview`,
    queryString: [['lead_ids', leadIds.join(',')]],
  });

export const TRANSACTION_DOCUMENT_VIEW_PATH = ({
  docId,
  leadId,
}: {
  docId: number | string;
  leadId: number | string;
}): string =>
  urlBuilder({
    path: `/api/file-service/files/${docId}/preview`,
    queryString: [['lead_id', `${leadId}`]],
  });

export const TRANSACTION_CLIENTS_PATH = ({
  orderId,
  side,
}: {
  orderId: number | string;
  side: 'buyer' | 'seller';
}): string =>
  urlBuilder({
    queryString: [['side', side]],
    path: `/api/order-data-service/portals/transactions/${orderId}/contacts`,
  });

export const TRANSACTION_PEOPLE_PATH = ({
  orderId,
  side,
}: {
  orderId: number | string;
  side: 'buyer' | 'seller';
}): string =>
  urlBuilder({
    queryString: [['side', side]],
    path: `/api/order-data-service/portals/transactions/${orderId}/contacts`,
  });

export const TRANSACTION_PROGRESS_PATH = ({
  leadId,
}: {
  leadId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/portals/leads/${leadId}/lead-progress`,
  });

export const HLCS_PROGRESS_TRACKER_PATH = ({
  orderId,
}: {
  orderId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/portals/leads/${orderId}/progress-tracker`,
  });

export const TRANSACTION_LEAD_PROGRESS_PATH = ({
  leadId,
}: {
  leadId: string;
}): string =>
  urlBuilder({
    path: `/api/lead-data-service/portals/leads/${leadId}/lead-progress`,
  });

export const TRANSACTION_ORDER_PATH = ({
  orderId,
}: {
  orderId: number | string;
}): string =>
  urlBuilder({
    path: `/api/order-data-service/agent-dashboard/orders/${orderId}`,
    queryString: [
      [
        'include',
        'leads,leads.provider_leads,leads.provider_leads.providable,leads.agent_user_surveys,leads.offer_page',
      ],
      [
        'fields[escrow_office_lead]',
        'cancellation_pending_email_log,order_number,order_closing_on,loan_amount,order_purchase_price,milestone,order_status',
      ],
    ],
  });

export const TRANSACTION_TASKS_PATH = ({
  orderId,
  leadTypes,
}: {
  orderId: string;
  leadTypes?: string;
}): string =>
  urlBuilder({
    path: `/api/order-data-service/agent-dashboard/orders/${orderId}/tasks`,
    queryString: [
      ['include', 'assignee,template'],
      ...(leadTypes ? [['lead_types', leadTypes]] : []),
    ],
  });

export const SOFT_CREDIT_AUTHORIZATION_PATH =
  '/api/client-data-service/soft-credit-check/authorize';

export const CLIENT_TRANSACTIONS_PATH = urlBuilder({
  path: `/api/order-data-service/portals/transactions/client-transactions`,
  queryString: [['include', 'leads']],
});

export const CLIENT_TRANSACTION_PATH = ({
  orderId,
  side,
}: {
  orderId: string | string[];
  side: 'buyer' | 'seller';
}) =>
  urlBuilder({
    path: `/api/order-data-service/portals/transactions/${orderId}/${side}`,
    queryString: [['include', 'leads']],
  });

export const AGENT_SEARCH_PATH = ({
  name,
  email,
  licenseNumber,
  licenseState,
}: {
  name: string;
  email: string;
  licenseNumber: string;
  licenseState: string;
}) =>
  urlBuilder({
    path: `/api/agent-search-service/search/for-signup`,
    queryString: [
      ['filter[name]', name],
      ['filter[email]', email],
      ['filter[license_number]', licenseNumber],
      ['filter[license_state]', licenseState],
    ],
  });

export const CREATE_AGENT_PATH = urlBuilder({
  path: `/api/agent-data-service/agent-dashboard/agents`,
  queryString: [
    ['include', 'partner_program'],
    ['fields[partner_program]', 'destination_url'],
  ],
});

export const VERIFY_ZIP_CODES_PATH = (zip_codes: Array<string>) =>
  urlBuilder({
    path: `/api/area-data-service/zip-codes/verification`,
    queryString: [['zip_codes', zip_codes.join(',')]],
  });

export const AGENT_USER_SURVEY_PATH = ({ webId }: { webId: string }) =>
  urlBuilder({
    path: `/api/user-survey-service/agent-user-surveys/${webId}`,
  });

export const CLAIM_AGENT_PATH = ({ agentId }: { agentId: string | number }) =>
  urlBuilder({
    path: `/api/agent-data-service/agent-dashboard/agents/${agentId}/claim`,
    queryString: [
      ['include', 'partner_program'],
      ['fields[partner_program]', 'destination_url'],
    ],
  });

export const SEND_VERIFICATION_PATH = urlBuilder({
  path: `/api/user-verification-service/verifications`,
});

export const VERIFY_PIN_PATH = ({ id }: { id: string | number }) =>
  urlBuilder({
    path: `/api/user-verification-service/verifications/${id}`,
  });

export const CLIENT_TASKS_PATH = ({
  leadIds = [],
  userId,
}: {
  leadIds?: Array<string | string[]>;
  userId: string | number;
}): string =>
  urlBuilder({
    path: `/api/task-service/v2/tasks`,
    queryString: [
      ['filter[attachable_type]', 'Lead'],
      ['filter[attachable_id]', leadIds.join(',')],
      ['filter[assignee_id]', userId as string],
      ['filter[type]', 'ClientTask'],
      ['filter[_completion_status]', 'ready,completed'],
      ['include', 'assignee,template'],
      [
        'fields[task]',
        [
          'template',
          'title',
          'assigned_at',
          'completed_at',
          'created_at',
          'template_slug',
          'metadata',
          'note',
        ].join(','),
      ],
      ['fields[template]', ['description'].join(',')],
      ['sort', 'priority'],
    ],
  });

export const TRANSACTIONS_PATH = ({
  page = '1',
  search,
  similarEmails,
  service,
  sort,
  perPage = '10',
  teamMemberId,
}: {
  page: string;
  search?: string;
  service?: string;
  similarEmails?: string;
  sort?: string;
  perPage?: string;
  teamMemberId?: string;
}): string =>
  urlBuilder({
    path: '/api/order-data-service/agent-dashboard/orders',
    queryString: [
      ['include', 'leads,leads.provider_leads,leads.provider_leads.providable'],
      [
        'fields[lead]',
        'address,city_display,city_name,state_code,user_type,name,provider_leads,stage,assigned_to,email,phone,zip_code',
      ],
      ['fields[provider_lead]', 'stage,providable'],
      ['fields[escrow_office_lead]', 'order_closing_on,buyer'],
      ['page[number]', page],
      ['page[size]', perPage],
      ...(sort ? [['sort', sort]] : []),
      ...(search ? [['filter[_search]', search]] : []),
      ...(similarEmails ? [['filter[_similar][emails]', similarEmails]] : []),
      ...(service ? [['filter[user_type]', service]] : []),
      ['filter[!stage]', 'failed'],
      ...(teamMemberId === 'All'
        ? []
        : [['filter[_user_memberships_accessible]', teamMemberId]]),
    ],
  });

// Offers management endpoints
export const ORDER_OFFERS_LIST_PATH = ({
  orderId,
}: {
  orderId: string;
}): string =>
  urlBuilder({
    path: `/api/order-data-service/agent-dashboard/orders/${orderId}/offers`,
  });

export const ORDER_OFFERS_EXPORT_PATH = ({
  orderId,
  sort,
  hidePresenterInfo,
  hideExpiredOffers,
  favoriteOnly,
}: {
  orderId: string;
  sort: string;
  hidePresenterInfo: boolean;
  hideExpiredOffers: boolean;
  favoriteOnly: boolean;
}): string =>
  urlBuilder({
    path: `/api/order-data-service/agent-dashboard/orders/${orderId}/export-offers-csv`,
    queryString: [
      [
        'fields[offer]',
        `status,data,viewed_at,favorite,client_id${
          hidePresenterInfo ? '' : ',buyer_agent_email'
        }`,
      ],
      ['sort', sort],
      ...(favoriteOnly ? [['favorite_only', 'true']] : []),
      ...(hidePresenterInfo ? [['hide_presenter_info', 'true']] : []),
      ...(hideExpiredOffers ? [['hide_expired', 'true']] : []),
    ],
  });

export const OFFERS_PAGES_PATH = '/api/offer-data-service/offer-pages';

export const OFFERS_PAGE_PATH = ({
  offersPageId,
}: {
  offersPageId: string;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offer-pages/${offersPageId}`,
  });

export const OFFERS_PAGE_IMAGE_UPLOAD_PATH = ({
  offersPageId,
  type,
}: {
  offersPageId: string;
  type: OffersPageImageUploadType;
}) =>
  urlBuilder({
    path: `/api/offer-data-service/offer-pages/${offersPageId}/create-presigned-url/${type}`,
  });

export const OFFERS_PAGE_PUBLISH_PATH = ({
  offersPageId,
}: {
  offersPageId: string;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offer-pages/${offersPageId}/publish`,
  });

export const OFFERS_PAGE_UNPUBLISH_PATH = ({
  offersPageId,
}: {
  offersPageId: string;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offer-pages/${offersPageId}/unpublish`,
  });

export const OFFERS_PAGE_SLUG_PATH = ({ slug }: { slug: string }): string =>
  urlBuilder({
    path: `/api/offer-data-service/offer-page-by-slug/${slug}`,
    queryString: [
      ['include', 'lead'],
      ['fields[lead]', 'full_address'],
    ],
  });

export const OFFER_CREATE_PATH = '/api/offer-data-service/offers';

export const OFFER_DETAILS_PATH = ({
  offerId,
  queryString,
}: {
  offerId: string;
  queryString?: Array<string[]>;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/${offerId}`,
    queryString,
  });

export const OFFER_DETAILS_BY_EXTERNAL_ID_PATH = ({
  externalId,
  queryString,
}: {
  externalId: string;
  queryString?: Array<string[]>;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/public/${externalId}`,
    queryString,
  });

export const OFFER_DETAILS_BY_CLIENT_ID_PATH = ({
  clientId,
  queryString,
}: {
  clientId: string;
  queryString?: Array<string[]>;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/client/${clientId}`,
    queryString,
  });

export const OFFER_SUBMIT_PATH = ({ offerId }: { offerId: string }): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/${offerId}/submit`,
  });

export const OFFER_SUBMIT_AS_LISTING_AGENT_PATH = ({
  offerId,
}: {
  offerId: string;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/${offerId}/agent/submit`,
  });

export const OFFER_FAVORITE_PATH = ({ offerId }: { offerId: string }): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/${offerId}/favorite`,
  });

export const OFFER_VIEWED_PATH = ({ offerId }: { offerId: string }): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/${offerId}/viewed`,
  });

export const OFFER_DOCUMENT_DOWNLOAD_PATH = ({
  clientId,
  docId,
}: {
  clientId: string;
  docId: string;
}): string =>
  urlBuilder({
    path: `/api/offer-data-service/offers/client/${clientId}/documents/${docId}/download`,
  });

export const cancelEscrowPath =
  '/api/sales-comms-service/closing-services/cancel-closing';

// File upload flow
export const FETCH_PRESIGNED_S3_LINK =
  '/api/file-service/bucket/create-signed-url';

export const PROCESS_AND_CREATE_DOCUMENT = '/api/file-service/documents';

export const PROCESS_AND_CREATE_FILE = '/api/file-service/files';

// Submission flow
export const CLOSING_SERVICES_INSTANT_OPEN = `/api/escrow-order-service/escrow-leads/instant_open?include=order`;

export const CLOSING_SERVICES_NEW_LEAD = `/api/escrow-order-service/escrow-leads?include=order`;

export const CLOSING_SERVICES_EXISTING_LEAD = ({
  parentLeadId,
}: {
  parentLeadId: number;
}): string =>
  urlBuilder({
    path: `/api/escrow-order-service/escrow-leads/${parentLeadId}`,
    queryString: [['include', 'order']],
  });

export const CASH_OFFER_NEW_LEAD = `/api/order-data-service/agent-dashboard/cash-offers/cash-offer-order`;

export const CASH_OFFER_EXISTING_LEAD = `/api/order-data-service/agent-dashboard/cash-offers/cash-offer-from-lead`;

export const TRADE_IN_NEW_LEAD = `/api/order-data-service/agent-dashboard/trade-ins/trade-in-order`;

export const TRADE_IN_EXISITNG_LEAD = `/api/order-data-service/agent-dashboard/trade-ins/trade-in-from-lead`;

export const TRANSACTION_TEAM_MEMBERS_PATH = `/api/team-service/agent-dashboard/transaction-team-members`;

// Teams endpoints
export const SHOW_TEAM_MEMBERS_PATH = ({
  agentTeamId,
}: {
  agentTeamId: string;
}): string =>
  urlBuilder({
    path: `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}`,
    queryString: [
      [
        'include',
        'agent_team_invitations,agent_team_memberships,agent_team_memberships.user',
      ],
    ],
  });

export const TEAMS_INVITE_PATH = ({
  agentTeamId,
}: {
  agentTeamId: string;
}): string =>
  urlBuilder({
    path: `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/invitations`,
  });

export const CREATE_TEAM_PATH = `/api/agent-team-service/agent-dashboard/agent-teams`;

export const TEAMS_RESEND_INVITATION_PATH = ({
  agentTeamId,
  inviteId,
}: {
  agentTeamId: string;
  inviteId: string;
}): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/invitations/${inviteId}/resend`;

export const TEAMS_ACCEPT_INVITATION_PATH = ({
  agentTeamId,
  inviteId,
}: {
  agentTeamId: string;
  inviteId: string;
}): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/invitations/${inviteId}/accept`;

export const TEAMS_DELETE_INVITATION_PATH = ({
  agentTeamId,
  memberId,
}: {
  agentTeamId: string;
  memberId: string;
}): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/invitations/${memberId}`;

export const TEAMS_DELETE_MEMBERSHIP_PATH = ({
  agentTeamId,
  memberId,
}: {
  agentTeamId: string;
  memberId: string;
}): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/team-memberships/${memberId}`;

export const TEAMS_DECLINE_INVITATION_PATH = ({
  agentTeamId,
  inviteId,
}: {
  agentTeamId: string;
  inviteId: string;
}): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/invitations/${inviteId}/decline`;

export const TEAMS_INVITATION_PATH = ({ token }: { token: string }): string =>
  urlBuilder({
    path: '/api/agent-team-service/agent-dashboard/agent-teams/invitations/invite_token',
    queryString: [
      ['invite_token', token],
      ['include', 'agent_team'],
    ],
  });

export const TEAM_MEMBER_UPDATE_PATH = (teamMembershipId: string) =>
  `/api/agent-team-service/team-memberships/${teamMembershipId}/preferences`;

export const TEAM_MEMBER_FETCH_PATH = (
  page?: string,
  query?: string,
  sortingParam?: string
) => {
  const searchParam = query ? [['_search', query]] : [];

  return urlBuilder({
    path: '/api/agent-team-service/team-memberships',
    queryString: [
      ['page[number]', `${page}`],
      ['page[size]', '200'],
      ['include', 'referral_preferences,user.agent'],
      ...searchParam,
      ...[sortingParam ? ['sort', sortingParam] : []],
    ],
  });
};

export const TEAM_LEAD_ROUTING_PATH = ({
  agentTeamId,
}: {
  agentTeamId: string;
}): string =>
  urlBuilder({
    path: `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/lead-routing`,
  });

// MapBox
export const MAP_IMAGE_PATH = ({
  lat,
  lng,
  size = '64x64',
  zoom = '14',
}: {
  lat: string;
  lng: string;
  size?: string;
  zoom?: number | string;
}): string =>
  urlBuilder({
    protocol: 'https',
    host: 'api.mapbox.com',
    path: `/styles/v1/mapbox/streets-v11/static/${lng},${lat},${zoom},0/${size}`,
    queryString: [['access_token', config.mapboxAccessToken]],
  });

export const MAPBOX_PLACES_SEARCH_API = ({
  search,
}: {
  search: string;
}): string =>
  urlBuilder({
    protocol: 'https',
    host: 'api.mapbox.com',
    path: `/geocoding/v5/mapbox.places/${search}.json`,
  });

export const PROPERTY_SERVICE_SEARCH_API = (search: string) =>
  urlBuilder({
    host: 'https://release.hapi.homelight.com', // TODO: Replace with env var
    path: '/api/property-service/v2/properties/autocomplete',
    queryString: [
      ['value', search],
      [
        'fields[property_suggestion]',
        'city,state_or_province,postal_code,street_number,street_name,display_address,hl_full_address',
      ],
    ],
  });

export const GET_PROPERTY_UUID = (fullAddress: string) =>
  `/api/property-service/search/find-by-full-address?full_address=${fullAddress}`;

export const GET_PROPERTY_UUID_PUBLIC = (fullAddress: string) =>
  `/api/property-service/public-search/public-find-by-full-address?full_address=${fullAddress}`;

export const PROPERTY_ELIGIBILITY_CHECK = (uuid: string, stateCode: string) =>
  `/api/property-service/v2/eligibility/check?property_uuid=${uuid}&property_state=${stateCode}`;

export const PROPERTY_ELIGIBILITY_CHECK_PUBLIC = (
  uuid: string,
  stateCode: string
) =>
  `/api/property-service/v2/public-eligibility/public-check?property_uuid=${uuid}&property_state=${stateCode}`;

export const STATE_ELIGIBILITY_PATH = (state: string, partner: string) =>
  `/api/property-service/v2/public-lead-submission/state-eligibility?state_code=${state}&partner_slug=${partner}`;

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECK_PUBLIC =
  '/api/property-service/v2/public-eligibility/automated-bbys-check';
export const INSTANT_APPROVAL_CHECK_PUBLIC =
  '/api/property-service/v2/public-eligibility/final-eligibility-checks';

export const GET_LEAD_PUBLIC = (externalId: string) =>
  `/api/lead-data-service/public-leads/find-lead-by-external-id?external_id=${externalId}`;

export const REQUEST_AGREEMENT_PUBLIC =
  '/api/lead-data-service/trade-ins/request_agreement';

export const CHANGE_LEAD_MEMBERSHIP_URL = (leadId: string): string =>
  `/api/lead-data-service/agent-dashboard/leads/${leadId}/agent-team-membership`;

export const CHANGE_ALT_CONTACT_URL = (leadId: string): string =>
  `/api/lead-data-service/agent-dashboard/leads/${leadId}/agent-lead-alt-contacts`;

export const ADD_NOTE_URL = (leadId: string): string =>
  `/api/lead-data-service/agent-dashboard/leads/${leadId}/agent-lead-create-note`;

export const UPDATE_STAGE_URL = (leadId: string): string =>
  `/api/lead-data-service/agent-dashboard/leads/${leadId}/agent-lead-update`;

export const TEAM_SUMMARY_COUNTS_URL = `/api/lead-data-service/agent-dashboard/leads/team-summary-counts`;

export const REFERRAL_LIST_ENDPOINT =
  '/api/lead-data-service/agent-dashboard/leads/referral-index';

export const EDIT_PROFILE_AGENT_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current';

export const EDIT_TEAM_MEMBER_PROFILE_ENDPOINT = (teamMembershipId: string) =>
  `/api/agent-team-service/team-memberships/${teamMembershipId}/profile`;

export const CREATE_AGENT_AWARD_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/create-award';

export const REMOVE_AGENT_AWARD_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/remove-award';

export const UPDATE_AGENT_SPECIALTIES_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/update-specialties';

export const UPDATE_AGENT_CERTIFICATIONS_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/update-certifications';

export const DELETE_AGENT_TESTIMONIAL_ENDPOINT = (testimonialId: string) =>
  `/api/agent-data-service/agent-dashboard/agents/current/testimonials/${testimonialId}`;

export const CREATE_AGENT_TESTIMONIAL_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/testimonials';

export const EDIT_AGENT_TESTIMONIAL_ENDPOINT =
  DELETE_AGENT_TESTIMONIAL_ENDPOINT;

export const HIDE_AGENT_REVIEW_ENDPOINT = (reviewId: string) =>
  `/api/agent-data-service/agent-dashboard/agents/current/reviews/${reviewId}/hide`;

export const PRESIGN_PROFILE_PICTURE_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/picture-presigned-url';

export const UPDATE_PROFILE_PICTURE_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/update-profile-picture';

export const UPDATE_BROKERAGE_IMAGE_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/update-brokerage-image';

export const REFERRAL_PREFERENCE_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/referral-preference';

export const LOCATION_PREFERENCE_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/location-preferences';

export const HISTORICAL_TRANSACTIONS_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/transactions';

export const MULTIPLE_HISTORICAL_TRANSACTIONS_ENDPOINT =
  '/api/agent-data-service/agent-dashboard/agents/current/transactions/bulk';

export const SHORT_URL_ENDPOINT = `/api/short-url-service/generate`;

export const REFERRAL_DETAIL_ENDPOINT = (referralId: string): string =>
  `/api/lead-data-service/agent-dashboard/leads/${referralId}/agent-lead-detail`;

export const REFERRAL_DETAIL_BY_TOKEN_ENDPOINT = (token: string): string =>
  urlBuilder({
    path: `/api/lead-data-service/agent-dashboard/leads/text-referral-by-token`,
    queryString: [['token', token]],
  });

export const SEND_LEAD_CSV_THROUGH_EMAIL =
  '/api/lead-data-service/agent-dashboard/leads/agent-lead-csv-email';

export const REFERRAL_GENERATE_SHIPPING_LABEL_ENDPOINT = (
  referralId: string
): string =>
  `/api/lead-data-service/agent-dashboard/leads/${referralId}/generate-shipping-label`;

export const GET_AGENT_TEAM_MEMBERSHIPS = (teamId: string): string =>
  `/api/agent-team-service/agent-dashboard/agent-teams/${teamId}/memberships`;

export const CHANGE_PASSWORD_URL =
  '/api/user-auth-service/user-login/update-password';

export const SUBMIT_USER_SURVEY_RESPONSES_PATH =
  '/api/user-survey-service/user-survey-responses';

export const SEARCH_LOAN_OFFICERS = `/api/lead-data-service/lender-leads/loan-officers`;

export const SUBMIT_LENDER_BBYS_LEAD =
  '/api/property-service/v2/public-lead-submission/run-checks-and-submit-lead';

export const SUBMIT_LENDER_BBYS_PRE_LEAD =
  '/api/order-data-service/public-bbys/pre-lead-submission/create-pre-lead';

export const SUBMIT_LENDER_BBYS_ALL_CASH_LEAD =
  '/api/order-data-service/public-bbys/bbys/all-cash-order';

export const VALIDATE_TASK_TOKEN =
  '/api/order-data-service/public-bbys/validate-token/validate-and-regenerate-token';

export const FETCH_WHITE_LABEL_STYLE = (partnerSlug: string) =>
  `/api/lead-data-service/partners/${partnerSlug}`;

export const SUBMIT_INFORMATION_REQUEST_PATH = ({
  taskId,
}: {
  taskId: string;
}): string =>
  urlBuilder({
    path: `/api/escrow-order-service/tasks/information-requests/${taskId}`,
  });

// Generic current user endpoint
export const GENERIC_USER_CURRENT_PATH = urlBuilder({
  path: '/api/user-auth-service/users/current',
  queryString: {
    'fields[user]': ['first_name', 'last_name', 'email', 'impersonated'],
  },
});

export const MFA_VERIFICATION_PATH_AUTH = urlBuilder({
  path: '/api/mfa-service/auth',
});

export const MFA_REGISTRATION_PATH = urlBuilder({
  path: '/api/mfa-service/registrations',
});

export const MFA_REGISTRATION_SUBMIT_PATH = (uuid: string) =>
  urlBuilder({
    path: `/api/mfa-service/registrations/${uuid}`,
  });

export const SEND_MFA_VERIFICATION_PATH = urlBuilder({
  path: '/api/mfa-service/verifications',
});

export const VERIFY_MFA_CODE_PATH = (uuid: string) =>
  urlBuilder({
    path: `/api/mfa-service/verifications/${uuid}`,
  });

export const SELLERS_NET_SHEET_QUOTE_PARAMETERS = `/api/escrow-order-service/seller-net-estimates/quote_parameters`;

export const SELLERS_NET_SHEET_QUOTE = `/api/escrow-order-service/seller-net-estimates/quote`;

export const SNAPSHOT_PATH = (uuid: string) =>
  urlBuilder({
    path: `/api/lead-data-service/snapshot/${uuid}`,
  });

export const CREATE_SNAPSHOT_PATH = `/api/lead-data-service/snapshot`;

//
// Homelight monolith
//
export const LOGIN_URL = ({
  redirect_url = '',
}: {
  redirect_url?: string;
}): string => {
  const queryString = [];

  if (redirect_url) {
    queryString.push(['return', redirect_url]);
  }

  return urlBuilder({
    host: config.homelightHost,
    path: '/users/login',
    queryString: queryString,
  });
};

export const RESEND_VERIFICATION_PATH = urlBuilder({
  host: config.homelightHost,
  path: '/users/resend_confirmation',
});

export const USERS_URL = urlBuilder({
  host: config.homelightHost,
  path: '/users',
});

export const CLAIM_AGENT_LEAD_PATH = (token: string) =>
  urlBuilder({
    path: `/agents/claim-referral`,
    host: config.homelightHost,
    queryString: [['token', token]],
  });

export const HELLOSIGN_API = ({
  signatureId,
  signatureToken,
}: {
  signatureId: string;
  signatureToken: string;
}): string =>
  `https://app.hellosign.com/editor/embeddedSign?signature_id=${signatureId}&token=${signatureToken}`;

// Broadcast claiming
export const CLAIM_OR_DECLINE_AGENT_LEAD_PATH = (agentLeadId: string) =>
  urlBuilder({
    path: `/api/agent-team-service/agent-lead-invitations/${agentLeadId}`,
  });

// Call to claim
export const CALL_TO_CLAIM_PATH = () =>
  urlBuilder({
    path: `/api/provider-api-service/twilio/calls/start-lead-claim`,
  });

export const LEAD_ROUTING_PATH = (agentTeamId: string) =>
  urlBuilder({
    path: `/api/agent-team-service/agent-dashboard/agent-teams/${agentTeamId}/lead-routing`,
  });

export const LEAD_BOOST_PATH = (agentId: string) =>
  urlBuilder({
    path: `/api/agent-team-service/agent-dashboard/agents/${agentId}/lead-boost`,
  });

// Digital Call init without initiating the Claim part
export const CALL_CLIENT_PATH = () =>
  urlBuilder({
    path: `/api/provider-api-service/twilio/calls/start-digital-call`,
  });

export const PROPERTY_LOOKUPS_PATH = ({
  userId,
  mappingType,
  pageNumber = 1,
  pageSize = 999,
}: {
  userId: string;
  mappingType: PropertyMappingType;
  pageNumber: number;
  pageSize: number;
}) => {
  const mappingTypeParams =
    mappingType === 'all' ? '' : `&filter[mapping_type]=${mappingType}`;

  return urlBuilder({
    path: `/api/property-service/property-lookups?filter[user_id]=${userId}${mappingTypeParams}&page[number]=${pageNumber}&page[size]=${pageSize}&sort=-created_at`,
  });
};

export const INTERESTED_PROPERTIES_PATH = ({ uuids }: { uuids: string }) =>
  urlBuilder({
    path: `/api/property-service/properties?filter[_url_eligible]=true&filter[_uuids_formatted]=${uuids}`,
  });

// Pay Per Lead
export const PPL_AGENT_DATA_PATH = () =>
  urlBuilder({
    path: '/api/agent-data-service/agent-dashboard/agents/current',
    queryString: [
      [
        'include',
        'ppl_billing_infos,ppl_provider_settings,ppl_provider_agreements',
      ],
      ['filter[ppl_provider_settings.active]', 'true'],
      ['fields[ppl_provider_settings]', 'area_type,location,user_type,price'],
      ['fields[ppl_provider_agreements]', 'accepted_at'],
    ],
  });

export const PPL_PROVIDER_DATA_PATH = ({
  token,
  providerId,
  providerType,
}: {
  token: string;
  providerId: string;
  providerType: string;
}) =>
  urlBuilder({
    path: `/api/subscription-service/${providerType}/${providerId}`,
    queryString: [
      ['token', token],
      [
        'include',
        'ppl_billing_infos,ppl_provider_settings,ppl_provider_agreements',
      ],
      ['filter[ppl_provider_settings.active]', 'true'],
      ['fields[ppl_provider_settings]', 'area_type,location,user_type,price'],
      ['fields[ppl_provider_agreements]', 'accepted_at'],
    ],
  });

export const PPL_PROVIDER_ACCEPT_AGREEMENT = ({
  token,
  providerId,
  providerType,
}: {
  token: string;
  providerId: string;
  providerType: string;
}) =>
  urlBuilder({
    path: `/api/subscription-service/${providerType}/${providerId}/accept-ppl`,
    queryString: [['token', token]],
  });

export const PPL_PROVIDER_CREATE_CHECKOUT_SESSION = ({
  token,
  providerId,
  providerType,
}: {
  token: string;
  providerId: string;
  providerType: string;
}) =>
  urlBuilder({
    path: `/api/subscription-service/${providerType}/${providerId}/setup`,
    queryString: [['token', token]],
  });

export const PPL_PROVIDER_PAYMENT_INFO = ({
  token,
  providerId,
  providerType,
}: {
  token: string;
  providerId: string;
  providerType: string;
}) =>
  urlBuilder({
    path: `/api/subscription-service/${providerType}/${providerId}/payment-info`,
    queryString: [['token', token]],
  });

export const PPL_ACCEPT_AGREEMENT = () =>
  urlBuilder({
    path: '/api/agent-data-service/agent-dashboard/agents/current/agreements/accept-ppl',
  });

export const PPL_CREATE_CHECKOUT_SESSION = () =>
  urlBuilder({
    path: '/api/subscription-service/checkout-sessions/setup',
  });

export const LEAD_SURVEY_FEEDBACK = ({
  feedbackIds,
}: {
  feedbackIds: Array<string>;
}) => {
  const feedbackIdsParam = feedbackIds.map((feedbackId) => [
    'ids[]',
    feedbackId,
  ]);

  return urlBuilder({
    path: `/api/survey-service/lead-survey-feedbacks/search-by-ids`,
    queryString: [...feedbackIdsParam, ['include', 'user,lead,attachable']],
  });
};

export const UPDATE_LEAD_SURVEY_FEEDBACK = () =>
  urlBuilder({
    path: `/api/survey-service/lead-survey-feedbacks/bulk-update`,
  });
