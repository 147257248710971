import styled from 'styled-components';

export const ProgramHeader = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray5};
  position: sticky;
  top: 0;
  z-index: 9;
`;

export const ProgramFooter = styled.div<{ fixedFooter?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.coolGray5};
  position: ${({ fixedFooter }) => (fixedFooter ? 'fixed' : 'sticky')};
  bottom: 0;
  z-index: 9;
  width: 100%;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 8px;
  cursor: pointer;
`;

export default ProgramHeader;
