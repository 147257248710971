import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { STATES } from '@hl-portals/constants';

import { BoxTypes } from '../../Box';
import InputGroup from '../../InputGroup';
import { Select } from '../../Select';

export type StateSelectProps = {
  name: string;
  label: string;
  testId?: string;
  containerProps?: BoxTypes;
  placeholder?: string;
  mode?: 'onBlur' | 'onChange';
};

export const StateSelect = ({
  name,
  label,
  testId,
  containerProps,
  placeholder = 'Select state',
  mode,
}: StateSelectProps) => {
  const { formState, setValue, watch, trigger } = useFormContext();
  const error = formState.errors[name];
  const value = watch(name);

  const STATE_OPTIONS = useMemo(
    () =>
      Object.entries(STATES).map((state) => {
        const [stateCode, stateName] = state;
        return { stateCode, stateName };
      }),
    []
  );

  return (
    <InputGroup
      label={label}
      error={error?.message?.toString()}
      {...containerProps}
    >
      <Select
        width="100%"
        options={STATE_OPTIONS.map(({ stateName, stateCode }) => ({
          text: stateName,
          value: stateCode,
        }))}
        placeholder={placeholder}
        onChange={(value) => setValue(name, value)}
        onBlur={() => {
          if (mode === 'onBlur') {
            trigger(name);
          }
        }}
        selectedValue={value}
        isError={!!error}
        height="45px"
        dataTest={testId}
      />
    </InputGroup>
  );
};
