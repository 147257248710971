import {
  BordersProps,
  FlexboxProps,
  SizingProps,
  SpacingProps,
  sizing,
} from '@mui/system';
import styled, { createGlobalStyle } from 'styled-components';

import { MouseEvent, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { media } from '@hl-portals/helpers';

import { Box } from '../Box';
import { Icon } from '../Icon';

export const RemoveScrollFromBody = createGlobalStyle`
  body {
    position: fixed !important;
    width: 100%;
  }
`;

const ModalWrapper = styled(Box)`
  position: absolute;
  width: 100vw;
  max-width: 490px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: 100%;
  ${sizing}

  ${media.desktop`
    width: 80vw;
    max-height: 80vh;
  `}

  svg {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => `${theme.colors.darkBlue}4D`};
  z-index: 50;
`;

type ModalProps = {
  children: ReactNode;
  onClickIcon?: (event: MouseEvent<HTMLElement>) => void;
  onClickBackdrop?: (event: MouseEvent<HTMLElement>) => void;
} & FlexboxProps &
  SpacingProps &
  SizingProps &
  BordersProps;

export const Modal = ({
  children,
  onClickIcon,
  onClickBackdrop,
  ...extraProps
}: ModalProps): React.ReactElement => {
  const modalRootId = 'modal-root';
  const onBackdrop = (event: any) => {
    if (
      typeof onClickBackdrop !== 'undefined' &&
      event.target.parentElement.id === modalRootId
    ) {
      onClickBackdrop(event);
    }
  };
  return createPortal(
    <Backdrop onClick={onBackdrop} data-test="modal-backdrop">
      <RemoveScrollFromBody />
      <ModalWrapper {...extraProps}>
        {onClickIcon && (
          <Icon
            type="times"
            size={18}
            fill="darkBlue"
            onClick={onClickIcon}
            data-test="modal-close-icon"
          />
        )}
        {children}
      </ModalWrapper>
    </Backdrop>,
    // @ts-ignore
    document.getElementById(modalRootId)
  );
};
