import React, { useContext } from 'react';
import { ModalContext } from './modal.context';
import ModalPortal from './components/modal-portal.component';

const ModalContainer = (): React.ReactElement => {
  const { closeModal, onAnimationEnd, stack } = useContext(ModalContext);

  const DEFAULT_ANIMATION_CLASSES = {
    CLOSED: '--closed',
    OPENING: '--opening',
    OPEN: '--open',
    CLOSING: '--closing',
    OPENING_DRAWER: '--opening-drawer',
    CLOSING_DRAWER: '--closing-drawer',
  };
  const defaultHandler = () => { };

  return (
    <>
      {stack.map((state, index) => {
        const { currentStatus, component, options = {} } = state;

        const defaultModalProps = {
          currentStatus,
          closeModalHandler: closeModal || defaultHandler,
          modalDataTest: options.modalDataTest,
          hideCloseIcon: options.hideCloseIcon,
          enableCloseOnEsc: true,
          enableBackDropClick: true,
          animationClassName: DEFAULT_ANIMATION_CLASSES[currentStatus],
          wrapperClassName: options.wrapperClassName,
          enableClose: options.enableClose,
          asDrawer: options.asDrawer,
          key: index,
        };

        const openingPortal = (
          <ModalPortal
            {...defaultModalProps}
            onStageAnimationEnd={onAnimationEnd}
          >
            {component}
          </ModalPortal>
        );

        const closingPortal = (
          <ModalPortal
            {...defaultModalProps}
            onBackdropAnimationEnd={onAnimationEnd}
          >
            {component}
          </ModalPortal>
        );

        return {
          CLOSED: null,
          OPENING: openingPortal,
          OPEN: <ModalPortal {...defaultModalProps}>{component}</ModalPortal>,
          CLOSING: closingPortal,
          OPENING_DRAWER: openingPortal,
          CLOSING_DRAWER: closingPortal,
        }[currentStatus || 'CLOSED'];
      })}
    </>
  );
};

export default ModalContainer;
