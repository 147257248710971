export default {};

export const getGeoinfoFromPlaces = ({
  features,
}: {
  features: MapboxPlaceFeature[];
}): Array<TransformedMapboxPlace> =>
  features.map((place) => {
    const contextIds = [
      'postcode',
      'place',
      'district',
      'region',
      'neighborhood',
      'country',
    ] as const;
    const [zipCode, city, district, state, neighborhood, country] =
      contextIds.map((k) => place.context.find((c) => c.id.includes(k))?.text);
    const region = place.context.find((c): c is ContextItem<'region'> =>
      c.id.includes('region')
    );
    const [countryCode = '', stateCode = ''] =
      region?.short_code?.split('-') ?? [];

    const [lon, lat] = place.center;

    return {
      city,
      country,
      countryCode,
      district,
      fullAddress: place?.place_name.replace(`, ${country}`, ''),
      lat,
      lon,
      neighborhood,
      number: place?.address,
      state: state as State,
      stateCode: stateCode as StateCode | '',
      street: place?.text,
      zipCode,
    };
  });
