import { FadeIn } from '../Animations';
import Spinner from '../Spinner';

export const PageLoading = () => (
  <FadeIn
    width="100%"
    height="100vh"
    justifyContent="center"
    alignItems="center"
  >
    <Spinner xl m="auto" mt="20px" />
  </FadeIn>
);
