import { AxiosError } from 'axios';

import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';
import { SnapshotResponse } from '@hl-portals/libs/types';

import { SNAPSHOT_PATH } from '@hl-portals/constants';

import { handleErrors } from '@hl-portals/helpers';

export const FETCH_SNAPSHOT_QUERY_KEY = (uuid: string) => [
  'FETCH_SNAPSHOT',
  uuid,
];

export function useFetchSnapshot<Payload>(
  uuid: string,
  options?: UseQueryOptions<SnapshotResponse<Payload>['data']>
) {
  const key = FETCH_SNAPSHOT_QUERY_KEY(uuid);

  return useQuery(
    key as QueryKey,
    async () => {
      const res = await http.public.get<SnapshotResponse<Payload>>(
        SNAPSHOT_PATH(uuid)
      );
      return res.data?.data;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        handleErrors(args[0] as AxiosError);
      },
      enabled: !!uuid,
    }
  );
}
