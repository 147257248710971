import { IconColors } from './types';

type Colors = IconColors | undefined;
type RequiredColors = IconColors;

export function getActualColors(
  colors: Partial<Colors>,
  defaultColors: RequiredColors
): RequiredColors {
  return colors ? { ...defaultColors, ...colors } : defaultColors;
}
