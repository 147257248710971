import { MouseEvent } from 'react';

import Image from 'next/image';

import { Buttons, Icons, icons } from '@hl-portals/constants';

import { useModal } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Button } from '../Button';

type DialogProps = {
  icon?: Icons;
  children: React.ReactNode;
  buttons: Array<{
    action: ({
      event,
      closeDialog,
    }: {
      event: MouseEvent<HTMLElement, globalThis.MouseEvent>;
      closeDialog: () => void;
    }) => void;
    text: string;
    color?: Buttons;
  }>;
  buttonsPosition?: 'left' | 'right';
  buttonWidth?: string;
} & BoxTypes;

const Dialog = ({
  icon,
  children,
  buttons,
  buttonsPosition = 'left',
  buttonWidth,
  ...otherProps
}: DialogProps): React.ReactElement => {
  const { closeModal } = useModal();
  return (
    <Box
      maxWidth={{ md: '512px' }}
      width={{ md: '100vw' }}
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      m={{ xs: '0 12px', md: '32px 24px' }}
      data-test="dialog"
    >
      {icon && (
        <Image
          src={icons[icon].file}
          height={icons[icon].height}
          width={icons[icon].width}
          alt={icons[icon].alt}
        />
      )}
      <Box
        width={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="12px"
        mb="32px"
        {...otherProps}
      >
        {children}
      </Box>
      <Box
        width={1}
        justifyContent={buttonsPosition === 'right' ? 'flex-end' : 'flex-start'}
        flexDirection={{ xs: 'column', md: 'row' }}
        gap="0 4px"
      >
        {buttons.map((button, index) =>
          button ? (
            <Button
              key={button.text}
              onClick={(event) =>
                button.action({ event, closeDialog: closeModal })
              }
              size="large"
              color={button.color}
              {...(buttonWidth && {
                width: {
                  xs: 1,
                  md: buttonWidth,
                },
              })}
              mt={{ xs: '8px', md: '0px' }}
              data-test={`action-button-${index}`}
            >
              {button.text}
            </Button>
          ) : null
        )}
      </Box>
    </Box>
  );
};

export default Dialog;
