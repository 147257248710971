/* eslint-disable import/no-cycle */
import { sizing, spacing } from '@mui/system';
import styled, { css } from 'styled-components';

import { paragraphHelper } from '@hl-portals/helpers';

import { TextAreaProps } from '.';

export const TextAreaInput = styled.textarea<TextAreaProps>`
  ${({ theme, isError }) => css`
    width: 100%;
    margin: 0;
    padding: 12px;
    border: 1px solid ${theme.colors.coolGray4};
    color: ${theme.colors.darkBlue};
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    resize: vertical;
    ${paragraphHelper({ type: 'text-small', theme })}
    min-height: 80px;
    font-family: 'Open Sans', sans-serif;
    ${isError
      ? css`
          padding: calc(${theme.spacing}px - 1px);
          border: 2px solid ${theme.colors.cadmiumRed};
        `
      : css`
          &:focus {
            padding: calc(${theme.spacing}px - 1px);
            border: 2px solid ${theme.colors.electricBlue};
          }
        `}
    &:focus {
      outline: none;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
    }

    &:focus::-moz-placeholder {
      color: transparent;
    }
  `}
  ${sizing}
  ${spacing}
`;

export default {};
