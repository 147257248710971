import {
  borders,
  display,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { Colors } from '@hl-portals/constants';

import { BoxTypes } from '../Box';

type ButtonIconWrapperProps = {
  size?: number;
  fill?: Colors;
} & BoxTypes;

export const ButtonIconWrapper = styled.button<ButtonIconWrapperProps>`
  ${borders};
  ${display};
  ${positions};
  ${spacing};
  ${sizing};
  ${palette};
  ${shadows};

  height: unset;
  padding: 0;
  background-color: unset;
  border: none;

  ${({ theme, size, fill, disabled }) =>
    css`
      svg {
        height: ${size}px;
        width: ${size}px;
        fill: ${disabled ? theme.colors.coolGray4 : fill};
      }
    `}

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
