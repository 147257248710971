const bboxList = [
  { stateCode: 'AL', bbox: '-88.473227,30.223334,-84.88908,35.008028' },
  { stateCode: 'AK', bbox: '-179.148909,51.214183,179.77847,71.365162' },
  { stateCode: 'AS', bbox: '-171.089874,-14.548699,-168.1433,-11.046934' },
  { stateCode: 'AZ', bbox: '-114.81651,31.332177,-109.045223,37.00426' },
  { stateCode: 'AR', bbox: '-94.617919,33.004106,-89.644395,36.4996' },
  { stateCode: 'CA', bbox: '-124.409591,32.534156,-114.131211,42.009518' },
  { stateCode: 'CO', bbox: '-109.060253,36.992426,-102.041524,41.003444' },
  { stateCode: 'MP', bbox: '144.886331,14.110472,146.064818,20.553802' },
  { stateCode: 'CT', bbox: '-73.727775,40.980144,-71.786994,42.050587' },
  { stateCode: 'DE', bbox: '-75.788658,38.451013,-75.048939,39.839007' },
  { stateCode: 'DC', bbox: '-77.119759,38.791645,-76.909395,38.99511' },
  { stateCode: 'FL', bbox: '-87.634938,24.523096,-80.031362,31.000888' },
  { stateCode: 'GA', bbox: '-85.605165,30.357851,-80.839729,35.000659' },
  { stateCode: 'GU', bbox: '144.618068,13.234189,144.956712,13.654383' },
  { stateCode: 'HI', bbox: '-178.334698,18.910361,-154.806773,28.402123' },
  { stateCode: 'ID', bbox: '-117.243027,41.988057,-111.043564,49.001146' },
  { stateCode: 'IL', bbox: '-91.513079,36.970298,-87.494756,42.508481' },
  { stateCode: 'IN', bbox: '-88.09776,37.771742,-84.784579,41.760592' },
  { stateCode: 'IA', bbox: '-96.639704,40.375501,-90.140061,43.501196' },
  { stateCode: 'KS', bbox: '-102.051744,36.993016,-94.588413,40.003162' },
  { stateCode: 'KY', bbox: '-89.571509,36.497129,-81.964971,39.147458' },
  { stateCode: 'LA', bbox: '-94.043147,28.928609,-88.817017,33.019457' },
  { stateCode: 'ME', bbox: '-71.083924,42.977764,-66.949895,47.459686' },
  { stateCode: 'MD', bbox: '-79.487651,37.911717,-75.048939,39.723043' },
  { stateCode: 'MA', bbox: '-73.508142,41.237964,-69.928393,42.886589' },
  { stateCode: 'MI', bbox: '-90.418136,41.696118,-82.413474,48.2388' },
  { stateCode: 'MN', bbox: '-97.239209,43.499356,-89.491739,49.384358' },
  { stateCode: 'MS', bbox: '-91.655009,30.173943,-88.097888,34.996052' },
  { stateCode: 'MO', bbox: '-95.774704,35.995683,-89.098843,40.61364' },
  { stateCode: 'MT', bbox: '-116.050003,44.358221,-104.039138,49.00139' },
  { stateCode: 'NE', bbox: '-104.053514,39.999998,-95.30829,43.001708' },
  { stateCode: 'NV', bbox: '-120.005746,35.001857,-114.039648,42.002207' },
  { stateCode: 'NH', bbox: '-72.557247,42.69699,-70.610621,45.305476' },
  { stateCode: 'NJ', bbox: '-75.559614,38.928519,-73.893979,41.357423' },
  { stateCode: 'NM', bbox: '-109.050173,31.332301,-103.001964,37.000232' },
  { stateCode: 'NY', bbox: '-79.762152,40.496103,-71.856214,45.01585' },
  { stateCode: 'NC', bbox: '-84.321869,33.842316,-75.460621,36.588117' },
  { stateCode: 'ND', bbox: '-104.0489,45.935054,-96.554507,49.000574' },
  { stateCode: 'OH', bbox: '-84.820159,38.403202,-80.518693,41.977523' },
  { stateCode: 'OK', bbox: '-103.002565,33.615833,-94.430662,37.002206' },
  { stateCode: 'OR', bbox: '-124.566244,41.991794,-116.463504,46.292035' },
  { stateCode: 'PA', bbox: '-80.519891,39.7198,-74.689516,42.26986' },
  { stateCode: 'PR', bbox: '-67.945404,17.88328,-65.220703,18.515683' },
  { stateCode: 'RI', bbox: '-71.862772,41.146339,-71.12057,42.018798' },
  { stateCode: 'SC', bbox: '-83.35391,32.0346,-78.54203,35.215402' },
  { stateCode: 'SD', bbox: '-104.057698,42.479635,-96.436589,45.94545' },
  { stateCode: 'TN', bbox: '-90.310298,34.982972,-81.6469,36.678118' },
  { stateCode: 'TX', bbox: '-106.645646,25.837377,-93.508292,36.500704' },
  { stateCode: 'VI', bbox: '-65.085452,17.673976,-64.564907,18.412655' },
  { stateCode: 'UT', bbox: '-114.052962,36.997968,-109.041058,42.001567' },
  { stateCode: 'VT', bbox: '-73.43774,42.726853,-71.464555,45.016659' },
  { stateCode: 'VA', bbox: '-83.675395,36.540738,-75.242266,39.466012' },
  { stateCode: 'WA', bbox: '-124.763068,45.543541,-116.915989,49.002494' },
  { stateCode: 'WV', bbox: '-82.644739,37.201483,-77.719519,40.638801' },
  { stateCode: 'WI', bbox: '-92.888114,42.491983,-86.805415,47.080621' },
  { stateCode: 'WY', bbox: '-111.056888,40.994746,-104.05216,45.005904' },
];

export type BBox = Record<
  StateCode,
  {
    filterByStateCode: StateCode;
    bbox: string;
  }
>;

export const bbox: BBox = bboxList.reduce(
  (prev, { stateCode, bbox: bboxValue }) => ({
    ...prev,
    [stateCode]: {
      filterByStateCode: stateCode as StateCode,
      bbox: bboxValue,
    },
  }),
  {} as BBox
);
