import { Box } from '../Box';

export type ButtonToggleProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> & {
  onClick?: (v: boolean) => void;
  selected?: boolean;
};

export const ButtonToggle = ({
  selected = false,
  onClick = () => {},
  ...props
}: ButtonToggleProps): React.ReactElement => (
  <Box
    as="button"
    type="button"
    onClick={() => {
      onClick(!selected);
    }}
    {...props}
  />
);
