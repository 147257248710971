import { AxiosError } from 'axios';

import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

const USE_TITLE_FLEX_QUERY_KEY = 'TITLE_FLEX';

export type TrustData = {
  owner_names?: string[];
  dr_held_in_trust?: boolean;
};

export type TrustOptions = {};

export const useTitleFlex = (
  fullAddress: string,
  options?: UseQueryOptions<TrustData>
) => {
  return useQuery<TrustData>(
    [USE_TITLE_FLEX_QUERY_KEY, fullAddress],
    async () => {
      const url = '/api/provider-api-service/titleflex/property/details';
      const params = {
        address: fullAddress,
      };
      const res = await http.public.get(url, { params });
      return res?.data;
    },
    {
      ...options,
    }
  );
};
