import {
  DisplayProps,
  flexbox,
  FlexboxProps,
  positions,
  PositionsProps,
  spacing,
  SpacingProps,
} from '@mui/system';
import styled from 'styled-components';
import { slideInFromTop } from '@hl-portals/helpers';

type FloatingContainerProps = {
  isOpen?: boolean;
} & PositionsProps &
  DisplayProps &
  FlexboxProps &
  SpacingProps;

const FloatingContainer = styled.div<FloatingContainerProps>`
  ${flexbox}
  ${positions}
  ${spacing}
  
  overflow: hidden;
  background: #fff;
  z-index: 10;
  border: 1px solid rgb(238, 241, 247);
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  animation: 0.3s ease 0s 1 normal ${slideInFromTop};
  height: auto;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export default FloatingContainer;
