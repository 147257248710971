export const CalculatorIcon = () => (
  <svg
    width="257"
    height="207"
    viewBox="0 0 257 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9569_20981)">
      <path
        d="M245.017 0H85.9833C79.3651 0 74 5.36834 74 11.9905V102.009C74 108.632 79.3651 114 85.9833 114H245.017C251.635 114 257 108.632 257 102.009V11.9905C257 5.36834 251.635 0 245.017 0Z"
        fill="white"
      />
      <path d="M186.77 64H92.7695V77H186.77V64Z" fill="#BBD3EA" />
      <path d="M239.77 83H87.7695V96H239.77V83Z" fill="#E9F6FF" />
      <path d="M169.77 21H92.7695V34H169.77V21Z" fill="#BBD3EA" />
      <path d="M244.77 39H92.7695V52H244.77V39Z" fill="#E9F6FF" />
      <path
        d="M0.769531 198.179C0.769531 203.066 4.68012 207 9.51428 207H110.033C114.859 207 118.778 203.041 118.77 198.154V57.8459C118.77 52.9591 114.834 49 110 49H9.48932C4.65516 49 0.769531 52.9757 0.769531 57.8624V198.179Z"
        fill="#D4E6F0"
      />
      <path
        d="M0.769531 198.179C0.769531 203.066 4.68012 207 9.51428 207H110.033C114.859 207 118.778 203.041 118.77 198.154V57.8459C118.77 52.9591 114.834 49 110 49H9.48932C4.65516 49 0.769531 52.9757 0.769531 57.8624V198.179Z"
        fill="url(#paint0_linear_9569_20981)"
      />
      <path
        d="M9.77784 91.6602C9.77784 94.0647 11.6885 96 14.0476 96L105.508 95.9832C107.867 95.9832 109.778 94.0396 109.77 91.6435V64.3398C109.77 61.9437 107.859 60 105.5 60L14.0393 60.0168C11.6801 60.0168 9.76953 61.9688 9.76953 64.3649V91.6602H9.77784Z"
        fill="url(#paint1_linear_9569_20981)"
      />
      <path
        d="M88.7777 142.786C88.7777 144.006 89.8033 145 91.0667 145H104.489C105.752 145 106.77 144.006 106.77 142.778V130.222C106.77 128.994 105.744 128 104.481 128H91.0503C89.7869 128 88.7695 128.994 88.7695 130.222V142.778L88.7777 142.786Z"
        fill="#BBD3EA"
      />
      <path
        d="M88.7695 168.778C88.7695 170.006 89.7955 171 91.0513 171H104.488C105.752 171 106.77 170.006 106.77 168.778V156.222C106.77 154.994 105.744 154 104.488 154H91.0595C89.7955 154 88.7777 154.994 88.7777 156.222V168.77L88.7695 168.778Z"
        fill="#BBD3EA"
      />
      <path
        d="M88.7777 193.778C88.7777 195.006 89.8033 196 91.0585 196H104.489C105.752 196 106.77 195.006 106.77 193.778V181.222C106.77 179.994 105.744 179 104.481 179H91.0503C89.7869 179 88.7695 179.994 88.7695 181.222V193.778H88.7777Z"
        fill="#BBD3EA"
      />
      <path
        d="M63.7695 142.786C63.7695 144.014 64.7955 145 66.0513 145H79.4795C80.7435 145 81.7695 144.006 81.7695 142.778V130.222C81.7695 128.994 80.7435 128 79.4795 128H66.0513C64.7873 128 63.7695 128.994 63.7695 130.222V142.778V142.786Z"
        fill="#BBD3EA"
      />
      <path
        d="M63.7695 168.777C63.7695 170.005 64.7955 171 66.0513 171H79.4877C80.7517 171 81.7777 170.005 81.7695 168.777V156.223C81.7695 154.995 80.7435 154 79.4795 154H66.0513C64.7873 154 63.7695 154.995 63.7695 156.223V168.785V168.777Z"
        fill="#BBD3EA"
      />
      <path
        d="M63.7695 193.778C63.7695 195.006 64.7951 196 66.0503 196H79.4806C80.744 196 81.7695 195.006 81.7695 193.778V181.222C81.7695 179.994 80.744 179 79.4806 179H66.0585C64.7951 179 63.7777 179.994 63.7777 181.222V193.778H63.7695Z"
        fill="#BBD3EA"
      />
      <path
        d="M37.7782 142.778C37.7782 144.006 38.8607 145 40.1857 145H54.3621C55.6957 145 56.7695 144.006 56.7695 142.778V130.222C56.7695 128.994 55.687 128 54.3534 128H40.177C38.8434 128 37.7695 128.994 37.7695 130.222V142.77L37.7782 142.778Z"
        fill="#BBD3EA"
      />
      <path
        d="M13.7695 143.777C13.7695 145.005 14.7951 146 16.0585 146H29.4888C30.7522 146 31.7695 145.005 31.7695 143.777V131.223C31.7695 129.995 30.744 129 29.4806 129H16.0503C14.7951 129 13.7695 130.003 13.7695 131.232V143.785V143.777Z"
        fill="#BBD3EA"
      />
      <path
        d="M37.7695 168.777C37.7695 170.005 38.852 171 40.177 171H54.3534C55.687 171 56.7695 169.997 56.7695 168.777V156.215C56.7695 154.995 55.687 154 54.3534 154H40.177C38.8434 154 37.7695 154.995 37.7695 156.223V168.785V168.777Z"
        fill="#BBD3EA"
      />
      <path
        d="M37.7695 193.778C37.7695 195.006 38.852 196 40.177 196H54.3534C55.687 196 56.7695 195.006 56.7695 193.778V181.222C56.7695 179.994 55.687 179 54.3534 179H40.177C38.8434 179 37.7695 179.994 37.7695 181.222V193.778Z"
        fill="#BBD3EA"
      />
      <path
        d="M87.7781 119.023C87.7781 120.115 88.698 121 89.8309 121H105.717C106.85 121 107.77 120.106 107.77 119.014V107.977C107.77 106.885 106.85 106 105.708 106H89.8223C88.6895 106 87.7695 106.885 87.7695 107.977V119.023H87.7781Z"
        fill="white"
      />
      <path
        d="M62.7695 119.024C62.7695 120.115 63.6899 121 64.8232 121H80.7158C81.8492 121 82.7695 120.107 82.7695 119.015V107.976C82.7695 106.885 81.8492 106 80.7158 106H64.8232C63.6899 106 62.7695 106.893 62.7695 107.985V119.024Z"
        fill="white"
      />
      <path
        d="M37.7695 119.022C37.7695 120.114 38.6435 121 39.7197 121H54.8113C55.8956 121 56.7695 120.114 56.7695 119.022V107.978C56.7695 106.886 55.8956 106 54.8113 106H39.7197C38.6435 106 37.7695 106.886 37.7695 107.987V119.03V119.022Z"
        fill="white"
      />
      <path
        d="M11.7695 119.023C11.7695 120.115 12.6899 121 13.8232 121H29.7158C30.8492 121 31.7695 120.106 31.7695 119.014V107.977C31.7695 106.885 30.8492 106 29.7073 106H13.8232C12.6899 106 11.7695 106.894 11.7695 107.986V119.023Z"
        fill="white"
      />
      <path
        d="M11.7695 189.598C11.7695 192.577 12.6899 195 13.8232 195H29.7158C30.8492 195 31.7695 192.577 31.7695 189.59V159.41C31.7695 156.423 30.8407 154 29.7073 154H13.8232C12.6899 154 11.7695 156.431 11.7695 159.41V189.59V189.598Z"
        fill="#BBD3EA"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9569_20981"
        x1="21.9866"
        y1="241.986"
        x2="122.66"
        y2="-75.9185"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBD3EA" />
        <stop offset="0.122004" stopColor="#BBD3EA" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9569_20981"
        x1="47.8241"
        y1="136.222"
        x2="74.8313"
        y2="36.2208"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.274618" stopColor="#46B6FF" />
        <stop offset="1" stopColor="#B6E2FF" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_9569_20981">
        <rect width="257" height="207" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
