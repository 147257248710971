import { theme } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';

export type ProgressBarProps = {
  progress: string | number;
  color?: string;
  shadow?: string;
} & BoxTypes;

export const ProgressBar = ({
  progress,
  color,
  shadow,
  ...props
}: ProgressBarProps): React.ReactElement => {
  return (
    <Box
      height="8px"
      width="100%"
      borderRadius={props.borderRadius || '8px'}
      bgcolor={shadow || 'white'}
      {...props}
    >
      <Box
        borderRadius={props.borderRadius || '8px'}
        width={`${progress}%`}
        transition="width .2s ease-out"
        bgcolor={color || theme.colors.electricBlue}
      />
    </Box>
  );
};
