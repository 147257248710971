import styled, { css } from 'styled-components';

import { media, paragraphHelper } from '@hl-portals/helpers';

export const StepperWrapper = styled.ol`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  counter-reset: stepnumber;
`;

const activeBeforeStep = ({ theme }: any) => css`
  background-color: ${theme.colors.electricBlue};
  border: 1px solid ${theme.colors.electricBlue};
  ${paragraphHelper({ type: 'text', theme })}
  color: ${theme.colors.white};
`;

const completedBeforeStep = ({ theme }: any) => css`
  background-color: ${theme.colors.electricBlue};
  border: 1px solid ${theme.colors.electricBlue};

  ${paragraphHelper({ type: 'text', theme })}
  color: ${theme.colors.white};
  content: '';
  background-image: url('/icons/check.svg');
  background-position: center;
  background-repeat: no-repeat;
`;

export const Step = styled.li<{
  active: boolean;
  completed: boolean;
  clickable?: boolean;
}>`
  ${({ theme, active, completed, clickable }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: ${active ? 'auto' : '0'};
    overflow: hidden;
    color: ${active ? theme.colors.black : theme.colors.coolGray1};
    ${paragraphHelper({
      type: active ? 'text-bold' : 'text',
      theme,
    })}
    ${media.desktop`
      display: flex;
      overflow: auto;
      width: auto;
      margin-right: 8px;
      &:not(:last-child)::after {
        content: '';
        width: 60px;
        height: 2px;
        background-color: ${theme.colors.coolGray5};
        margin-left: 8px;
      }
    `}
    ${media.large`
      margin-right: 16px;
      &:not(:last-child)::after {
        width: 100px;
        margin-left: 16px;
      }
    `}
    &::before {
      width: 27px;
      height: 26px;
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      background-color: ${theme.colors.whiteHover};
      border: 1px solid ${theme.colors.coolGray4};
      border-radius: 50%;
      box-sizing: border-box;
      ${paragraphHelper({ type: 'text', theme })}
      color: ${theme.colors.coolGray2};
      counter-increment: stepnumber;
      content: counter(stepnumber);
      ${active ? activeBeforeStep({ theme }) : ''}
      ${completed ? completedBeforeStep({ theme }) : ''}
    }

    ${clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

    > span {
      margin-left: 4px;
      font-weight: 400;
      font-style: italic;
      color: ${theme.colors.coolGray2};
    }
  `}
`;
