import React from 'react';

import { Paragraph, ProgramLayout, SuccessMessage } from '@hl-portals/ui';

import { CompletedTaskMessageType } from './types';

const CompletedTaskMessage = ({
  returnURL,
  title = 'This task has been',
}: CompletedTaskMessageType): React.ReactElement => {
  return (
    <ProgramLayout
      program="homelight"
      closeLink={returnURL}
      mt={0}
      minHeight="800px"
      maxWidth={'none'}
      hideLogo={true}
      showFooter={false}
    >
      <SuccessMessage
        returnUrl={returnURL}
        btnText="Go to the transaction's page"
        title={`${title} completed!`}
        message={
          <>
            <Paragraph variant="text" color="darkBlue" lineHeight="32px">
              {' '}
              Good news! This task is finished.
            </Paragraph>
            {returnURL && (
              <Paragraph variant="text" color="darkBlue">
                For more details check the transaction's page.
              </Paragraph>
            )}
          </>
        }
      />
    </ProgramLayout>
  );
};

export default CompletedTaskMessage;
