import styled from 'styled-components';

import { Box } from '../Box';

export const MessageThreadWrapper = styled(Box)`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray4};

  &:hover {
    cursor: pointer;
  }
`;

export default {};
