import { createContext, useContext, useState } from 'react';

type FeaturesState = {
  features: Record<string, boolean>;
  setFeature: (label: string, value: boolean) => void;
};

const DEFAULT_STATE = {
  features: {},
  setFeature: () => {},
};

const FeaturesContext = createContext<FeaturesState>({ ...DEFAULT_STATE });

export const FeaturesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [features, setFeatures] = useState({});

  const setFeature = (label: string, value: boolean) => {
    setFeatures({
      ...features,
      [label]: value,
    });
  };

  return (
    <FeaturesContext.Provider
      value={{
        features,
        setFeature,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeaturesContext = () => {
  const ctx = useContext(FeaturesContext);
  if (!ctx) throw new Error('Missing FeaturesProvider');

  return ctx;
};
