import { ReactNode, useRef } from 'react';

import { useBrowserEvent } from '@hl-portals/hooks';

import { BrowseButton, HiddenInput } from '../styles';
import { ColorType } from '../types';

export interface FilePickerProps {
  fileChange: (files: FileList | null) => void;
  label?: ReactNode;
  accept?: Array<string>;
  multipleUpload?: boolean;
  uploadButtonSize?: 'medium' | 'large';
  uploadButtonColor?: ColorType;
  uploadButtonText?: string;
  disabled?: boolean;
}

export const FilePicker = ({
  fileChange,
  accept = [],
  multipleUpload,
  uploadButtonColor = 'primary',
  uploadButtonText = 'Browse files',
  disabled,
}: FilePickerProps): React.ReactElement => {
  const input = useRef<HTMLInputElement>();

  useBrowserEvent('OPEN_FILE_PICKER', () => {
    input.current?.click();
  });

  const onKeyDown = (event: any) => {
    if (input.current && ['Enter', ' '].includes(event.key)) {
      input.current.click();
    }
  };

  const sanitizedFormats = accept.map((ext) => ext.replace(/[.,]/, ''));
  const acceptedFormats = accept.length
    ? `.${sanitizedFormats.join(',.')}`
    : '*';

  return (
    <>
      <BrowseButton
        role="button"
        color={disabled ? 'disabled' : uploadButtonColor}
        tabIndex={0}
        {...(!disabled && { onKeyDown: onKeyDown })}
      >
        {uploadButtonText}
      </BrowseButton>
      <HiddenInput
        // @ts-ignore
        ref={input}
        type="file"
        accept={acceptedFormats}
        data-testid="file-input"
        onChange={(e) => {
          fileChange(e.target.files);
          e.target.value = '';
        }}
        multiple={multipleUpload}
        disabled={disabled}
      />
    </>
  );
};
