import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SlideDown } from '../../Animations';
import { Box, BoxTypes } from '../../Box';
import InputGroup, { InputGroupProps } from '../../InputGroup';
import { TextArea, TextAreaProps } from '../../TextArea';
import { ParagraphProps } from '../../Typography';
import { InputError } from '../components/input-error';

type InputWrapperProps = {
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
} & BoxTypes;

const InputWrapper = ({
  children,
  label,
  labelProps,
  optional,
  ...props
}: InputWrapperProps) => {
  if (label) {
    return (
      <InputGroup
        label={label}
        labelProps={labelProps}
        flexDirection="column"
        flex="1"
        {...(optional && { note: 'Optional ' })}
        {...props}
      >
        {children}
      </InputGroup>
    );
  }
  return (
    <Box flexDirection="column" flex="1" {...props}>
      {children}
    </Box>
  );
};

export type TextAreaInputProps = {
  name: string;
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
  autofocus?: boolean;
  containerProps?: BoxTypes & InputGroupProps;
  testId?: string;
  tooltip?: string;
  wrapperProps?: BoxTypes;
} & TextAreaProps;

export const TextAreaInput = ({
  name,
  label,
  labelProps,
  placeholder,
  optional,
  autofocus,
  containerProps,
  testId,
  tooltip,
  wrapperProps,
  ...props
}: TextAreaInputProps) => {
  const { register, formState, setFocus } = useFormContext();
  const error = formState.errors[name];

  useEffect(() => {
    if (autofocus) setFocus(name);
  }, [autofocus, name, setFocus]);

  useEffect(() => {
    if (error) {
      setFocus(name);
    }
  }, [name, error, setFocus]);

  return (
    <InputWrapper
      label={label}
      optional={optional}
      labelProps={labelProps}
      {...containerProps}
    >
      <TextArea
        placeholder={placeholder}
        isError={!!error}
        {...register(name)}
        data-test={testId}
        {...props}
      />
      {!!error && (
        <SlideDown>
          <InputError error={error} />
        </SlideDown>
      )}
    </InputWrapper>
  );
};
