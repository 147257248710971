import React from 'react';

import Image from 'next/image';

import { Box, Button, Paragraph } from '@hl-portals/ui';

import { SuccessMessageProps } from './types';

const SuccessMessage = ({
  icon = '/icons/award.svg',
  title,
  message,
  subMessage,
  btnText = 'Ok',
  returnUrl,
  ...otherProps
}: SuccessMessageProps): React.ReactElement => (
  <Box
    flexDirection="column"
    width={1}
    height="calc(100vh - 42px)"
    justifyContent="center"
    alignItems="center"
    {...otherProps}
    data-test="successMessage"
  >
    {typeof icon === 'string' ? (
      <Image src={icon} width={60} height={60} />
    ) : (
      icon
    )}
    <Paragraph
      m="16px 0"
      variant="heading-3"
      color="darkBlue"
      data-test="successMessage_title"
      textAlign="center"
    >
      {title}
    </Paragraph>

    {message && typeof message === 'string' ? (
      <Paragraph
        variant="text"
        color="coolGray1"
        textAlign="center"
        data-test="successMessage_message"
      >
        {message}
      </Paragraph>
    ) : (
      message
    )}

    {subMessage && (
      <Paragraph
        variant="text"
        color="darkBlue"
        textAlign="center"
        data-test="successMessage_subMessage"
      >
        {subMessage}
      </Paragraph>
    )}

    {returnUrl && (
      <Button
        color="primary"
        size="large"
        href={returnUrl}
        data-test="successMessage_returnBtn"
        mt="32px"
      >
        {btnText}
      </Button>
    )}
  </Box>
);

export default SuccessMessage;
