import config from './config';

declare global {
  interface Window {
    gtag: any;
  }
}

function trackPageView(page_path) {
  if (!config.gtmId) {
    return;
  }

  window.gtag('config', config.gtmId, {
    page_path,
  });
}

function trackAction(action, event_category, event_label, value = 0) {
  if (!config.gtmId) {
    return;
  }

  window.gtag('event', action, {
    event_category,
    event_label,
    value,
  });
}

export const GTM = {
  trackPageView,
  trackAction,
};
