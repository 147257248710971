// @ts-strict
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { SEND_TEAM_APP_LINKS_OVER_TEXT_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useScreenSize } from '../useScreenSize';

const sendTeamAppLinksViaText = async (recipients: Array<string>) => {
  return enhancedAxios({
    method: 'POST',
    url: SEND_TEAM_APP_LINKS_OVER_TEXT_PATH(),
    translateJsona: true,
    data: { team_member_ids: recipients },
  });
};

export const useSendTeamAppLinks = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();

  return useMutation({
    mutationFn: sendTeamAppLinksViaText,
    onSuccess: () => {
      queryClient.invalidateQueries('current');
      toast.success('Link has been sent to your team', {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 80 },
      });
    },
    onError: () => {
      toast.error('Sorry, something went wrong. Please try again.', {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 80 },
      });
    },
  });
};
