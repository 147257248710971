import { useEffect, useState } from 'react';

import { useSession } from 'next-auth/react';

type UseUser = {
  id: string;
  name: string;
  email: string;
} | null;

const useUser = (): UseUser => {
  const [user, setUser] = useState(null);
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user && session?.['userId']) {
      setUser({
        id: session['userId'],
        ...session.user,
      });
    }
  }, [session]);

  return user;
};

export default useUser;
