import styled from 'styled-components';

import { colors } from '@hl-portals/constants';

import { Box } from '../Box';
import { Button } from '../Button';
import { Paragraph } from '../Typography';

const Item = styled(Box)`
  padding: 8px 16px;
  position: relative;
  background-color: ${colors.coolGray6};
  border-left: 4px solid #d9d9d9;
  border-radius: 8px 0px 0px 8px;
  flex-direction: column;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 50%;
  }
`;

const Title = styled(Paragraph)`
  font-size: 20px;

  @media (min-width: 500px) {
    font-size: 24px;
  }
`;

const SubHeader = styled(Paragraph)`
  font-size: 20px;
  font-weight: 700;

  @media (min-width: 500px) {
    font-size: 16px;
  }
`;

type Props = {
  items: string[][];
  propertyAddress: string;
  onContinue: () => void;
  onCancel?: () => void;
};

export const ImprovedPropertyEligibilityModal = ({
  items,
  propertyAddress,
  onContinue,
  onCancel,
}: Props) => {
  return (
    <Box
      id="improved-property-eligibility-modal"
      maxWidth={{ xs: '100%', md: '628px' }}
      maxHeight={{ xs: 'none', md: '90vh' }}
      height="fit-content"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="16px"
      width="100%"
      overflowY={{ xs: 'auto', md: 'scroll' }}
      mt={{ xs: '40px', md: '0' }}
    >
      <Box
        flexDirection="column"
        gap="20px"
        mb="32px"
        alignItems="center"
        justifyContent="center"
      >
        <Box gap="8px" justifyContent="center">
          <Title variant="heading-3" color="darkBlue" as="header" my="8px">
            Equity Unlock loan may be limited
          </Title>
        </Box>

        <Box justifyContent="center">
          <Paragraph>
            The equity unlock loan for the departing residence {propertyAddress}{' '}
            might be limited or unavailable for the following reasons
          </Paragraph>
        </Box>
      </Box>

      <Box flexDirection="column" gap="16px" mb="32px">
        {items.map((item) => (
          <Item key={item[0]}>
            <SubHeader my="8px">{item[0]}</SubHeader>
            <Paragraph>{item[1]}</Paragraph>
          </Item>
        ))}
      </Box>

      <Box flexDirection="column" gap="20px" mb="32px">
        <Paragraph>
          Final program eligibility and equity unlock amount will be made by our
          approval team once we review the application.
        </Paragraph>
      </Box>

      <Box width="100%" gap="16px" justifyContent="flex-end">
        <Button size="large" onClick={onCancel} variant="text">
          Cancel submission
        </Button>
        <Button
          size="large"
          color="primary"
          data-test="eligibility-continue-btn"
          onClick={onContinue}
        >
          Acknowledge
        </Button>
      </Box>
    </Box>
  );
};
