/* eslint-disable @typescript-eslint/no-unused-expressions */
import styled from 'styled-components';

import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, BoxTypes } from '../../Box';
import { Paragraph } from '../../Typography';

type Props = ComponentProps<'input'> &
  BoxTypes & {
    name: string;
    testId?: string;
    minMaxFormatter?: (value: string | number) => string;
  };

const RangeInputStyles = styled.input`
  width: 100%;
  height: 0.5rem;
  -webkit-appearance: none;
  appearance: none;
  background: #c0dbfb;
  border-radius: 200px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    appearance: none;
    border: 4px solid white;
    border-radius: 50%;
    background-color: #46b6ff;
  }
`;

export const RangeInput = ({
  name,
  onChange,
  testId = 'input-range',
  min,
  max,
  step,
  minMaxFormatter,
  ...props
}: Props) => {
  const { register } = useFormContext();

  return (
    <Box alignItems="center" gap="8px" {...props}>
      {(min || min === 0) && (
        <Paragraph color="#72757D" whiteSpace="nowrap">
          {minMaxFormatter ? minMaxFormatter(min) : min}
        </Paragraph>
      )}
      <RangeInputStyles
        type="range"
        data-test={testId}
        min={min}
        max={max}
        step={step}
        {...register(name)}
      />
      {max && (
        <Paragraph color="#72757D" whiteSpace="nowrap">
          {minMaxFormatter ? minMaxFormatter(max) : max}
        </Paragraph>
      )}
    </Box>
  );
};
