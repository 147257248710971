import {
  FieldValues
} from 'react-hook-form';

type Args = {
  propertyAddress: string;
  propertyUnit?: string;
  propertyCity: string;
  propertyState: string;
  propertyPostalCode: string;
};

type Form = {
  property_address: string;
  property_unit?: string,
  property_city: string,
  property_state: string,
  property_postal_code: string
}

export const getFullAddressFromForm = (form: FieldValues | Form) =>
  getFullAddress({
    propertyAddress: form.property_address || '',
    propertyCity: form.property_city,
    propertyState: form.property_state,
    propertyPostalCode: form.property_postal_code,
    propertyUnit: form.property_unit
  })

export function getFullAddress({
  propertyAddress,
  propertyUnit = '',
  propertyCity,
  propertyState,
  propertyPostalCode,
}: Args) {
  return [
    `${propertyAddress}`,
    propertyUnit ? `#${propertyUnit}` : null,
    `${propertyCity}`,
    `${propertyState} ${propertyPostalCode}`,
  ]
    .filter(Boolean)
    .join(', ');
}
