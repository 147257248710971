import { display, DisplayProps } from '@mui/system';
import styled, { css } from 'styled-components';
import { Colors } from '@hl-portals/constants';

type ArrowProps = {
  size: number;
  color: Colors;
  length: number;
  direction: 'down' | 'up' | 'right' | 'left';
  cursor?: 'pointer' | 'default' | 'not-allowed';
} & DisplayProps;

const Arrow = styled.i<ArrowProps>`
  ${({ theme, size, direction, length, color, cursor }) => css`
    display: block;
    ${display}
    width: ${size}px;
    height: ${size}px;
    position: relative;
    ${cursor ? `cursor: ${cursor};` : ''}
    &::before,
    &::after {
      content: '';
      display: block;
      width: ${size / 2}px;
      height: ${length}px;
      ${(direction === 'left' || direction === 'right') &&
      css`
        height: ${size / 2}px;
        width: ${length}px;
      `}
      background-color: ${theme.colors[color]};
      position: absolute;
      top: 50%;
      transition: transform 300ms ease;
    }
    &::before {
      left: 50%;
      transform: translateX(-85%) translateY(-50%) rotate(45deg); /* down */
      ${direction === 'left' &&
      `transform: translateX(-50%) translateY(-85%) rotate(45deg);`}
      ${direction === 'up' &&
      `transform: translateX(-85%) translateY(-50%) rotate(-45deg);`}
      ${direction === 'right' &&
      `transform: translateX(-50%) translateY(-85%) rotate(-45deg);`}
    }
    &::after {
      right: 50%;
      transform: translateX(85%) translateY(-50%) rotate(-45deg); /* down */
      ${direction === 'left' &&
      `transform: translateX(50%) translateY(-15%) rotate(-45deg);`}
      ${direction === 'up' &&
      `transform: translateX(85%) translateY(-50%) rotate(45deg);`}
      ${direction === 'right' &&
      `transform: translateX(50%) translateY(-15%) rotate(45deg);`}
    }
  `}
`;

export default Arrow;
