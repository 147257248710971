import styled from 'styled-components';

import { colors } from '@hl-portals/constants';

import { useModal } from '@hl-portals/hooks';

import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import SimpleLink from '../SimpleLink';
import { Paragraph } from '../Typography';

const Item = styled(Paragraph)`
  padding: 8px 16px;
  position: relative;
  background-color: ${colors.coolGray6};
  border-left: 4px solid #d9d9d9;
  border-radius: 8px 0px 0px 8px;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 50%;
  }
`;

const Title = styled(Paragraph)`
  font-size: 20px;

  @media (min-width: 500px) {
    font-size: 24px;
  }
`;

type Props = {
  items: string[];
  onContinue: () => void;
  onCancel?: () => void;
};

export const PropertyEligibilityModal = ({
  items,
  onContinue,
  onCancel,
}: Props) => {
  const { closeModal } = useModal();

  const handleCancel = () => {
    closeModal();
    onCancel && onCancel();
  };

  return (
    <Box
      id="property-eligibility-modal"
      maxWidth={{ xs: '100%', md: '628px' }}
      maxHeight={{ xs: 'none', md: '90vh' }}
      height="fit-content"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="16px"
      width="100%"
      overflowY={{ xs: 'auto', md: 'scroll' }}
      mt={{ xs: '40px', md: '0' }}
    >
      <Box
        flexDirection="column"
        gap="20px"
        mb="32px"
        alignItems="center"
        justifyContent="center"
      >
        <Box gap="8px" alignItems="center" justifyContent="center">
          <Icon size={120} type="referralsBlocked" />
        </Box>

        <Box gap="8px" justifyContent="center">
          <Title variant="heading-3" color="darkBlue" as="header" my="8px">
            Property may not be eligible because:
          </Title>
        </Box>
      </Box>

      <Box flexDirection="column" gap="16px" mb="32px">
        {items.map((item) => (
          <Item key={item}>{item}</Item>
        ))}
      </Box>

      <Box flexDirection="column" gap="20px" mb="32px">
        <Paragraph>
          If this information is not accurate, continue with the submission. If
          not, cancel.
        </Paragraph>
        <Paragraph>
          For details, refer to the{' '}
          <SimpleLink
            href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements"
            target="_blank"
            fontSize="16px"
          >
            Eligibility Requirements here.
          </SimpleLink>
        </Paragraph>
      </Box>

      <Box width="100%" gap="16px" justifyContent="flex-end">
        <Button size="large" onClick={handleCancel} color="urgent">
          Cancel submission
        </Button>
        <Button
          size="large"
          variant="text"
          data-test="eligibility-continue-btn"
          onClick={() => {
            closeModal();
            onContinue();
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};
