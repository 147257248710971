import dayjs from 'dayjs';

import { useEffect } from 'react';
import { FieldValues, UseFormSetError, useFormContext } from 'react-hook-form';

import { INVALID_DATE } from '@hl-portals/constants';

import { formatDate } from '@hl-portals/helpers';

import { BoxTypes } from '../../Box';
import DatePicker from '../../DatePicker';
import InputGroup, { InputGroupProps } from '../../InputGroup';

export type DateInputProps = {
  name: string;
  label: string;
  testId?: string;
  containerProps?: BoxTypes & InputGroupProps;
  disableWeekends?: boolean;
  disabledDates?: dayjs.Dayjs[];
  enabledDates?: dayjs.Dayjs[];
  minDate?: string;
  maxDate?: string;
  setError?: UseFormSetError<FieldValues>;
};

export const DateInput = ({
  name,
  label,
  testId,
  containerProps,
  disableWeekends,
  disabledDates,
  enabledDates,
  minDate,
  maxDate,
  setError,
  ...props
}: DateInputProps) => {
  const { formState, setValue, clearErrors, watch } = useFormContext();
  const error = formState.errors[name];
  const value = watch(name);

  useEffect(() => {
    clearErrors(name);
  }, [value, name, clearErrors]);

  return (
    <InputGroup
      label={label}
      error={error?.message?.toString()}
      {...containerProps}
    >
      <DatePicker
        {...props}
        onDateSelect={(date) => {
          setValue(
            name,
            formatDate({
              dateString: date,
              dateFormat: 'MM/DD/YYYY',
            })
          );
        }}
        value={value}
        error={value === INVALID_DATE}
        setError={() =>
          setError &&
          setError(name, {
            type: 'onBlur',
            message: 'Please choose an Escrow date in the future',
          })
        }
        data-test={testId}
        disableWeekends={disableWeekends}
        disabledDates={disabledDates}
        enabledDates={enabledDates}
        minDate={minDate}
        maxDate={maxDate}
      />
    </InputGroup>
  );
};
