import { useEffect } from 'react';

type EventName = 'OPEN_FILE_PICKER';
type Listener<Payload> = (e: CustomEvent<Payload>) => void;

export function useBrowserEvent<Payload = unknown>(
  eventName: EventName,
  fn?: Listener<Payload>
) {
  function publish(data?: Payload) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }

  useEffect(() => {
    function subscribe(listener: Listener<Payload>) {
      document.addEventListener(eventName, listener as EventListener);
    }

    function unsubscribe(listener: Listener<Payload>) {
      document.removeEventListener(eventName, listener as EventListener);
    }

    if (fn) {
      subscribe(fn);
      return () => unsubscribe(fn);
    }
    return undefined;
  }, [fn, eventName]);

  return { publish };
}
