import { Box } from '../Box';
import SimpleLink from '../SimpleLink';
import { Paragraph } from '../Typography';

const NotFoundSVG = () => (
  <svg
    width="281"
    height="181"
    viewBox="0 0 281 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M281 181H0L54.2135 57.8052H226.58C226.58 57.8052 238.581 85.0679 281 181Z"
      fill="#DBDFE6"
    />
    <path
      d="M26.4858 120.735H220.372V109.871H31.452L26.4858 120.735Z"
      fill="white"
    />
    <path
      d="M49.0404 158.452V147.588H14.6913L9.93213 158.452H49.0404Z"
      fill="white"
    />
    <path
      d="M207.957 79.1234L195.334 78.9185L195.127 91.2174H199.68C204.439 102.901 215.406 129.344 236.719 181H250.169C217.475 102.287 207.957 79.1234 207.957 79.1234Z"
      fill="white"
    />
    <path
      d="M112.359 181C124.153 127.909 130.361 100.442 133.051 88.3475L137.603 87.5276L135.12 75.4336L122.705 77.8934C122.705 77.8934 117.325 101.466 99.7363 181H112.359Z"
      fill="white"
    />
    <path
      d="M216.506 73.5253L190.533 75.7808L191.96 91.9129L217.933 89.6574L216.506 73.5253Z"
      fill="#DBDFE6"
    />
    <path
      d="M54.0066 61.6997C46.5574 61.6997 40.5566 67.6442 40.5566 75.0236C40.5566 85.8877 54.0066 99.8266 54.0066 99.8266C54.0066 99.8266 67.4565 85.8877 67.4565 75.0236C67.4565 67.6442 61.4558 61.6997 54.0066 61.6997Z"
      fill="#C5C8CD"
    />
    <path
      d="M200.922 113.971C191.817 113.971 184.575 121.145 184.575 130.164C184.575 143.488 200.922 160.092 200.922 160.092C200.922 160.092 217.268 143.283 217.268 130.164C217.475 121.35 210.026 113.971 200.922 113.971Z"
      fill="#C5C8CD"
    />
    <path
      d="M62.4907 116.84H75.9407C86.2868 92.0373 94.1498 72.7689 100.357 57.8052H86.9075C80.4929 72.9739 72.423 92.4473 62.4907 116.84Z"
      fill="white"
    />
    <path
      d="M141.121 0C120.636 0 103.875 16.6036 103.875 36.8969C103.875 67.0294 141.121 105.156 141.121 105.156C141.121 105.156 178.367 67.0294 178.367 36.8969C178.367 16.6036 161.813 0 141.121 0Z"
      fill="#8E929C"
    />
    <path
      d="M124.981 27.4678H128.705V38.9468H131.188V41.6116H128.705V45.7113H125.602V41.6116H116.497V39.3568L124.981 27.4678ZM125.602 30.9525L119.808 38.9468H125.395V30.9525H125.602Z"
      fill="white"
    />
    <path
      d="M147.949 40.1766C147.535 41.4065 147.121 42.2265 146.501 43.2514C145.88 44.0713 145.052 44.8912 144.018 45.3012C142.983 45.9162 141.948 46.1211 140.707 46.1211C139.465 46.1211 138.224 45.9162 137.396 45.3012C136.362 44.6863 135.741 44.0713 134.913 43.2514C134.292 42.4314 133.878 41.4065 133.465 40.1766C133.051 38.9467 133.051 37.9218 133.051 36.6919C133.051 35.462 133.258 34.2321 133.465 33.2072C133.878 31.9773 134.292 31.1574 134.913 30.1325C135.534 29.3125 136.362 28.4926 137.396 28.0826C138.431 27.4677 139.465 27.2627 140.707 27.2627C141.948 27.2627 143.19 27.4677 144.018 28.0826C145.052 28.6976 145.673 29.3125 146.501 30.1325C147.121 30.9524 147.535 31.9773 147.949 33.2072C148.363 34.4371 148.363 35.462 148.363 36.6919C148.363 37.7168 148.156 38.9467 147.949 40.1766ZM145.052 34.2321C144.845 33.4122 144.638 32.7972 144.225 32.1823C143.811 31.5673 143.397 31.1574 142.776 30.7474C142.155 30.3374 141.535 30.1325 140.707 30.1325C139.879 30.1325 139.258 30.3374 138.638 30.7474C138.017 31.1574 137.603 31.5673 137.189 32.1823C136.775 32.7972 136.568 33.4122 136.362 34.2321C136.155 35.0521 136.155 35.872 136.155 36.6919C136.155 37.5118 136.155 38.3318 136.362 39.1517C136.568 39.9716 136.775 40.5866 137.189 41.2015C137.603 41.8165 138.017 42.2265 138.638 42.6364C139.258 43.0464 139.879 43.2514 140.707 43.2514C141.535 43.2514 142.155 43.0464 142.776 42.6364C143.397 42.2265 143.811 41.8165 144.225 41.2015C144.638 40.5866 144.845 39.9716 145.052 39.1517C145.259 38.3318 145.259 37.5118 145.259 36.6919C145.259 35.667 145.052 34.8471 145.052 34.2321Z"
      fill="white"
    />
    <path
      d="M158.916 27.4678H162.641V38.9468H165.124V41.6116H162.641V45.7113H159.537V41.6116H150.432V39.3568L158.916 27.4678ZM159.537 30.9525L153.95 38.9468H159.537V30.9525Z"
      fill="white"
    />
  </svg>
);

export const NotFoundPage = () => {
  return (
    <Box flexDirection="column" alignItems="center" p="80px 16px 16px 16px">
      <NotFoundSVG />
      <Paragraph variant="heading-3" textAlign="center" mt="48px" mb="16px">
        Uh-oh, page not found
      </Paragraph>
      <Paragraph textAlign="center" lineHeight="28px">
        This page doesn't exist or was removed.
        <br /> If you need help please reach out to us at:{' '}
        <SimpleLink href="mailto:support@homelight.com">
          support@homelight.com
        </SimpleLink>
        .
      </Paragraph>
    </Box>
  );
};
