import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import Cookies from 'cookies';

import { getSession } from 'next-auth/react';
import getConfig from 'next/config';
import router from 'next/router';

import { FULL_LOGOUT_PATH, hapiLogoutPath } from '@hl-portals/constants';

function logError(err) {
  console.error('Failed to logout!', err);
  Sentry.captureException(err);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default async (req, res) => {
  const { user } = req?.body;
  const session = await getSession({ req });

  try {
    if (user?.impersonated) {
      await axios({
        url: hapiLogoutPath,
        method: 'DELETE',
        withCredentials: false,
      })
        .then(() => router.push('/dashboard'))
        .catch(logError);
    } else {
      await axios
        .post(FULL_LOGOUT_PATH, undefined, {
          headers: {
            Authorization: `Bearer ${(session as any)?.accessToken}`,
          },
        })
        .then(() => {
          const { serverRuntimeConfig } = getConfig();
          const cookies = new Cookies(req, res);

          const homelightCookieKey = `_hl_session_${serverRuntimeConfig.environmentName}`;
          const hapiCookieKey = `hapi_user_${serverRuntimeConfig.environmentName}`;

          cookies.set(hapiCookieKey, undefined, {
            domain: 'homelight.com',
          });
          cookies.set(homelightCookieKey, undefined, {
            domain: 'homelight.com',
          });
        })
        .catch(logError);
    }
  } catch (err) {
    logError(err);
  }

  res.end();
};
