/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ChangeEventHandler, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatCurrency } from '@hl-portals/helpers';

import { BoxTypes } from '../../Box';
import { Input, InputProps } from '../../Input';
import InputGroup from '../../InputGroup';
import { ParagraphProps } from '../../Typography';
import { InputError } from '../components/input-error';

type Props = {
  name: string;
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
  tooltip?: string;
  testId?: string;
  showSymbol?: boolean;
  isFocused?: boolean;
  note?: string;
  containerProps?: BoxTypes;
} & InputProps;

export const CurrencyInput = ({
  name,
  label,
  labelProps,
  optional,
  tooltip,
  onChange,
  showSymbol = true,
  testId = 'input-currency',
  isFocused,
  note,
  containerProps,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { formState, setValue, clearErrors, watch } = useFormContext();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const formatted = formatCurrency({
      value: e.target.value,
      raw: true,
    });

    setValue(name, formatted);
    clearErrors(name);
    onChange && onChange(e);
  };

  const error = formState.errors[name];
  const value = watch(name);

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  return (
    <InputGroup
      label={label}
      note={note}
      labelProps={labelProps}
      {...(optional && { note: 'Optional ' })}
      tooltip={tooltip}
      flexDirection="column"
      noteProps={{ fontStyle: 'normal' }}
      width="100%"
      {...containerProps}
    >
      <Input
        variant={showSymbol ? 'currency' : 'normal'}
        maxLength={12}
        type="tel"
        ref={inputRef}
        value={value}
        {...(value && { onClear: () => setValue(name, '') })}
        onChange={handleChange}
        isError={!!formState.errors[name]}
        data-test={testId}
        {...props}
      />
      {!!error && <InputError error={error} />}
    </InputGroup>
  );
};
