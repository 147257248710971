/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useController, useFormContext } from 'react-hook-form';

import { Input, InputProps } from '../../Input';
import InputGroup from '../../InputGroup';
import { InputError } from '../components/input-error';

type Props = {
  name: string;
  label?: string;
  optional?: boolean;
  tooltip?: string;
  testId?: string;
  showSymbol?: boolean;
} & InputProps;

export const PercentInput = ({
  name,
  label,
  optional,
  tooltip,
  onChange,
  showSymbol = true,
  testId = 'input-percent',
  ...props
}: Props) => {
  const { formState, control, setValue, watch } = useFormContext();
  const { field } = useController({ control, name });
  const error = formState.errors[name];
  const value = watch(name);

  return (
    <InputGroup
      label={label}
      {...(optional && { note: 'Optional ' })}
      tooltip={tooltip}
      flexDirection="column"
      noteProps={{ fontStyle: 'normal' }}
    >
      <Input
        variant={showSymbol ? 'percent' : 'normal'}
        {...(value && { onClear: () => setValue(name, '') })}
        numberFormatProps={{
          suffix: '%',
          value: field.value,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e.target.value);
            onChange && onChange(e);
          },
        }}
        isError={!!formState.errors[name]}
        data-test={testId}
        {...props}
      />
      {!!error && <InputError error={error} />}
    </InputGroup>
  );
};
