import { AgentEvent } from 'packages/hooks/src/useUserEvents/agent-event-types';
import { ClientEvent } from 'packages/hooks/src/useUserEvents/client-event-types';
import styled from 'styled-components';

import { useState } from 'react';

import {
  CLIENT_TRANSACTION_FILES_DOWNLOAD_PATH,
  CLIENT_TRANSACTION_FILES_VIEW_PATH,
} from '@hl-portals/constants';
import { theme } from '@hl-portals/constants';

import { ellipsis, enhancedAxios, media } from '@hl-portals/helpers';

import { useUserEvents } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import ResponsiveDisplay from '../ResponsiveDisplay';
import SimpleLink from '../SimpleLink';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';
import CondensedDocumentCard from './CondensedDocumentCard';
import { getTimeString, showErrorToast } from './helpers';
import { RowWrapper } from './styles';

type DocumentCardProps = {
  leadIds: Array<string>;
  primaryLeadId: string;
  document: TransactionDocument;
  condensed?: boolean;
  userEventSource: 'agent_dashboard' | 'client_portal';
} & BoxTypes;

type DocumentAction = 'viewed' | 'downloaded';

const hoverStyles = `
  transition: all 0.2s ease-out;

  &:hover {
    background: ${theme.colors.coolGray6};
  }
`;
const StyledResponsiveDisplay = styled(ResponsiveDisplay)`
  ${media.desktop`
    ${hoverStyles}
  `}
`;
const StyledCondensedDocumentCard = styled(CondensedDocumentCard)`
  ${hoverStyles}
`;

const DocumentCard = ({
  leadIds,
  primaryLeadId,
  document,
  condensed = false,
  userEventSource,
  ...extraProps
}: DocumentCardProps): React.ReactElement => {
  const [isFetching, setFetching] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const { recordUserEvent } = useUserEvents();

  const { id: docId, name: docName, updated_at: updatedAt } = document;

  const sendUserEvent = (action: DocumentAction) => {
    recordUserEvent({
      source: userEventSource,
      event_action: `transaction_document_${action}`,
      result: {
        document_id: docId,
        lead_id: primaryLeadId,
      },
    } as AgentEvent | ClientEvent);
  };

  const onDocumentView = () => {
    setFetching(true);
    enhancedAxios({
      bypassAuth: true,
      url: CLIENT_TRANSACTION_FILES_VIEW_PATH({ docId, leadIds }),
    })
      .then(({ data }) => {
        const previewUrl = data?.attributes?.preview_url;

        sendUserEvent('viewed');
        setFetching(false);

        if (previewUrl) {
          window.open(previewUrl);
        } else {
          showErrorToast(docName);
        }
      })
      .catch(() => {
        setFetching(false);
        showErrorToast(docName);
      });
  };

  const downloadDocument = () =>
    enhancedAxios({
      bypassAuth: true,
      url: CLIENT_TRANSACTION_FILES_DOWNLOAD_PATH({ leadIds, docId }),
    })
      .then(({ data }) => {
        const downloadUrl = data?.attributes?.download_url;

        sendUserEvent('downloaded');
        setDownloading(false);

        if (downloadUrl) {
          window.open(downloadUrl);
        }
      })
      .catch(() => {
        setDownloading(false);
        showErrorToast(docName);
      });

  const onDocumentDownload = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setDownloading(true);
    downloadDocument().catch(() => {
      setDownloading(false);
    });
  };

  if (condensed)
    return (
      <StyledCondensedDocumentCard
        document={document}
        onDocumentView={onDocumentView}
        onDocumentDownload={onDocumentDownload}
        loading={isDownloading || isFetching}
        {...extraProps}
      />
    );

  return (
    <StyledResponsiveDisplay
      width="100%"
      mobile={
        <StyledCondensedDocumentCard
          document={document}
          onDocumentView={onDocumentView}
          onDocumentDownload={onDocumentDownload}
          loading={isDownloading || isFetching}
          {...extraProps}
        />
      }
      desktop={
        <RowWrapper
          {...extraProps}
          data-test="transaction-document-card"
          alignItems="center"
          onClick={onDocumentView}
          padding="24px"
          borderBottom={`1px solid ${theme.colors.coolGray5}`}
          width="100%"
          justifyContent="space-between"
        >
          <Box flex="1">
            {isDownloading || isFetching ? (
              <Spinner xl mr="32px" ml="16px" />
            ) : (
              <Icon
                type="file"
                color="electricBlue"
                size={32}
                marginRight="16px"
              />
            )}
            <Paragraph
              variant="text-bold"
              color="darkBlue"
              alignItems="center"
              display="flex"
              minWidth="200px"
            >
              {ellipsis(docName, 30)}
            </Paragraph>
          </Box>
          <Paragraph
            variant="text-small"
            color="coolGray2"
            alignItems="center"
            justifyContent="flex-start"
            display="flex"
            flex=".5"
          >
            {getTimeString(updatedAt)}
          </Paragraph>
          <SimpleLink
            onClick={onDocumentDownload}
            color={theme.colors.darkBlue}
            fontWeight="bold"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex=".5"
          >
            <Icon type="simpleDownload" fill="darkBlue" />
            Download
          </SimpleLink>
        </RowWrapper>
      }
    />
  );
};
export default DocumentCard;
