import styled from 'styled-components';

import { ArrowIcon } from '../Icon/icons';

export const DropdownComponentWrapper = styled.label`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.coolGray4};
  padding: 1px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  &:focus-within {
    padding: 0px;
    border: 2px solid ${(props) => props.theme.colors.electricBlue};
  }
`;

export const DropDownComponent = styled.select`
  width: calc(100% + 1rem);
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.coolGray2};
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const ArrowDownIcon = styled(ArrowIcon)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;
