type DrawerProps = {
  open: boolean;
  onClose: () => void;
  position?: 'left' | 'right' | 'top' | 'bottom';
  width?: string; // Only for 'left' and 'right' positions
  height?: string; // Only for 'top' and 'bottom' positions
  children: React.ReactNode;
};

export const Drawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  position = 'left',
  width = '250px',
  height = '250px',
  children,
}) => {
  const getDrawerStyle = (): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      position: 'fixed',
      backgroundColor: '#fff',
      zIndex: 1000,
      transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    };

    switch (position) {
      case 'left':
        return {
          ...baseStyle,
          top: 0,
          left: 0,
          width,
          height: '100%',
          transform: open ? 'translateX(0)' : 'translateX(-100%)',
        };
      case 'right':
        return {
          ...baseStyle,
          top: 0,
          right: 0,
          width,
          height: '100%',
          transform: open ? 'translateX(0)' : 'translateX(100%)',
        };
      case 'top':
        return {
          ...baseStyle,
          top: 0,
          left: 0,
          width: '100%',
          height,
          transform: open ? 'translateY(0)' : 'translateY(-100%)',
        };
      case 'bottom':
        return {
          ...baseStyle,
          bottom: 0,
          left: 0,
          width: '100%',
          height,
          transform: open ? 'translateY(0)' : 'translateY(100%)',
        };
      default:
        return baseStyle;
    }
  };

  return (
    <>
      {/* Backdrop */}
      {open && (
        <div
          onClick={onClose}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 999,
          }}
        />
      )}

      {/* Drawer */}
      <div style={getDrawerStyle()}>{children}</div>
    </>
  );
};
