import { fontSizes, fontWeights } from '@homelight/particle-tokens';
import styled, { css } from 'styled-components';

import { TChipProps } from './types';

export default function Chip({
  label,
  value,
  onSelect,
  isSelected = false,
}: TChipProps) {
  return (
    <Container onClick={() => onSelect(value)} isSelected={isSelected}>
      <Label>{label}</Label>
    </Container>
  );
}

const Container = styled.div<{ isSelected: boolean }>`
  background-color: #e8eef9;
  padding: 3px 6px;
  border-radius: 8px;
  color: #55585e;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #e9f6ff;

      & ${Label} {
        color: #0c6eba;
      }
    `}
`;

const Label = styled.p`
  margin: 0;
  color: #55585e;
  font-size: ${fontSizes.fontSizeSm}px;
  font-weight: ${fontWeights.fontWeightBold};
`;
