import styled, { css } from 'styled-components';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Panel } from '../Panel';

export const CardWrapper = styled(Panel)`
  display: flex;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin-bottom: 24px;
  }
`;

export const RowWrapper = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const FileIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.electricBlue};
  `}
`;
