import { SpacingProps } from '@mui/system';

import { ICONS } from '@hl-portals/ui';

import { Icon } from '../../Icon';
import { FileProgressIconWrapper } from '../styles';

export interface IconProps {
  type?: keyof typeof ICONS;
}

type FileProgressIconProps = IconProps & SpacingProps;

const FileProgressIcon = ({
  type = 'download',
  ...props
}: FileProgressIconProps): React.ReactElement => (
  <FileProgressIconWrapper alignItems="center" justifyContent="center">
    <Icon size={32} type={type} {...props} />
  </FileProgressIconWrapper>
);

export default FileProgressIcon;
