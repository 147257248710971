import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { useModal, useScreenSize } from '@hl-portals/hooks';

type CalculateEquityModalArgs = {
  onConfirm?: () => void;
};

export const CalculateEquityModal = ({
  onConfirm,
}: CalculateEquityModalArgs) => {
  const { closeModal } = useModal();
  const { isMobile } = useScreenSize();

  return (
    <Box
      maxWidth={{ xs: '100%', md: '628px' }}
      maxHeight="90vh"
      height="fit-content"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      p="16px"
      width="100%"
    >
      <Box flexDirection="column" gap="20px" mb="32px">
        <Box gap="8px" alignItems="center">
          <Icon type="exclamationCircle" size={32} />
          <Paragraph
            variant={isMobile ? 'heading-4' : 'heading-3'}
            color="darkBlue"
            my="8px"
          >
            Property may not be eligible
          </Paragraph>
        </Box>

        <Paragraph mb="4px">
          We're sorry, based on our info, there may not be enough available
          equity in this property to be a good fit for the program.
        </Paragraph>
        <Paragraph>
          Typically clients need at least 25% equity in their home to utilize
          the program.
        </Paragraph>
      </Box>

      <Box width="100%" justifyContent="flex-end">
        <Button
          size="large"
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
            closeModal();
          }}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
};
