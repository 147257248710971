import { PropsWithChildren } from 'react';

import { colors } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';
import { CloseIconSlim, Icon, IconTypeProp } from '../Icon';
import { Paragraph } from '../Typography';

type Variant = 'info' | 'warning' | 'danger' | 'success' | 'neutral';
type VariantsObj = Record<
  Variant,
  {
    background: string;
    color: string;
    borderColor: string;
  }
>;

type AlertProps = {
  variant?: Variant;
  title?: string;
  icon?: IconTypeProp;
  iconSize?: number;
  noIcon?: boolean;
  onDismiss?: () => void;
} & BoxTypes;

const Alert = ({
  children,
  variant = 'info',
  title,
  icon = 'info',
  iconSize = 20,
  noIcon,
  onDismiss,
  ...props
}: PropsWithChildren<AlertProps>) => {
  const variants: VariantsObj = {
    info: {
      background: colors['blue100'],
      color: '#1192E5',
      borderColor: '#D0ECFF',
    },
    warning: {
      background: colors['warningLight'],
      color: 'warningDark',
      borderColor: colors['warningLight'],
    },
    danger: {
      background: colors['red200'],
      color: 'red700',
      borderColor: colors['red200'],
    },
    success: {
      background: '#EFFAF6',
      color: '#259B71',
      borderColor: '#EFFAF6',
    },
    neutral: {
      background: '#F5F6F9',
      color: '#273653',
      borderColor: '#F5F6F9',
    },
  };

  const { color, background, borderColor } = variants[variant];

  return (
    <Box
      p="20px"
      pr={onDismiss && '40px'}
      flexDirection={title ? 'column' : 'row'}
      borderRadius="4px"
      border="1px solid"
      borderColor={borderColor}
      bgcolor={background}
      gap="6px"
      alignItems="flex-start"
      position="relative"
      {...props}
    >
      {onDismiss && (
        <Box
          width="20px"
          position="absolute"
          top="12px"
          right="12px"
          cursor="pointer"
          onClick={onDismiss}
        >
          <CloseIconSlim color={color} bg={background} />
        </Box>
      )}
      {title ? (
        <>
          <Box gap="8px" mb="8px">
            {!noIcon && (
              <Icon type={icon} size={iconSize} fill={color} color={color} />
            )}
            <Paragraph variant="text-small" fontWeight={600} color={color}>
              {title}
            </Paragraph>
          </Box>
          <Paragraph variant="text-small" color={color}>
            {children}
          </Paragraph>
        </>
      ) : (
        <>
          {!noIcon && (
            <Icon type={icon} size={iconSize} fill={color} color={color} />
          )}
          <Paragraph variant="text-small" color={color}>
            {children}
          </Paragraph>
        </>
      )}
    </Box>
  );
};

export default Alert;
