import { useEffect, useMemo, useRef, useState } from 'react';

const defaultOpt = {
  delay: 250,
};

type OptionsType = {
  delay?: number;
};

function useThrottle(cb: () => void, opt: OptionsType = {}) {
  const options = { ...defaultOpt, ...opt };

  const throttled = useRef(false);
  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);

  const throttledCb = useMemo(
    () => () => {
      if (throttled.current || typeof window === 'undefined') return;

      throttled.current = true;
      setTimeoutId(
        window.setTimeout(() => {
          cb();
          throttled.current = false;
        }, options.delay)
      );
    },
    []
  );

  return throttledCb;
}

export default useThrottle;
