import {
  flexbox,
  spacing,
  positions,
  sizing,
  display,
  typography,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { FilterButtonProps } from './types';

const FILTER_BUTTON_STYLES = {
  SELECTED: {
    outlined: () => css`
      border-color: ${({ theme }) => theme.colors.electricBlue};
      &:hover {
        background-color: ${({ theme }) => theme.colors.coolGray6};
      }
    `,
    solid: () => css`
      background-color: ${({ theme }) => theme.colors.electricBlue};
      color: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 0px 0px rgba(39, 54, 83, 0.08);
      &:hover:not(:disabled) {
        background-color: ${({ theme }) => theme.colors.electricBlue};
        box-shadow: 0px 0px 0px rgba(39, 54, 83, 0.08);
      }
    `,
  },
  NOT_SELETECED: {
    outlined: () => css`
      color: ${({ theme }) => theme.colors.darkBlue};
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.electricBlue};
      border: 2px solid ${({ theme }) => theme.colors.coolGray5};
      &:hover {
        background-color: ${({ theme }) => theme.colors.coolGray6};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.coolGray4};
        box-shadow: 0px 0px 0px transparent;
        filter: opacity(0.5);
        cursor: default;
      }
    `,
    solid: () => css`
      color: ${({ theme }) => theme.colors.mediumBlue};
      background-color: ${({ theme }) => theme.colors.coolGray6};
      border: 1px solid ${({ theme }) => theme.colors.coolGray4};
      box-shadow: 0px 1px 2px rgba(39, 54, 83, 0.08);
      font-weight: bold;
      &:hover {
        background-color: ${({ theme }) => theme.colors.coolGray4};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.coolGray4};
        color: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 0px 0px transparent;
        filter: opacity(0.5);
        cursor: default;
      }
    `,
  },
};

export const FilterButtonStyledComponent = styled.button<FilterButtonProps>`
  border-radius: 1rem;
  line-height: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  text-align: center;
  display: inline-flex;
  transition: 250ms linear all;
  align-items: center;
  cursor: pointer;
  outline: none;

  ${(props) => FILTER_BUTTON_STYLES.NOT_SELETECED[props.variant ?? 'solid']()}
  ${display}
  ${flexbox}
  ${sizing}
  ${spacing}
  ${positions}
  ${typography}

  ${(props) =>
    props.selected && FILTER_BUTTON_STYLES.SELECTED[props.variant ?? 'solid']()}
`;

export default {};
