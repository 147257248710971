import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';

import { toast } from 'react-toastify';

export const handleErrors = (error: AxiosError<{ errors: string[] }>) => {
  if (error.response?.data?.errors) {
    error.response.data.errors.forEach((e) => {
      toast(e, { type: 'error' });
    });
  } else {
    toast('There was an error', { type: 'error' });
  }
  Sentry.captureException(error);
};
