import { AxiosError } from 'axios';

import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { http } from '@hl-portals/libs/http';
import { SnapshotAttributes, SnapshotResponse } from '@hl-portals/libs/types';

import { SNAPSHOT_PATH } from '@hl-portals/constants';

import { handleErrors } from '@hl-portals/helpers';

import { FETCH_SNAPSHOT_QUERY_KEY } from '../useFetchSnapshot';

export function useUpdateSnapshot<Payload, ResponseData>(
  uuid: string,
  snapshot: SnapshotAttributes<Payload>,
  options?: UseMutationOptions<ResponseData | {}, unknown, Payload>
) {
  const client = useQueryClient();

  return useMutation(
    async (payload: Payload) => {
      if (!!snapshot) {
        const res = await http.public.put<SnapshotResponse<ResponseData>>(
          SNAPSHOT_PATH(uuid),
          {
            ...snapshot,
            payload: {
              ...snapshot.payload,
              ...payload,
            },
          }
        );
        return res.data?.data?.attributes;
      }
      toast('Unable to update', { type: 'error' });
      return {};
    },
    {
      ...options,
      onSuccess: (...args) => {
        client.invalidateQueries({
          queryKey: FETCH_SNAPSHOT_QUERY_KEY(uuid),
        });

        options?.onSuccess && options.onSuccess(...args);
      },
      onError: (...args) => {
        options?.onError?.(...args);
        handleErrors(args[0] as AxiosError);
      },
    }
  );
}
