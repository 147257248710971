import styled from 'styled-components';

export const EnvMarker = styled.div`
  text-shadow: -1px -1px 0 #555, 1px -1px 0 #555, -1px 1px 0 #555, 1px 1px 0 #555;
  position: fixed;
  left: -92px;
  top: 25px;
  transform: rotate(-45deg);
  background-color: red;
  opacity: 0.9;
  z-index: 2147483647;
  height: 55px;
  width: 290px;
  overflow-x: hidden;
  box-shadow: 7px 0px 9px #000;
  color: #fff;
  pointer-events: none;
`;

export const EnvMarkerTextContainer = styled.div`
  margin: 0px 45px;
  line-height: 55px;
  height: 100%;
  width: calc(100% - 90px);
  overflow: hidden;
  text-align: center;
`;

export const EnvMarkerText = styled.span`
 display: inline-block;
 text-align: center;
 font-size: 30px;
`;
