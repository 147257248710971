import styled from 'styled-components';

export const ToggleAnchor = styled.input.attrs(() => ({ type: 'checkbox' }))`
  display: none;
`;

export const ToggleContainer = styled.div`
  width: 40px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.coolGray6};
  border: 1px solid ${(props) => props.theme.colors.coolGray4};
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease;
  display: flex;
  align-items: center;
  ::before {
    box-sizing: border-box;
    content: '';
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    margin: -1px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    display: block;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.coolGray4};
    transition: transform 0.1s ease;
  }
`;

export const ToggleWrapper = styled.label`
  ${/* sc-selector */ ToggleAnchor}:checked ~ ${
    /* sc-selector */ ToggleContainer
  } {
    background-color: ${(props) => props.theme.colors.electricBlue};
    border-color: ${(props) => props.theme.colors.electricBlue};
  }
  ${/* sc-selector */ ToggleAnchor}:checked ~ ${
    /* sc-selector */ ToggleContainer
  }::before {
    transform: translateX(16px);
    border-color: ${(props) => props.theme.colors.electricBlue};
  }
`;
