import styled from 'styled-components';

import { Paragraph, ParagraphType } from '../Typography';

const Ul = styled(Paragraph)`
  list-style: none;
  > ul {
    margin-left: 1rem;
  }
`;

const Li = styled.li`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  ::before {
    content: '·';
    display: inline-block;
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1.2em;
  }
`;

type RecursiveList = Array<string | React.ReactChild | RecursiveList>;

const List = ({
  items,
  ...rest
}: { items: RecursiveList } & Partial<ParagraphType>) => (
  <Ul color="darkBlue" variant="text-small" {...rest} as="ul">
    {items.map((v, index) =>
      Array.isArray(v) ? (
        <List {...rest} items={v} key={index} />
      ) : (
        <Li key={index}>{v}</Li>
      )
    )}
  </Ul>
);

export default List;
