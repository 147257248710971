import { colors } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';

const Layout = ({ children, ...extraProps }: BoxTypes): React.ReactElement => (
  <Box
    flexDirection="column"
    flexWrap="wrap"
    alignItems="flex-start"
    justifyContent="space-between"
    width="100%"
    maxWidth="1280px"
    bgcolor={colors.white}
    mx="auto"
    mt={{ xs: '20px', md: '60px' }}
    px={{ xs: '20px', md: '12px' }}
    {...extraProps}
  >
    {children}
  </Box>
);

export default Layout;
