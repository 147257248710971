import styled, { css } from 'styled-components';
import { sizing, SizingProps, spacing, SpacingProps } from '@mui/system';

export const RadioInput = styled.span<
  { disabled: boolean } & SizingProps & SpacingProps
>`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.coolGray4};
  background: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  &::before {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: ${({ theme }) => theme.colors.electricBlue};
    transform: translate(-50%, -50%);
    border-radius: 50%;

    ${({ theme, disabled }) =>
      disabled &&
      css`
        content: '';
        background: ${theme.colors.coolGray4};
        border-color: ${theme.colors.coolGray4};
        cursor: not-allowed;
      `}
  }

  ${sizing};
  ${spacing};
`;

export const RadioNative = styled.input`
  display: none;
  &:checked + ${RadioInput} {
    border: 2px solid ${({ theme }) => theme.colors.electricBlue};
    &::before {
      content: '';
    }
  }
`;
