import styled from 'styled-components';

import { Box } from '../Box';

export const UploadedFilesList = styled(Box)`
  &::-webkit-scrollbar-track {
    background-color: white;
  }
`;

export default {};
