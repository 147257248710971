import { SizingProps, SpacingProps } from '@mui/system';

import { useState } from 'react';

import { CSSVariants } from '@hl-portals/helpers';

import { useHandleClickOutside } from '@hl-portals/hooks';

import { BoxTypes } from '../Box';
import { Option, OptionList, SelectWrapper, SelectedItem } from './styles';

export type SelectProps<T extends string = string> = {
  id?: string;
  disabled?: boolean;
  name?: string;
  placeholder: string;
  required?: boolean;
  selectedValue?: T;
  dataTest?: string;
  onOpen?: () => void;
  onChange: (value: T) => void;
  onReset?: () => void;
  options: {
    text: string | React.ReactElement;
    value: T;
    disabled?: boolean;
    selected?: boolean;
  }[];
  isError?: boolean;
  variant?: CSSVariants<'bottom' | 'top'>;
  onBlur?: () => void;
  className?: string;
  customSelectedItemCss?: Record<string, unknown>;
  'data-test'?: string;
} & SpacingProps &
  SizingProps &
  BoxTypes;

const PLACEHOLDER_VALUE = 'PLACEHOLDER_VALUE';

export const Select = <T extends string>({
  id,
  disabled,
  name,
  required,
  options: optionsProp,
  placeholder,
  selectedValue,
  onOpen,
  onChange,
  onReset,
  isError,
  onBlur,
  variant = 'bottom',
  dataTest = 'select-selected-item',
  customSelectedItemCss,
  ...extraProps
}: SelectProps<T>): React.ReactElement => {
  const BASE_OPTIONS = onReset
    ? [
        {
          text: placeholder,
          value: PLACEHOLDER_VALUE as T,
          disabled: false,
          selected: false,
        },
      ]
    : [];

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const options = [...BASE_OPTIONS, ...optionsProp].map((option) => ({
    ...option,
    selected: option.value === selectedValue,
  }));

  const selectRef = useHandleClickOutside<HTMLElement>({
    onClickOutside: () => {
      setIsOpen(false);
      const newIndex = options.findIndex(
        (option) => option.value === selectedValue
      );
      setSelectedIndex(newIndex);
      if (onBlur && isOpen) onBlur();
    },
  });

  const changeSelected = (value: T) => {
    if (value === PLACEHOLDER_VALUE && onReset) {
      onReset();
    } else {
      onChange(value);
    }
  };

  const updateSelectedIndex = () =>
    setSelectedIndex(
      options.findIndex((option) => option.value === selectedValue)
    );

  const navigateTo = (direction: 'up' | 'down') => {
    if (!isOpen) {
      setIsOpen(true);
      updateSelectedIndex();
      return;
    }
    const directions = {
      up: selectedIndex === 0 ? 0 : selectedIndex - 1,
      down:
        selectedIndex === options.length - 1
          ? options.length - 1
          : selectedIndex + 1,
    };
    const newSelectedIndex = directions[direction];
    setSelectedIndex(newSelectedIndex);
  };

  const onOpenClick = () => {
    if (onOpen) {
      onOpen();
    }
    if (!disabled) {
      setIsOpen(!isOpen);
    }
    if (!isOpen) updateSelectedIndex();
  };

  const onKeyDown = (event: any) => {
    switch (event.key) {
      case 'Tab':
        if (isOpen) {
          event.preventDefault();
          setIsOpen(false);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();

        navigateTo('down');
        break;
      case 'ArrowUp':
        event.preventDefault();

        navigateTo('up');
        break;
      case 'Enter':
        event.preventDefault();
        setIsOpen(!isOpen);
        if (isOpen) {
          if (!options[selectedIndex].disabled) {
            onChange(options[selectedIndex]?.value ?? ('' as T));
          }
        } else {
          updateSelectedIndex();
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const selected = options.find((option) => Boolean(option.selected));
  const refProps = { ref: selectRef };

  return (
    <>
      {/* @ts-ignore */}
      <SelectWrapper
        id={id}
        name={name}
        required={Boolean(required)}
        onClick={onOpenClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        role="listbox"
        data-test="select"
        {...refProps}
        {...extraProps}
      >
        <SelectedItem
          role="button"
          width="100%"
          open={isOpen}
          isPlaceholder={!selected}
          disabled={Boolean(disabled)}
          data-test={dataTest}
          isError={isError}
          {...customSelectedItemCss}
        >
          {selected ? selected.text : placeholder}
        </SelectedItem>
        <OptionList
          open={isOpen}
          variant={variant}
          data-test="select-option-list"
        >
          {options.map((option, index) => (
            <Option
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={Boolean(option.disabled)}
              selected={selectedIndex === index}
              onClick={() => !option.disabled && changeSelected(option.value)}
              onMouseOver={() => setSelectedIndex(index)}
              role="option"
              data-test={
                option.value === PLACEHOLDER_VALUE ? '' : 'select-option-item'
              }
            >
              {option.text}
            </Option>
          ))}
        </OptionList>
      </SelectWrapper>
    </>
  );
};
