import { ReactNode } from 'react';

import { http } from '@hl-portals/libs/http';

import { Box, Paragraph, SimpleLink } from '@hl-portals/ui';

import {
  AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECK_PUBLIC,
  theme,
} from '@hl-portals/constants';

import { normalizeCurrency } from '@hl-portals/helpers';

export type AutomatedBbysPropertyEligibilityChecks = {
  lot_size_check: boolean;
  property_type_check: boolean;
  room_count_check: boolean;
  zoning_description_check: boolean;
  property_value_check: boolean;
  ltv_check: boolean;
};

export type AutomatedBbysPropertyWarningChecks = {
  low_equity_check: boolean;
  rural_property_check: boolean;
  listed_DOM_check: boolean;
  unrealistic_value_check: boolean;
};

export type AutomatedBbysPropertyDenialChecks = {
  ineligible_state_check: boolean;
  ineligible_zip_check: boolean;
  lot_size_check: boolean;
  low_value_check: boolean;
  not_enough_equity_check: boolean;
  sq_footage_check: boolean;
  zoning_description_check: boolean;
};

export type AutomatedBbysPropertyEligibilityBlockers = {
  lead_exists: boolean;
};

export type PropertyEligibilityChecksResponse = {
  eligibility_checks: AutomatedBbysPropertyEligibilityChecks;
  eligibility_blockers: AutomatedBbysPropertyEligibilityBlockers;
  warning_checks: AutomatedBbysPropertyWarningChecks;
  denial_checks: AutomatedBbysPropertyDenialChecks;
  liens_present: boolean;
  property_address: string;
  acknowledged: boolean;
  canceled_submission: boolean;
  agent_valuation: string;
};

export type AutomatedBbysEligibilityCheckArgs = {
  lenderName: string;
  lenderEmail: string;
  lenderPhone: string;
  lenderCompany: string;
  uuid: string;
  state: string;
  value: string;
  mortgage?: string;
  targetAmount?: string;
  targetAmountRadio?: string;
  agentName?: string;
  agentEmail?: string;
  agentPhone?: string;
  flow?: string;
  salesConsultantName?: string;
  salesConsultantEmail?: string;
  salesConsultantPhone?: string;
  liens?: string;
};

export async function checkAutomatedPropertyEligibility({
  lenderName,
  lenderEmail,
  lenderPhone,
  lenderCompany,
  uuid,
  state,
  value,
  mortgage,
  targetAmount,
  targetAmountRadio,
  agentName,
  agentEmail,
  agentPhone,
  flow,
  salesConsultantName,
  salesConsultantEmail,
  salesConsultantPhone,
  liens,
}: AutomatedBbysEligibilityCheckArgs): Promise<PropertyEligibilityChecksResponse> {
  const res = await http.public.get(
    AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECK_PUBLIC,
    {
      params: {
        lender_name: lenderName,
        lender_email: lenderEmail,
        lender_phone: lenderPhone,
        lender_company: lenderCompany,
        agent_name: agentName,
        agent_email: agentEmail,
        agent_phone: agentPhone,
        sales_consultant_name: salesConsultantName,
        sales_consultant_email: salesConsultantEmail,
        sales_consultant_phone: salesConsultantPhone,
        flow: flow,
        property_uuid: uuid,
        property_state: state,
        agent_valuation: value && normalizeCurrency(value),
        target_unlock_amount:
          targetAmountRadio === '0' && targetAmount
            ? normalizeCurrency(targetAmount)
            : null,
        agent_estimated_loan_payoff: mortgage && normalizeCurrency(mortgage),
        source: 'public_lender_euc',
        source_form: 'public_lender_euc_submission',
        liens,
      },
    }
  );

  return res?.data?.data?.attributes;
}

export const BlockerWarning = ({
  title,
  description,
}: {
  title: ReactNode;
  description: ReactNode;
}) => (
  <Box flexDirection="column" gap="8px" alignItems="center">
    <Paragraph variant="heading-4" textAlign="center">
      {title}
    </Paragraph>
    <Paragraph variant="text" textAlign="center">
      {description}
    </Paragraph>
    <Paragraph variant="text" textAlign="center">
      Need help? Contact us at{' '}
      <SimpleLink
        href="mailto:lenders@homelight.com"
        color={theme.colors.aaBlue}
        fontSize="16px"
      >
        lenders@homelight.com
      </SimpleLink>
    </Paragraph>
  </Box>
);
