import {
  BordersProps,
  DisplayProps,
  FlexboxProps,
  SizingProps,
  SpacingProps,
  borders,
  display,
  flexbox,
  sizing,
  spacing,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { media, paragraphHelper } from '@hl-portals/helpers';

import { Box } from '../Box';

interface DataRowProps {
  isLink?: boolean;
  off?: boolean;
}

export const DataRow = styled.a<
  DataRowProps & FlexboxProps & DisplayProps & SpacingProps & SizingProps
>`
  ${({ theme, isLink, off }) => css`
    ${paragraphHelper({ type: 'text-small', theme })}
    color: ${theme.colors.darkBlue};
    padding: 20px;
    background-color: ${off ? theme.colors.coolGray6 : theme.colors.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.colors.coolGray4};

    ${isLink ? 'cursor: pointer;' : ''}
    ${media.desktop`
      padding: 12px 20px;
      flex-direction: row;
      align-items: center;
    `}
  `}
  ${flexbox}
  ${display}
  ${spacing}
  ${sizing}
`;

const DataTableWrapper = styled(Box)<SpacingProps & SizingProps & BordersProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.coolGray4};
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
  ${spacing};
  ${sizing};
  ${borders};
`;

interface DataTableProps {
  children: React.ReactNode;
}

export const DataTable = ({
  children,
  ...extraProps
}: DataTableProps &
  SpacingProps &
  SizingProps &
  FlexboxProps &
  BordersProps): React.ReactElement => (
  <DataTableWrapper {...extraProps}>{children}</DataTableWrapper>
);

export const DataBody = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray4};
  position: relative;
  flex-direction: column;

  &::after {
    content: '.';
    color: white;
    font-size: 1px;
    margin-top: -1px;
    width: 100%;
    background: white;
  }
`;
