import { ReactNode } from 'react';

import { useScreenSize } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';

type ResponsiveDisplayProps = {
  desktop?: ReactNode;
  mobile?: ReactNode;
} & BoxTypes;

const ResponsiveDisplay = ({
  desktop,
  mobile,
  ...extraProps
}: ResponsiveDisplayProps): React.ReactElement => {
  const { isDesktop } = useScreenSize();

  return <Box {...extraProps}>{isDesktop ? desktop : mobile}</Box>;
};

export default ResponsiveDisplay;
