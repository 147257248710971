type RenderFn<T> = (data?: T) => React.ReactElement<any, any>;
type Keys = string | number | symbol;
type With<T extends Keys> = Record<T, RenderFn<T>>;

interface Props<T extends Keys> {
  value: T;
  with: With<T>;
}

function Match<T extends Keys>(props: Props<T>) {
  const foundMethod = Object.keys(props.with).find(
    (key) => key === (props.value as unknown as string)
  ) as unknown as keyof With<T>;

  if (!foundMethod) return <></>;
  return props.with[foundMethod](props.value);
}

export default Match;
