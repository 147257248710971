import styled from 'styled-components';

import { ReactNode } from 'react';

import { media, slideInFromLeft, slideInFromRight } from '@hl-portals/helpers';

import { Box, BoxTypes } from '../Box';

const Sidebar = styled(Box)`
  animation: ${slideInFromLeft} 0.2s ease-out forwards;

  ${media.untilTablet`
    display: none
  `}
`;

const Content = styled(Box)`
  animation: ${slideInFromRight} 0.2s ease-out forwards;

  ${media.untilTablet`
    width: 100%;
  `}
`;

type DashboardProps = {
  sidebar?: ReactNode;
  content: ReactNode;
  contentProps?: BoxTypes;
  sidebarProps?: BoxTypes;
} & BoxTypes;

const Dashboard = ({
  sidebar,
  content,
  contentProps = {},
  sidebarProps = {},
  ...extraProps
}: DashboardProps): React.ReactElement => (
  <Box width="100%" {...extraProps}>
    {sidebar && (
      <Sidebar width="34%" {...sidebarProps}>
        {sidebar}
      </Sidebar>
    )}
    <Content width={sidebar ? '66%' : '100%'} {...contentProps}>
      {content}
    </Content>
  </Box>
);

export default Dashboard;
