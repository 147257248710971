import * as Sentry from '@sentry/nextjs';
import { v4 as uuidv4 } from 'uuid';

import { PUBLIC_EVENT_PATH } from '@hl-portals/constants';

const requestId = uuidv4();

export function usePublicEvent<Event>(source?: string) {
  async function recordPublicEvent(event: Event = {} as Event) {
    try {
      const payload = {
        source,
        request_id: requestId,
        ...event,
      };

      fetch(PUBLIC_EVENT_PATH, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Request-Id': requestId,
        }),
        body: JSON.stringify(payload),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return { recordPublicEvent };
}
