// @ts-nocheck
import { spacing } from '@mui/system';
import styled, { css } from 'styled-components';

import { media } from '@hl-portals/helpers';

import { Button } from '../Button';
import { ButtonGroupProps } from './types';

export const ButtonWrapper = styled.div<ButtonGroupProps>`
  ${spacing}
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};

  ${media.tablet`
    width: auto;
  `}

  ${Button} {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -1px;
      ${media.tablet`
        ${({ orientation: o }) => css`
          margin-left: ${o === 'vertical' ? '0' : '-1'}px;
          margin-top: ${o === 'vertical' ? '-1' : '0'}px;
          border-top-right-radius: ${o === 'vertical' ? '0' : '3'}px;
          border-top-left-radius: ${o === 'vertical' ? '0' : '0'}px;
          border-bottom-left-radius: ${o === 'vertical' ? '3' : '0'}px;
          ${o === 'vertical' && 'border-top-right-radius: 0px;'};
        `}
      `}
    }
    &:not(:last-child) {
      border-bottom-right-radius: 0;
      ${media.untilTablet`
        border-bottom-left-radius: 0;
        border-bottom-color: transparent;
      `}
      ${media.tablet`
        ${({ orientation: o }) => css`
          border-bottom-right-radius: ${o === 'vertical' ? '0' : '0'}px;
          ${o === 'vertical' && 'border-bottom-left-radius: 0;'}
          ${o === 'horizontal' && 'border-top-right-radius: 0px;'}
          ${o === 'horizontal' && 'border-right-color: transparent;'}
        `}
      `}
    }
  }
`;

export default ButtonWrapper;
