import styled from 'styled-components';

import { Box } from '../Box';

export const Marker = styled(Box)<{ active: boolean; completed: boolean }>`
  ${({ active, completed, theme }) => `
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${theme.colors.coolGray3};
    color: ${theme.colors.coolGray2};
    display: flex;
    height: 32px;
    justify-content: center;
    text-align: center;
    transition: all .2s ease-out;
    width: 32px;

    ${
      (active || completed) &&
      `
      background-color: ${theme.colors.electricBlue};
      border-color: ${theme.colors.electricBlue};
      color: ${theme.colors.white};
    `
    }

    ${
      completed &&
      `
      @keyframes pop-in {
        0% {
          transform: scale(.8);
          opacity: 0;
        }
        60% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
      
      opacity: 0;
      animation: pop-in .5s ease-out forwards;
    `
    }
  `}
`;

export const Separator = styled(Box)`
  ${({ theme }) => `
    height: 1px;
    width: 32px;
    background-color: ${theme.colors.coolGray3};
    margin: 0 12px;
  `}
`;
