import { ReactNode } from 'react';

import { useModal } from '@hl-portals/hooks';

import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';

type Props = {
  items: Array<string | ReactNode>;
  onContinue: () => void;
};

export const PropertyBlockedModal = ({ items, onContinue }: Props) => {
  const { closeModal } = useModal();

  return (
    <Box
      id="property-eligibility-modal"
      maxWidth={{ xs: '100%', md: '628px' }}
      maxHeight={{ xs: 'none', md: '90vh' }}
      height="fit-content"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="16px"
      width="100%"
      overflowY={{ xs: 'auto', md: 'scroll' }}
      mt={{ xs: '40px', md: '0' }}
    >
      <Box
        flexDirection="column"
        gap="20px"
        mb="32px"
        alignItems="center"
        justifyContent="center"
      >
        <Box gap="8px" alignItems="center" justifyContent="center">
          <Icon size={120} type="referralsBlocked" />
        </Box>
      </Box>

      <Box flexDirection="column" gap="16px" mb="32px">
        {items.map((item, idx) => (
          <Box gap="8px" justifyContent="center" key={idx}>
            {item}
          </Box>
        ))}
      </Box>

      <Box width="100%" gap="16px" justifyContent="center">
        <Button
          size="large"
          data-test="eligibility-continue-btn"
          onClick={() => {
            closeModal();
            onContinue();
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};
