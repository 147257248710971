import * as Sentry from '@sentry/nextjs';

import { BBYS_DROPOUTS_PATH } from '@hl-portals/constants';

export const useDropoutsCheck = (updateDropoutCheck?: () => void) => {
  const postDropoutCheck = (snapshot_uuid?: string | string[]) => {
    return fetch(BBYS_DROPOUTS_PATH, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: snapshot_uuid
        ? JSON.stringify({ snapshot_uuid: snapshot_uuid })
        : null,
    })
      .then(() => {
        if (updateDropoutCheck) updateDropoutCheck();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return { postDropoutCheck };
};
