import { Box } from '../../Box';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Paragraph } from '../../Typography';
import { FileName, UploadedFileWrapper } from '../styles';

export interface UploadedFileProps {
  resetFiles: () => void;
  files: Array<{ name: string }>;
}

export const UploadedFiles = ({
  files,
  resetFiles,
}: UploadedFileProps): React.ReactElement => (
  <UploadedFileWrapper flexDirection="column" alignItems="center">
    {files.map((file) => (
      <Box
        key={file.name}
        data-testid={file.name}
        flexDirection="column"
        alignItems="center"
      >
        <FileName
          variant="text-small-bold"
          color="coolGray2"
          data-test="file-upload-name"
          maxWidth={{ xs: 250, md: 350 }}
        >
          {file.name}
        </FileName>
      </Box>
    ))}
    <Paragraph
      variant="text-small"
      color="coolGray2"
      m={1}
      data-test="file-upload-success"
    >
      successfully uploaded!
    </Paragraph>
    <Button
      variant="outlined"
      onClick={resetFiles}
      iconLeft={<Icon fill="electricBlue" type="times" />}
    >
      Remove File{files.length > 1 ? 's' : ''}
    </Button>
  </UploadedFileWrapper>
);
