export const BankIcon = () => (
  <svg
    width="224"
    height="233"
    viewBox="0 0 224 233"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M201.868 22.6188L154.471 57.7693L171.169 80.2795L218.567 45.129L201.868 22.6188Z"
      fill="url(#paint0_linear_9672_14236)"
    />
    <path
      d="M201.868 22.6188L154.471 57.7693L171.169 80.2795L218.567 45.129L201.868 22.6188Z"
      fill="url(#paint1_linear_9672_14236)"
    />
    <g opacity="0.62">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.102 58.0612L201.099 26.174L214.924 44.8189L171.927 76.706L158.102 58.0612ZM160.271 58.3855L172.251 74.5373L212.762 44.4969L200.782 28.3451L160.271 58.3855Z"
        fill="#55B788"
      />
      <path
        d="M159.188 58.2317L163.92 54.7189C164.38 55.3398 164.717 56.05 164.901 56.8006C165.092 57.5536 165.127 58.3308 165.013 59.0953C164.898 59.8598 164.638 60.5977 164.24 61.2652C163.841 61.9327 163.319 62.5114 162.691 62.969L159.185 58.2386L159.188 58.2317Z"
        fill="#55B788"
      />
      <path
        d="M172.098 75.5973L168.585 70.8646C169.206 70.4046 169.916 70.0679 170.667 69.8841C171.42 69.6934 172.197 69.6578 172.961 69.7723C173.726 69.8869 174.464 70.147 175.131 70.5453C175.799 70.9435 176.377 71.466 176.835 72.0938L172.105 75.5997L172.098 75.5973Z"
        fill="#55B788"
      />
      <path
        d="M200.936 27.2621L204.454 32.0042C203.833 32.4642 203.122 32.8009 202.372 32.9847C201.619 33.1754 200.842 33.211 200.077 33.0964C199.313 32.9818 198.575 32.7218 197.907 32.3235C197.24 31.9252 196.661 31.4028 196.203 30.7749L200.936 27.2621Z"
        fill="#55B788"
      />
      <path
        d="M213.841 44.6649L209.115 48.1569C208.655 47.536 208.319 46.8258 208.135 46.0752C207.944 45.3222 207.908 44.545 208.023 43.7805C208.138 43.016 208.398 42.2781 208.796 41.6106C209.194 40.9431 209.717 40.3645 210.344 39.9068L213.85 44.6604L213.841 44.6649Z"
        fill="#55B788"
      />
      <path
        d="M185.437 58.7143C189.451 59.3142 193.181 56.5405 193.781 52.5264C194.381 48.5122 191.607 44.7818 187.593 44.1819C183.579 43.582 179.849 46.3556 179.249 50.3698C178.649 54.384 181.422 58.1144 185.437 58.7143Z"
        fill="#55B788"
      />
    </g>
    <path
      d="M162.816 7.11296L145.944 63.6585L172.803 71.6683L189.675 15.1228L162.816 7.11296Z"
      fill="url(#paint2_linear_9672_14236)"
    />
    <path
      d="M162.816 7.11296L145.944 63.6585L172.803 71.6683L189.675 15.1228L162.816 7.11296Z"
      fill="url(#paint3_linear_9672_14236)"
    />
    <g opacity="0.62">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M149.027 61.7148L164.332 10.4188L186.573 17.0574L171.268 68.3534L149.027 61.7148ZM150.957 60.6745L170.228 66.423L184.65 18.0955L165.379 12.347L150.957 60.6745Z"
        fill="#55B788"
      />
      <path
        d="M149.998 61.2005L151.681 55.5519C152.421 55.7732 153.116 56.1399 153.713 56.6306C154.317 57.119 154.812 57.7198 155.178 58.4004C155.545 59.0811 155.779 59.8276 155.86 60.6006C155.941 61.3737 155.87 62.15 155.642 62.8926L150 61.2074L149.998 61.2005Z"
        fill="#55B788"
      />
      <path
        d="M170.74 67.3639L165.092 65.681C165.313 64.9406 165.68 64.2454 166.17 63.6484C166.659 63.0444 167.259 62.55 167.94 62.1835C168.621 61.8171 169.367 61.5829 170.14 61.5016C170.913 61.4204 171.69 61.4917 172.432 61.72L170.747 67.3617L170.74 67.3639Z"
        fill="#55B788"
      />
      <path
        d="M164.853 11.3879L170.51 13.0756C170.289 13.816 169.922 14.5112 169.432 15.1082C168.943 15.7122 168.343 16.2065 167.662 16.573C166.981 16.9395 166.235 17.1736 165.462 17.2549C164.689 17.3362 163.912 17.2648 163.17 17.0365L164.853 11.3879Z"
        fill="#55B788"
      />
      <path
        d="M185.614 17.5838L183.924 23.2115C183.184 22.9902 182.488 22.6235 181.891 22.1328C181.287 21.6444 180.793 21.0436 180.427 20.363C180.06 19.6824 179.826 18.9358 179.745 18.1628C179.663 17.3898 179.735 16.6134 179.963 15.8708L185.618 17.5746L185.614 17.5838Z"
        fill="#55B788"
      />
      <path
        d="M171.297 45.8551C174.87 43.9293 176.194 39.4732 174.268 35.9004C172.342 32.3276 167.886 31.004 164.313 32.9298C160.74 34.8556 159.417 39.3118 161.342 42.8845C163.268 46.4573 167.724 47.7809 171.297 45.8551Z"
        fill="#55B788"
      />
    </g>
    <path
      d="M206.225 98.6672H14.9287V222.013H206.225V98.6672Z"
      fill="#E1E1E1"
    />
    <path
      d="M206.225 98.6672H14.9287V222.013H206.225V98.6672Z"
      fill="#E9F6FF"
    />
    <path d="M206.188 99.04H14.8916V107.866H206.188V99.04Z" fill="#BBD3EA" />
    <path
      d="M52.6581 99.04H24.2656V105.094H28.9307V212.639H24.2876H24.2656V218.693H52.6581V212.639H52.5045H47.9857V105.094H52.6581V101.182H52.7166V99.6323H52.6581V99.04Z"
      fill="#BBD3EA"
    />
    <path
      d="M91.7479 99.04H63.3555V105.094H68.0205V212.639H63.3847H63.3555V218.693H91.7479V212.639H91.5944H87.0755V105.094H91.7479V101.182H91.8064V99.6323H91.7479V99.04Z"
      fill="#BBD3EA"
    />
    <path
      d="M159.75 99.04H131.357V105.094H136.03V212.639H131.387H131.357V218.693H159.75V212.639H159.596H155.078V105.094H159.75V101.182H159.808V99.6323H159.75V99.04Z"
      fill="#BBD3EA"
    />
    <path
      d="M198.935 99.6323H198.869V99.04H170.477V105.094H175.149V212.639H170.506H170.477V218.693H198.869V212.639H198.723H194.204V105.094H198.869V101.182H198.935V99.6323Z"
      fill="#BBD3EA"
    />
    <path d="M87.076 98.6672H58.6836V104.722H87.076V98.6672Z" fill="white" />
    <path d="M87.076 212.266H58.6836V218.32H87.076V212.266Z" fill="white" />
    <path d="M82.4105 104.721H63.3555V213.012H82.4105V104.721Z" fill="white" />
    <path
      d="M82.4105 104.721H63.3555V106.272H82.4105V104.721Z"
      fill="#C7DEEE"
    />
    <path
      d="M82.4105 210.716H63.3555V212.266H82.4105V210.716Z"
      fill="#C7DEEE"
    />
    <path
      d="M68.1674 110.271H67.3046C66.7998 110.271 66.3906 110.68 66.3906 111.185V206.841C66.3906 207.345 66.7998 207.755 67.3046 207.755H68.1674C68.6722 207.755 69.0814 207.345 69.0814 206.841V111.185C69.0814 110.68 68.6722 110.271 68.1674 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M73.2641 110.271H72.4013C71.8965 110.271 71.4873 110.68 71.4873 111.185V206.841C71.4873 207.345 71.8965 207.755 72.4013 207.755H73.2641C73.7689 207.755 74.1781 207.345 74.1781 206.841V111.185C74.1781 110.68 73.7689 110.271 73.2641 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M78.5795 110.271H77.7167C77.2119 110.271 76.8027 110.68 76.8027 111.185V206.841C76.8027 207.345 77.2119 207.755 77.7167 207.755H78.5795C79.0843 207.755 79.4935 207.345 79.4935 206.841V111.185C79.4935 110.68 79.0843 110.271 78.5795 110.271Z"
      fill="#C7DEEE"
    />
    <path d="M164.356 98.6672H135.964V104.722H164.356V98.6672Z" fill="white" />
    <path d="M164.356 212.266H135.964V218.32H164.356V212.266Z" fill="white" />
    <path d="M159.684 104.721H140.629V213.012H159.684V104.721Z" fill="white" />
    <path
      d="M159.684 104.721H140.629V106.272H159.684V104.721Z"
      fill="#C7DEEE"
    />
    <path
      d="M159.684 210.716H140.629V212.266H159.684V210.716Z"
      fill="#C7DEEE"
    />
    <path
      d="M145.44 110.271H144.577C144.072 110.271 143.663 110.68 143.663 111.185V206.841C143.663 207.345 144.072 207.755 144.577 207.755H145.44C145.945 207.755 146.354 207.345 146.354 206.841V111.185C146.354 110.68 145.945 110.271 145.44 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M150.537 110.271H149.674C149.169 110.271 148.76 110.68 148.76 111.185V206.841C148.76 207.345 149.169 207.755 149.674 207.755H150.537C151.041 207.755 151.451 207.345 151.451 206.841V111.185C151.451 110.68 151.041 110.271 150.537 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M155.853 110.271H154.99C154.485 110.271 154.076 110.68 154.076 111.185V206.841C154.076 207.345 154.485 207.755 154.99 207.755H155.853C156.358 207.755 156.767 207.345 156.767 206.841V111.185C156.767 110.68 156.358 110.271 155.853 110.271Z"
      fill="#C7DEEE"
    />
    <path d="M203.775 98.6672H175.383V104.722H203.775V98.6672Z" fill="white" />
    <path d="M203.775 212.266H175.383V218.32H203.775V212.266Z" fill="white" />
    <path d="M199.11 104.721H180.055V213.012H199.11V104.721Z" fill="white" />
    <path d="M199.11 104.721H180.055V106.272H199.11V104.721Z" fill="#C7DEEE" />
    <path d="M199.11 210.716H180.055V212.266H199.11V210.716Z" fill="#C7DEEE" />
    <path
      d="M184.867 110.271H184.004C183.499 110.271 183.09 110.68 183.09 111.185V206.841C183.09 207.345 183.499 207.755 184.004 207.755H184.867C185.371 207.755 185.781 207.345 185.781 206.841V111.185C185.781 110.68 185.371 110.271 184.867 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M189.963 110.271H189.101C188.596 110.271 188.187 110.68 188.187 111.185V206.841C188.187 207.345 188.596 207.755 189.101 207.755H189.963C190.468 207.755 190.877 207.345 190.877 206.841V111.185C190.877 110.68 190.468 110.271 189.963 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M195.279 110.271H194.416C193.911 110.271 193.502 110.68 193.502 111.185V206.841C193.502 207.345 193.911 207.755 194.416 207.755H195.279C195.784 207.755 196.193 207.345 196.193 206.841V111.185C196.193 110.68 195.784 110.271 195.279 110.271Z"
      fill="#C7DEEE"
    />
    <path d="M48.3221 98.6672H19.9297V104.722H48.3221V98.6672Z" fill="white" />
    <path d="M48.3221 212.266H19.9297V218.32H48.3221V212.266Z" fill="white" />
    <path d="M43.6576 104.721H24.6025V213.012H43.6576V104.721Z" fill="white" />
    <path
      d="M43.6576 104.721H24.6025V106.272H43.6576V104.721Z"
      fill="#C7DEEE"
    />
    <path
      d="M43.6576 210.716H24.6025V212.266H43.6576V210.716Z"
      fill="#C7DEEE"
    />
    <path
      d="M29.4135 110.271H28.5507C28.0459 110.271 27.6367 110.68 27.6367 111.185V206.841C27.6367 207.345 28.0459 207.755 28.5507 207.755H29.4135C29.9183 207.755 30.3275 207.345 30.3275 206.841V111.185C30.3275 110.68 29.9183 110.271 29.4135 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M34.5102 110.271H33.6474C33.1426 110.271 32.7334 110.68 32.7334 111.185V206.841C32.7334 207.345 33.1426 207.755 33.6474 207.755H34.5102C35.015 207.755 35.4242 207.345 35.4242 206.841V111.185C35.4242 110.68 35.015 110.271 34.5102 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M39.8256 110.271H38.9628C38.458 110.271 38.0488 110.68 38.0488 111.185V206.841C38.0488 207.345 38.458 207.755 38.9628 207.755H39.8256C40.3304 207.755 40.7396 207.345 40.7396 206.841V111.185C40.7396 110.68 40.3304 110.271 39.8256 110.271Z"
      fill="#C7DEEE"
    />
    <path
      d="M0.290039 86.9973V80.5993L113.662 23.3464L222.516 80.9722V86.9973H0.290039Z"
      fill="#E1EEF5"
    />
    <path
      d="M211.533 86.9973H11.1924V99.2522H211.533V86.9973Z"
      fill="#E9F6FF"
    />
    <path
      d="M211.702 218.321H12.2529V225.706H211.702V218.321Z"
      fill="#BBD3EA"
    />
    <path
      d="M216.462 225.508H4.66992V232.893H216.462V225.508Z"
      fill="#BBD3EA"
    />
    <path
      d="M28.1484 77.3456L113.56 34.2122L195.023 77.3456H28.1484Z"
      fill="#E9F6FF"
    />
    <path
      d="M40.0085 77.3456L113.86 39.5645L187.148 77.3456H195.023L113.56 34.2122L28.1484 77.3456H40.0085Z"
      fill="#BBD3EA"
    />
    <path
      d="M119.044 66.1508C118.13 67.0429 116.814 67.5913 115.088 67.8106V70.3698H112.807V67.8764C111.681 67.8179 110.606 67.6205 109.582 67.2842C108.559 66.9478 107.74 66.5383 107.118 66.0484L108.376 63.2626C108.953 63.6867 109.633 64.045 110.409 64.3374C111.184 64.6299 111.988 64.8127 112.807 64.8858V61.069C111.725 60.8131 110.811 60.5206 110.065 60.1988C109.319 59.8771 108.698 59.4092 108.193 58.7949C107.688 58.1807 107.44 57.3618 107.44 56.3381C107.44 55.0731 107.901 53.991 108.822 53.0843C109.743 52.1776 111.074 51.6292 112.807 51.4245V48.8872H115.088V51.3806C116.96 51.5341 118.51 51.9948 119.724 52.7772L118.583 55.585C117.443 54.9269 116.273 54.5321 115.088 54.3931V58.2758C116.156 58.5171 117.055 58.7949 117.794 59.1094C118.532 59.4238 119.154 59.8844 119.658 60.506C120.163 61.1202 120.411 61.9318 120.411 62.9408C120.411 64.1912 119.958 65.2587 119.037 66.1508H119.044ZM111.549 57.0913C111.842 57.3326 112.258 57.5446 112.807 57.7055V54.4589C112.229 54.5979 111.805 54.8099 111.527 55.1097C111.249 55.4095 111.118 55.7532 111.118 56.148C111.118 56.5429 111.264 56.8426 111.549 57.0839V57.0913ZM116.324 64.1985C116.602 63.9206 116.733 63.5989 116.733 63.2187C116.733 62.8385 116.594 62.5241 116.309 62.2828C116.024 62.0415 115.622 61.8221 115.088 61.6393V64.7908C115.637 64.6665 116.046 64.469 116.324 64.1985Z"
      fill="#C7DEEE"
    />
    <path d="M128.41 158.867H95.8867V218.32H128.41V158.867Z" fill="#E1EEF5" />
    <defs>
      <linearGradient
        id="paint0_linear_9672_14236"
        x1="227.378"
        y1="7.15773"
        x2="185.501"
        y2="52.2605"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#20B270" />
        <stop offset="0.25" stop-color="#249B64" />
        <stop offset="0.51" stop-color="#29895B" />
        <stop offset="1" stop-color="#0B8956" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9672_14236"
        x1="227.378"
        y1="7.15773"
        x2="164.399"
        y2="69.1627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3EC98A" />
        <stop offset="0.887851" stop-color="#09623E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9672_14236"
        x1="173.969"
        y1="-20.5533"
        x2="167.481"
        y2="40.6501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#20B270" />
        <stop offset="0.25" stop-color="#249B64" />
        <stop offset="0.51" stop-color="#29895B" />
        <stop offset="1" stop-color="#0B8956" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9672_14236"
        x1="173.969"
        y1="-20.5533"
        x2="160.721"
        y2="66.8274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3EC98A" />
        <stop offset="0.887851" stop-color="#138255" />
      </linearGradient>
    </defs>
  </svg>
);
