import { useEffect, useState } from 'react';

import { hooks } from '@hl-portals/libs/auth';

declare global {
  interface Window {
    _hsq: any;
  }
}

const { useUser } = hooks;

function useHubspot() {
  const [identified, setIdentified] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const user = useUser();

  if (!scriptLoaded && typeof window !== 'undefined' && !!window._hsq) {
    setScriptLoaded(true);
  }

  const identify = (email: string) => {
    if (window && window._hsq && email) {
      window._hsq.push([
        'identify',
        {
          email,
        },
      ]);
      setIdentified(true);
    }
  };

  useEffect(() => {
    if (user?.email && !identified && scriptLoaded) {
      identify(user.email);
    }
  }, [user, identified, scriptLoaded]);

  const trackEvent = ({
    name,
    properties = {},
  }: {
    name: string;
    properties?: any;
  }) => {
    if (!identified) {
      console.warn(
        `User not yet identified, tracked event ${name} may not be associated with the user`
      );
    }

    if (window && window._hsq) {
      window._hsq.push([
        'trackCustomBehavioralEvent',
        {
          name,
          properties,
        },
      ]);
    }
  };

  const trackPageView = () => {
    if (window && window._hsq) {
      window._hsq.push(['trackPageView']);
    }
  };

  return {
    identify,
    trackEvent,
    trackPageView,
  };
}

export default useHubspot;
