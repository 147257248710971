import React, {
  ChangeEventHandler,
  ClipboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { SlideDown } from '../../Animations';
import { Box, BoxTypes } from '../../Box';
import { InputError } from '../components/input-error';

export type PinInputProps = {
  length: number;
  name: string;
  testId?: string;
} & BoxTypes;

export const PinInput = ({
  length = 1,
  name,
  testId,
  ...props
}: PinInputProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const { setValue, formState } = useFormContext();
  const error = formState.errors[name];

  const inputRefs = Array(length)
    .fill(0)
    .map(() => React.createRef<HTMLInputElement>());

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const index = e.target.getAttribute('data-index') as string;

    const singleValue = value.length > 1 ? value[0] : value;
    const numberValue = isNaN(+singleValue) ? '' : singleValue;

    e.target.value = numberValue;

    setInputValue((prev) => {
      const prevArray = prev.split('');
      prevArray[+index] = numberValue;
      return prevArray.join('');
    });

    if (value === '') {
      setCurrentIndex((prev) => {
        if (+index - 1 >= 0) return +index - 1;
        return prev;
      });
      return;
    }
    if (+index < inputRefs.length - 1 && numberValue) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const onPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData('text/plain');
    const pasteDataArray = pasteData.split('');

    setInputValue(pasteData);

    inputRefs.forEach((input, i) => {
      if (input?.current && pasteDataArray[i]) {
        input.current.value = pasteDataArray[i];
      }
    });
  };

  useEffect(() => {
    inputRefs[currentIndex]?.current?.focus();
  }, [inputRefs, currentIndex]);

  useEffect(() => {
    setValue(name, inputValue);
  }, [inputValue, setValue, name]);

  return (
    <Box flexDirection="column" {...props}>
      <Box gap={{ xs: '4px', sm: '20px' }}>
        {inputRefs.map((ref, i) => (
          <Box
            key={i}
            width={{ xs: '56px', sm: '64px' }}
            height={{ xs: '56px', sm: '64px' }}
            border="2px solid #DBDFE6"
            borderRadius="12px"
            data-index={i}
            data-test={`${testId}-${i}`}
            as="input"
            ref={ref}
            onChange={onChange}
            onPaste={onPaste}
            padding={{ xs: '12px', sm: '24px' }}
            fontSize={'20px'}
            textAlign="center"
          />
        ))}
      </Box>
      <Box justifyContent={'center'}>
        {!!error && (
          <SlideDown>
            <InputError error={error} />
          </SlideDown>
        )}
      </Box>
    </Box>
  );
};
