import styled, { css } from 'styled-components';

import { paragraphHelper } from '@hl-portals/helpers';

export const StepCircle = styled.div`
  ${({ theme }) => css`
    width: 27px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: ${theme.colors.electricBlue};
    border: 1px solid ${theme.colors.electricBlue};
    ${paragraphHelper({ type: 'text', theme })}
    color: ${theme.colors.white};
  `}
`;

export const StepContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 12px 12px 12px 36px;
  box-sizing: border-box;
  &::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 13px;
    bottom: 12px;
    width: 2px;
    background: ${({ theme }) => theme.colors.coolGray6};
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-child ${StepContent}::before {
    content: normal;
  }
`;
