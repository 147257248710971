import styled, { css } from 'styled-components';

import { media } from '@hl-portals/helpers';

import { theme } from '../../../../constants/src/theme';
import { Box } from '../Box';
import { Icon } from '../Icon';

interface Props {
  open: boolean;
}

export const AngleIcon = styled(Icon)<{ open: boolean }>`
  transition: transform 0.2s ease-out;

  ${({ open }: { open: boolean }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `};
`;

export const NavbarWrapper = styled.nav`
  align-items: center;
  align-self: stretch;
  background: #ffffff;
  box-shadow: 0 3px 2px -2px ${theme.colors.coolGray4};
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex: none;
  height: 72px;
  width: 100%;
  padding: 16px 80px;
  position: static;

  ${media.untilTablet`
    padding: 16px;
  `}
`;

export const Logo = styled.img`
  display: inline-block;
  vertical-align: middle;
  max-width: 152px;
  height: auto;
`;

export const NavbarDropdownWrapper = styled(Box)<Props>`
  position: absolute;
  right: 0px;
  top: 0px;
  transition: all 0.2s ease-out;
  z-index: -1;

  ${({ open }) =>
    open &&
    css`
      z-index: 1;
    `};
`;

export const NavbarContent = styled(Box)<Props>`
  background: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 14px 50px ${theme.colors.coolGray3};
  margin-top: 20px;
  max-width: 250px;
  min-width: max-content;
  opacity: 0;
  padding: 10px;
  transition: all 0.2s ease-out;

  ${({ open }: { open: boolean }) =>
    open &&
    css`
      opacity: 1;
      transform: translateY(20px);
    `};
`;
