import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { isValidUrl } from '@hl-portals/helpers';

const validateUrl = (url: string): boolean => {
  // allows valid urls or relative paths
  return !!isValidUrl(url) || url[0] === '/';
};

// custom hook to redirect to a given url based on the `return` query param
// assumes that the next router calls decodeURIComponent on query params
function useReturnUrl(): { onDone: () => void; returnUrl: string } {
  const router = useRouter();
  const [returnUrl, setReturnUrl] = useState('');

  const onDone = () => {
    if (returnUrl) window.location.href = returnUrl;
  };

  useEffect(() => {
    if (router.query?.return && validateUrl(router.query?.return as string)) {
      setReturnUrl(router.query?.return as string);
    }
  }, [router]);

  return { onDone, returnUrl };
}

export default useReturnUrl;
