import styled from 'styled-components';

import { useIntersectionObserver } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';

export const FadeIn = styled(Box)<{ animationDelay?: string }>`
  ${({ animationDelay }) => `
    @keyframes fade-in {
      0% {
        transform: translateY(-5px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    
    opacity: 0;
    animation: fade-in .5s ease-out forwards;
    animation-delay: ${animationDelay || '.5s'}
  `}
`;

export const FadeInWhenInViewComponent = styled(Box)<{
  animationDelay?: string;
  animate: boolean;
}>`
  ${({ animate, animationDelay }) => `
    @keyframes fade-in {
      0% {
        transform: translateY(-5px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    opacity: 0;
    ${animate ? `animation: fade-in 0.5s ease-out forwards;` : ''}
    animation-delay: ${animationDelay || '.5s'}
  `}
`;

export const FadeInWhenInView = ({
  children,
  ...props
}: BoxTypes & { animationDelay?: string }) => {
  const { ref, isIntersecting } = useIntersectionObserver<HTMLDivElement>({});

  return (
    <FadeInWhenInViewComponent ref={ref} animate={isIntersecting} {...props}>
      {children}
    </FadeInWhenInViewComponent>
  );
};

export const PopIn = styled(Box)<{
  animationDelay?: string;
  duration?: string;
}>`
  ${({ animationDelay, duration }) => `
    @keyframes pop-in {
      0% {
        transform: scale(.8);
        opacity: 0;
      }
      60% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    
    opacity: 0;
    animation: pop-in ${duration || '.5s'} ease-out forwards;
    animation-delay: ${animationDelay || '.5s'}
  `}
`;

export const SlideDown = styled(Box)<{ top?: string }>`
  ${({ top = '40px' }) => `
    @keyframes slide-down {
      0% {
        max-height: 0;
        opacity: 0;
        top: 0;
      }
      100% {
        max-height: 1000px;
        opacity: 1;
        top: ${top};
      }
    }

    max-height: 0;
    overflow: hidden;
    opacity: 0;
    animation slide-down 200ms ease-in-out forwards;    
  `}
`;

export const SlideFromRight = styled(Box)<{ right?: string }>`
  ${({ right = '40px' }) => `
    @keyframes slide-right {
      0% {
        transform: scaleX(0);
        opacity: 0;
        right: 0;
      }
      100% {
        transform: scaleX(1);
        opacity: 1;
        right: ${right};
      }
    }

    transform: scaleX(0);
    overflow: hidden;
    opacity: 0;
    animation slide-right 200ms ease-in-out forwards;    
  `}
`;

export const SlideFromLeft = styled(Box)<{ left?: string; delay?: string }>`
  ${({ left = '40px', delay = '0' }) => `
    @keyframes slide-left {
      0% {
        transform: scaleX(0);
        opacity: 0;
        left: 0;
      }
      50% {
        opacity: 0;
      } 
      100% {
        transform: scaleX(1);
        opacity: 1;
        left: ${left};
      }
    }

    transform: scaleX(0);
    transform-origin: left;
    overflow: hidden;
    opacity: 0;
    animation slide-left 200ms ease-in-out forwards;    
    animation-delay: ${delay};
  `}
`;
