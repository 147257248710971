import { Box } from '../../Box';
import { IconProps } from '../types';

export const House3Icon = ({ containerProps = {} }: IconProps) => {
  return (
    <Box {...containerProps}>
      <svg
        width="362"
        height="280"
        viewBox="0 0 362 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6976_67553)">
          <path
            d="M89.7323 109.725L75.8689 113.186C75.4203 111.36 75.3252 109.466 75.597 107.598C75.8689 105.731 76.5213 103.946 77.4863 102.338C78.4513 100.73 79.7289 99.3258 81.2376 98.2083C82.7462 97.0907 84.4588 96.2866 86.2801 95.8232L89.7323 109.725Z"
            fill="#008CE7"
          />
          <path
            d="M10.207 279.006H187.632V165.97L94.4073 91.98L10.207 165.97V279.006Z"
            fill="#E9F6FF"
          />
          <path
            d="M187.646 279.006H310.08V172.294H187.646V279.006Z"
            fill="#C7DEEE"
          />
          <path
            opacity="0.8"
            d="M35.7461 205.711V279.007H42.1749H72.1717H79.8646V205.711H35.7461Z"
            fill="#B8D0E7"
          />
          <path
            d="M10.2082 178.222L94.4085 110.024L187.634 189.739V172.294L94.4085 91.98L5.11133 164.321L10.2082 178.222Z"
            fill="#C7DEEE"
          />
          <path
            d="M221.218 81.8813H94.4076L85.043 89.6497L0 162.972V178.223H10.2073V169.473L94.2309 97.5135L187.633 177.527L316.617 177.868V165.97L221.218 81.8813Z"
            fill="#D4E6F0"
          />
          <path
            opacity="0.8"
            d="M108.678 205.766H98.5254V243.218V251.545H108.678H130.833V243.218V205.766H108.678Z"
            fill="#B8D0E7"
          />
          <path
            opacity="0.8"
            d="M145.907 205.766H135.768V243.218V251.545H145.907H168.075V243.218V205.766H145.907Z"
            fill="#B8D0E7"
          />
          <path
            d="M361.974 278.843H276.55C276.36 275.681 276.305 272.506 276.903 269.398C277.624 265.623 279.445 261.902 282.585 259.708C285.711 257.5 290.278 257.132 293.295 259.49C296.04 261.643 296.951 265.378 297.304 268.867C298.188 256.901 302.959 245.712 311.358 237.166C313.152 235.34 315.137 233.609 317.515 232.642C319.867 231.66 322.694 231.51 324.923 232.764C328.13 234.563 329.272 238.611 329.557 242.277C330.291 251.844 327.179 261.235 324.094 270.311C326.445 263.947 329.965 258.836 335.579 255.047C338.868 252.839 343.108 251.218 346.724 252.839C350.067 254.338 351.793 258.264 351.753 261.943C351.698 265.609 350.244 269.098 348.735 272.437C350.081 270.161 352.31 269.562 354.933 269.862C357.57 270.161 359.935 271.947 361.104 274.332C361.783 275.736 362.082 277.303 361.974 278.843Z"
            fill="#B8D0E7"
          />
          <path
            d="M251.092 279.551H325.819C324.065 274.522 321.537 269.752 317.813 265.977C313.219 261.344 306.614 258.4 300.171 259.354C291.364 260.648 284.921 268.676 281.918 277.071C278.289 270.543 270.093 267.299 262.862 269.098C257.561 270.407 253.144 274.509 251.092 279.538V279.551Z"
            fill="#E1EEF5"
          />
          <path
            d="M96.5 3C81.3727 3 69 15.1627 69 30.013C69 52.0759 96.5 80 96.5 80C96.5 80 124 52.0759 124 30.013C124 15.1475 111.781 3 96.5 3Z"
            fill="#BBD3EA"
          />
          <rect x="83" y="17" width="26" height="27" rx="13" fill="#E8EEF9" />
          <rect
            x="87"
            y="21.1538"
            width="18"
            height="18.6923"
            rx="9"
            fill="#FEEBEA"
          />
          <path
            d="M95.8887 44.4848C103.675 44.4848 109.989 38.1737 109.989 30.3848C109.989 22.6004 103.675 16.2848 95.8887 16.2848C88.102 16.2848 81.7887 22.6004 81.7887 30.3848C81.7887 38.1737 88.102 44.4848 95.8887 44.4848Z"
            fill="white"
            stroke="white"
            stroke-width="0.2"
          />
          <path
            d="M93.293 35.8039C93.293 34.3697 94.4556 33.2071 95.8897 33.2071C97.3239 33.2071 98.4865 34.3697 98.4865 35.8039C98.4865 37.238 97.3239 38.4006 95.8897 38.4006C94.4556 38.4006 93.293 37.238 93.293 35.8039Z"
            fill="#9BB0C9"
          />
          <path
            d="M93.8431 31.5504L93.4243 23.873C93.4032 23.485 93.7121 23.1587 94.1007 23.1587H97.6788C98.0674 23.1587 98.3763 23.485 98.3552 23.873L97.9364 31.5504C97.9168 31.9097 97.6198 32.1909 97.26 32.1909H94.5195C94.1597 32.1909 93.8627 31.9097 93.8431 31.5504Z"
            fill="#9BB0C9"
          />
        </g>
        <defs>
          <clipPath id="clip0_6976_67553">
            <rect width="362" height="279.552" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
