import { Box } from '../../Box';
import { useBrandTheme } from '../../BrandTheme';
import { getActualColors } from '../helpers';
import { IconColors, IconProps } from '../types';

export const House2Icon = ({
  colors,
  width = '266',
  height = '239',
  containerProps = {},
}: IconProps) => {
  const brandCtx = useBrandTheme();

  const defaultColors: IconColors = {
    primary: brandCtx?.colors?.primary || '#9BB0C9',
    secondary: brandCtx?.colors?.secondary || '#F6AB98',
  };

  const actualColors = getActualColors(colors, defaultColors);

  return (
    <Box
      as="svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...containerProps}
    >
      <g clipPath="url(#clip0_3948_1469)">
        <path
          d="M65.8495 114.51L55.6495 117.05C55.3195 115.71 55.2495 114.32 55.4495 112.95C55.6495 111.58 56.1295 110.27 56.8395 109.09C57.5495 107.91 58.4895 106.88 59.5995 106.06C60.7095 105.24 61.9695 104.65 63.3095 104.31L65.8495 114.51Z"
          fill="#008CE7"
        />
        <path
          d="M7.33984 238.72H137.88V155.78L69.2898 101.49L7.33984 155.78V238.72Z"
          fill="#E9F6FF"
        />
        <path
          d="M137.891 238.72H227.971V160.42H137.891V238.72Z"
          fill="#C7DEEE"
        />
        <path
          opacity="0.8"
          d="M26.1289 184.94V238.72H30.8589H52.9289H58.5889V184.94H26.1289Z"
          fill="#B8D0E7"
        />
        <path
          d="M7.33984 164.77L69.2898 114.73L137.88 173.22V160.42L69.2898 101.49L3.58984 154.57L7.33984 164.77Z"
          fill="#C7DEEE"
        />
        <path
          d="M162.59 94.08H69.2901L62.4001 99.78L-0.169922 153.58V164.77H7.34008V158.35L69.1601 105.55L137.88 164.26L232.78 164.51V155.78L162.59 94.08Z"
          fill="#D4E6F0"
        />
        <path
          d="M72.87 38C63.04 38 55 46.01 55 55.79C55 70.32 72.87 88.71 72.87 88.71C72.87 88.71 90.74 70.32 90.74 55.79C90.74 46 82.8 38 72.87 38Z"
          fill={actualColors.primary || '#9BB0C9'}
        />
        <path
          d="M72.8701 63.3599C77.4044 63.3599 81.0802 59.6842 81.0802 55.1499C81.0802 50.6157 77.4044 46.9399 72.8701 46.9399C68.3359 46.9399 64.6602 50.6157 64.6602 55.1499C64.6602 59.6842 68.3359 63.3599 72.8701 63.3599Z"
          fill="white"
        />
        <path
          d="M129.03 58.76C129.71 58.08 129.71 56.96 129.03 56.28L120.26 47.51C119.58 46.83 118.46 46.83 117.78 47.51C117.1 48.19 117.1 49.31 117.78 49.99L123.56 55.77H106.75C105.78 55.77 105 56.55 105 57.52C105 58.49 105.78 59.27 106.75 59.27H123.56L117.79 65.05C117.11 65.73 117.11 66.85 117.79 67.53C118.47 68.21 119.59 68.21 120.27 67.53L129.04 58.76H129.03Z"
          fill="#F6AB98"
        />
        <path
          opacity="0.8"
          d="M79.7903 184.98H72.3203V212.46V218.57H79.7903H96.0903V212.46V184.98H79.7903Z"
          fill="#B8D0E7"
        />
        <path
          opacity="0.8"
          d="M107.181 184.98H99.7207V212.46V218.57H107.181H123.491V212.46V184.98H107.181Z"
          fill="#B8D0E7"
        />
        <path
          d="M266.151 238.6H203.301C203.161 236.28 203.121 233.95 203.561 231.67C204.091 228.9 205.431 226.17 207.741 224.56C210.041 222.94 213.401 222.67 215.621 224.4C217.641 225.98 218.311 228.72 218.571 231.28C219.221 222.5 222.731 214.29 228.911 208.02C230.231 206.68 231.691 205.41 233.441 204.7C235.171 203.98 237.251 203.87 238.891 204.79C241.251 206.11 242.091 209.08 242.301 211.77C242.841 218.79 240.551 225.68 238.281 232.34C240.011 227.67 242.601 223.92 246.731 221.14C249.151 219.52 252.271 218.33 254.931 219.52C257.391 220.62 258.661 223.5 258.631 226.2C258.591 228.89 257.521 231.45 256.411 233.9C257.401 232.23 259.041 231.79 260.971 232.01C262.911 232.23 264.651 233.54 265.511 235.29C266.011 236.32 266.231 237.47 266.151 238.6Z"
          fill="#B8D0E7"
        />
        <path
          d="M184.57 239.12H239.55C238.26 235.43 236.4 231.93 233.66 229.16C230.28 225.76 225.42 223.6 220.68 224.3C214.2 225.25 209.46 231.14 207.25 237.3C204.58 232.51 198.55 230.13 193.23 231.45C189.33 232.41 186.08 235.42 184.57 239.11V239.12Z"
          fill="#E1EEF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3948_1469">
          <rect width="266" height="239" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
};
