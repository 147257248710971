/* eslint-disable react-hooks/exhaustive-deps */
import { useFlags } from 'flagsmith/react';

import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { PROPERTY_SERVICE_SEARCH_API, bbox } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import useDebounce from '../useDebounce';
import useMapboxPlaces from '../useMapboxPlaces';

export type PropertySuggestion = {
  attributes: {
    city: string;
    display_address: string;
    postal_code: string;
    state_or_province: string;
    hl_full_address: string;
    street_number: string;
    street_name: string;
  };
  id: string;
  type: 'property_suggestion';
};

type UsePropertyAutocompleteOptions = {
  bboxState?: keyof typeof bbox;
  filterBy?: {
    property: keyof PropertySuggestion['attributes'];
    value: string;
  };
};

const PROPERTY_FLAG_KEY = 'property-autocomplete';

export const usePropertyAutocomplete = (
  search: string,
  options?: UsePropertyAutocompleteOptions
) => {
  const debouncedSearch = useDebounce(search, 500);
  const url = PROPERTY_SERVICE_SEARCH_API(debouncedSearch);

  const {
    [PROPERTY_FLAG_KEY]: { enabled: isPropertyAutocompleteEnabled },
  } = useFlags([PROPERTY_FLAG_KEY]);

  const {
    data: propertyData,
    isLoading,
    refetch,
  } = useQuery<PropertySuggestion[]>(
    [url, debouncedSearch],
    async () => {
      const res = await enhancedAxios({ url });
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!debouncedSearch?.length && isPropertyAutocompleteEnabled,
    }
  );

  const bboxData = options?.bboxState ? bbox[options.bboxState] : null;

  const { data: mapboxData, isLoading: isMapboxLoading } = useMapboxPlaces(
    debouncedSearch,
    {
      bbox: bboxData?.bbox || undefined,
      filterByStateCode: bboxData?.filterByStateCode || undefined,
      enabled: !isPropertyAutocompleteEnabled,
    }
  );

  const formattedData: PropertySuggestion[] = useMemo(() => {
    if (propertyData) {
      if (options?.filterBy) {
        const { property, value } = options.filterBy;
        return propertyData.filter((pd) => pd.attributes[property] === value);
      }
      return propertyData;
    }

    if (mapboxData) {
      return mapboxData.reduce((list, mdata) => {
        const { number, city, fullAddress, zipCode, stateCode, street } = mdata;

        if (!number) return list;

        const addressNumber = number ? `${number} ` : '';
        const address = addressNumber + street;

        return [
          ...list,
          {
            attributes: {
              city: city,
              display_address: fullAddress,
              postal_code: zipCode,
              state_or_province: stateCode,
              hl_full_address: address,
              street_number: number,
              street_name: street,
            },
            id: fullAddress,
            type: 'property_suggestion',
          } as PropertySuggestion,
        ];
      }, [] as PropertySuggestion[]);
    }

    return [];
  }, [propertyData, mapboxData]);

  return {
    data: formattedData,
    isLoading: isLoading || isMapboxLoading,
    refetch,
  };
};
