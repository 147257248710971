import styled, { css } from 'styled-components';

import { useEffect } from 'react';

import { theme } from '@hl-portals/constants';

import { useDrawer } from '@hl-portals/hooks';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';
import ProgressItem from './ProgressItem';

export type ProgressGroupProps = {
  group: TransactionProgressParentStep;
  active: boolean;
  onToggle: () => void;
  num: number;
};

const Number = styled(Box)<{ completed: boolean; started: boolean }>`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border: 1px solid ${theme.colors.coolGray4};
  color: ${theme.colors.coolGray3};

  ${({ completed, started }) =>
    (completed || started) &&
    css`
      background-color: ${theme.colors.electricBlue};
      border-color: ${theme.colors.electricBlue};
      color: ${theme.colors.white};
    `};
`;

const AngleIcon = styled(Icon)<{ open: boolean }>`
  transition: all 0.2s ease-out;
  transform: rotate(0deg);

  ${({ open }: { open: boolean }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `};
`;

const ProgressGroup = ({
  group,
  num,
  active,
  onToggle,
  ...extraProps
}: ProgressGroupProps): React.ReactElement => {
  const completed = group.sub_steps.every((item) => item.complete);
  const started = group.sub_steps.some((item) => item.complete) || num === 1;
  const { isDrawerOpen, toggleDrawer, Drawer } = useDrawer(
    (ref) => (
      <Box ref={ref} flexDirection="column">
        {group.sub_steps.map((item) => (
          <ProgressItem key={item.title} {...item} />
        ))}
      </Box>
    ),
    { shouldStartOpen: active }
  );

  useEffect(() => {
    const closing = isDrawerOpen && !active;
    const opening = !isDrawerOpen && active;

    if (opening || closing) toggleDrawer();
  }, [isDrawerOpen, toggleDrawer, active]);

  return (
    <Box flexDirection="column" mb="32px" {...extraProps}>
      <Box
        onClick={onToggle}
        data-test={`progress-group-${num}`}
        cursor="pointer"
        alignItems="center"
        justifyContent="space-between"
        mb={isDrawerOpen ? '32px' : 0}
      >
        <Box alignItems="center">
          <Number
            borderRadius="50%"
            color={theme.colors.white}
            completed={completed}
            started={started}
            alignItems="center"
            justifyContent="center"
          >
            {completed ? <Icon type="check" color="white" size={12} /> : num}
          </Number>
          <Paragraph variant="heading-5" color="darkBlue" ml="16px">
            {group.title}
          </Paragraph>
        </Box>
        <AngleIcon type="angleDown" open={isDrawerOpen} size={16} />
      </Box>
      {Drawer}
    </Box>
  );
};

export default ProgressGroup;
