import { useEffect } from 'react';

import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { utils as authUtils } from '@hl-portals/libs/auth';

import { config } from '@hl-portals/helpers';

export const useAuthBoundary = async () => {
  const { data: session } = useSession();
  const router = useRouter();

  useEffect(() => {
    const skipAuth =
      config.publicHosts && config.publicHosts.includes(window.location.host);

    if (
      typeof session !== 'undefined' &&
      !session?.user &&
      authUtils.sessionAuthRequired(router.query, router?.pathname) &&
      router?.pathname !== '/logout' &&
      !skipAuth
    ) {
      signIn('homelight', {
        callbackUrl: authUtils.getSigninCallbackUrl(window?.location),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);
};
