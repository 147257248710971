export const UsersIcon = () => (
  <svg
    width="259"
    height="179"
    viewBox="0 0 259 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9662_17235)">
      <path
        d="M217.489 0H9.44343C4.22796 0 0 4.23613 0 9.46167V79.8194C0 85.0449 4.22796 89.2811 9.44343 89.2811H217.489C222.705 89.2811 226.933 85.0449 226.933 79.8194V9.46167C226.933 4.23613 222.705 0 217.489 0Z"
        fill="white"
      />
      <path
        d="M146.411 31.5847H87.6719V44.8356H146.411V31.5847Z"
        fill="#BBD3EA"
      />
      <path
        d="M203.553 50.7532H87.6719V64.0041H203.553V50.7532Z"
        fill="#E9F6FF"
      />
      <path
        d="M44.941 77.745C63.1867 77.745 77.9777 62.9254 77.9777 44.6445C77.9777 26.3635 63.1867 11.5439 44.941 11.5439C26.6953 11.5439 11.9043 26.3635 11.9043 44.6445C11.9043 62.9254 26.6953 77.745 44.941 77.745Z"
        fill="#E8F5FD"
      />
      <path
        d="M44.9411 77.7448C53.5746 77.7448 61.4365 74.4225 67.3272 68.9874C62.8118 62.6031 53.865 61.8835 51.8556 60.7812C48.9905 59.2195 49.7698 51.6487 49.7698 51.6487L45.4836 51.9243V51.6487L45.0251 51.7865L44.5667 51.6487V51.9243L40.2805 51.6487C40.2805 51.6487 41.0598 59.2119 38.1947 60.7812C36.17 61.8835 27.1468 62.6031 22.6543 69.0793C28.5297 74.4608 36.3534 77.7448 44.9411 77.7448Z"
        fill="#F2B099"
      />
      <path
        d="M39.6777 58.8291C43.1006 58.3162 46.5235 57.788 49.9463 57.2445C49.465 54.6342 49.7706 51.6487 49.7706 51.6487L45.4844 51.9243V51.6487L45.026 51.7865L44.5675 51.6487V51.9243L40.2813 51.6487C40.2813 51.6487 40.7321 56.0427 39.6777 58.8291Z"
        fill="#DD9585"
      />
      <path
        d="M36.6359 40.5794C35.7955 37.9537 33.7937 38.5661 33.6103 38.6274C33.4117 38.6504 31.3488 38.9566 31.7079 41.6894C32.067 44.4376 33.6562 45.9303 35.1537 45.4863C36.6894 45.2414 37.4916 43.2128 36.6435 40.5794H36.6359Z"
        fill="#EDA18C"
      />
      <path
        d="M53.4829 40.5794C54.3233 37.9537 56.3251 38.5661 56.5084 38.6274C56.7071 38.6504 58.77 38.9566 58.4109 41.6894C58.0518 44.4376 56.4626 45.9303 54.9651 45.4863C53.4294 45.2414 52.6272 43.2128 53.4752 40.5794H53.4829Z"
        fill="#EDA18C"
      />
      <path
        d="M34.5889 36.6066C35.6356 25.1087 44.3608 25.7517 45.1783 25.8435C46.0035 25.7593 54.7211 25.1163 55.7678 36.6066C56.8222 48.1734 51.6802 55.7289 45.1783 55.3079C38.6764 55.7213 33.5345 48.1734 34.5889 36.6066Z"
        fill="#F2B099"
      />
      <path
        d="M42.3962 24.9861C42.3962 24.9861 38.1941 18.1884 42.2052 17.0784C46.2164 15.9684 48.8905 16.1062 49.3642 19.4362C49.8379 22.7661 48.0272 26.785 48.0272 26.785L42.3962 24.9784V24.9861Z"
        fill="#2B241E"
      />
      <path
        d="M42.3962 24.9861C42.3962 24.9861 38.1941 18.1884 42.2052 17.0784C46.2164 15.9684 48.8905 16.1062 49.3642 19.4362C49.8379 22.7661 48.0272 26.785 48.0272 26.785L42.3962 24.9784V24.9861Z"
        fill="#604532"
      />
      <path
        d="M41.502 26.5401C41.502 27.9486 43.2516 29.0892 45.4062 29.0892C47.5607 29.0892 49.3104 27.9486 49.3104 26.5401C49.3104 25.1316 47.5607 23.991 45.4062 23.991C43.2516 23.991 41.502 25.1316 41.502 26.5401Z"
        fill="#46B6FF"
      />
      <path
        d="M55.5679 29.5638C52.8632 25.6674 45.0701 25.0703 45.0701 25.0703C45.0701 25.0703 37.4374 24.8789 34.7633 29.8547C32.4254 34.2028 34.5036 44.6137 34.5036 44.6137H35.6267L35.3593 38.2753C35.3593 38.2753 35.3593 38.2753 35.3746 38.2753C35.5044 38.3136 35.9094 38.3901 37.2159 38.505C37.384 36.6295 38.9961 33.5139 40.6158 31.9675C40.6158 31.9675 39.1107 35.7951 38.9197 38.6198C41.2194 38.8112 43.8324 39.0025 47.1177 39.0025C47.6755 37.4103 47.8818 34.1875 47.5456 31.8833C47.5303 31.8144 47.515 31.7455 47.4997 31.6843C47.7213 31.8068 47.8588 31.8833 47.8588 31.8833C47.8588 31.8833 49.9599 35.8333 49.6237 39.0025H52.2062C52.2062 39.0025 54.0551 39.1097 55.1248 38.9566L54.4295 45.073H55.4533C55.4533 45.073 58.2726 33.4756 55.5679 29.5791V29.5638Z"
        fill="#2B241E"
      />
      <path
        d="M55.5679 29.5638C52.8632 25.6674 45.0701 25.0703 45.0701 25.0703C45.0701 25.0703 37.4374 24.8789 34.7633 29.8547C32.4254 34.2028 34.5036 44.6137 34.5036 44.6137H35.6267L35.3593 38.2753C35.3593 38.2753 35.3593 38.2753 35.3746 38.2753C35.5044 38.3136 35.9094 38.3901 37.2159 38.505C37.384 36.6295 38.9961 33.5139 40.6158 31.9675C40.6158 31.9675 39.1107 35.7951 38.9197 38.6198C41.2194 38.8112 43.8324 39.0025 47.1177 39.0025C47.6755 37.4103 47.8818 34.1875 47.5456 31.8833C47.5303 31.8144 47.515 31.7455 47.4997 31.6843C47.7213 31.8068 47.8588 31.8833 47.8588 31.8833C47.8588 31.8833 49.9599 35.8333 49.6237 39.0025H52.2062C52.2062 39.0025 54.0551 39.1097 55.1248 38.9566L54.4295 45.073H55.4533C55.4533 45.073 58.2726 33.4756 55.5679 29.5791V29.5638Z"
        fill="#604532"
      />
      <path
        d="M56.4769 45.5628C56.8524 45.5628 57.1568 45.2578 57.1568 44.8815C57.1568 44.5052 56.8524 44.2002 56.4769 44.2002C56.1013 44.2002 55.7969 44.5052 55.7969 44.8815C55.7969 45.2578 56.1013 45.5628 56.4769 45.5628Z"
        fill="#FFDA6B"
      />
      <path
        d="M33.6487 45.5628C34.0243 45.5628 34.3287 45.2578 34.3287 44.8815C34.3287 44.5052 34.0243 44.2002 33.6487 44.2002C33.2732 44.2002 32.9688 44.5052 32.9688 44.8815C32.9688 45.2578 33.2732 45.5628 33.6487 45.5628Z"
        fill="#FFDA6B"
      />
      <path
        d="M47.2779 49.3523C47.2779 49.3523 47.2703 49.3753 47.2473 49.4059C47.2244 49.4518 47.2015 49.4977 47.1556 49.5513C47.1556 49.559 47.148 49.5743 47.1251 49.5896C47.0869 49.6355 47.0487 49.6891 47.0105 49.7427C46.9417 49.8346 46.8577 49.9264 46.7507 50.0336C46.4069 50.3551 45.9103 50.6613 45.2074 50.646C45.1233 50.646 45.0393 50.646 44.9476 50.623C44.8941 50.6307 44.833 50.6383 44.7795 50.6383C44.0842 50.6537 43.5876 50.3475 43.2438 50.0336C43.1368 49.9341 43.0528 49.8346 42.9764 49.7427C42.9382 49.6891 42.9 49.6355 42.8618 49.5896C42.8465 49.5743 42.8312 49.559 42.8312 49.5513C42.7854 49.4977 42.7548 49.4518 42.7395 49.4135C42.7243 49.3753 42.709 49.3523 42.709 49.3523C42.709 49.3523 42.7141 49.3523 42.7243 49.3523H42.7319C42.8007 49.3676 42.9535 49.3982 43.1674 49.4365H43.1903C43.282 49.4518 43.3966 49.4748 43.5112 49.4901C43.7328 49.5284 43.9544 49.5666 44.1759 49.5896C44.3975 49.6202 44.6038 49.6432 44.7566 49.6432C44.8406 49.6432 44.9323 49.6432 45.0316 49.6432C45.1004 49.6585 45.1615 49.6585 45.2226 49.6585C45.3907 49.6585 45.6276 49.6279 45.8797 49.5973C46.0784 49.5743 46.277 49.536 46.468 49.5054C46.5826 49.4901 46.6972 49.4671 46.7889 49.4518H46.8042C47.0258 49.4135 47.1709 49.3829 47.2397 49.3676H47.2473C47.2473 49.3676 47.255 49.3676 47.2626 49.3676L47.2779 49.3523Z"
        fill="#591E1E"
      />
      <path
        d="M46.2233 49.2986H43.5645V49.712H46.2233V49.2986Z"
        fill="#591E1E"
      />
      <path
        d="M46.751 50.0335C46.4072 50.355 45.9106 50.6612 45.2077 50.6459C45.1237 50.6459 45.0396 50.6459 44.9479 50.6229C44.8944 50.6306 44.8333 50.6382 44.7798 50.6382C44.0846 50.6535 43.588 50.3473 43.2441 50.0335C43.5116 49.8268 43.8248 49.6584 44.1839 49.5895C44.2297 49.5818 44.2679 49.5742 44.3138 49.5665C44.5583 49.5206 44.7569 49.5129 44.9403 49.5206C45.1389 49.4976 45.3681 49.5053 45.6738 49.5512C45.7425 49.5589 45.8189 49.5742 45.88 49.5895C46.2162 49.666 46.5066 49.8268 46.751 50.0335Z"
        fill="#AF3C3D"
      />
      <path
        d="M47.2635 49.3062C47.0802 49.3062 46.5453 48.9694 45.7355 48.9234C45.6514 48.9234 45.5521 48.9694 45.4604 49.0383C45.1777 49.2296 44.8263 49.222 44.5436 49.0383C44.4443 48.9694 44.3449 48.9158 44.2456 48.9158C43.4434 48.9617 42.9009 49.2985 42.7175 49.2985C42.687 49.2985 42.6717 49.2985 42.6641 49.2909C42.6641 49.2909 42.6641 49.2985 42.6717 49.3062C42.6793 49.3138 42.7023 49.3368 42.7099 49.3521C42.7099 49.3521 42.715 49.3547 42.7252 49.3598H42.7328C42.7328 49.3598 42.8016 49.421 42.8474 49.4363C42.8856 49.4669 42.9238 49.4822 42.962 49.4899C43.1301 49.5358 43.3211 49.5358 43.5121 49.5282C43.7948 49.5282 44.0699 49.4976 44.2532 49.5741C44.4213 49.643 44.6964 49.6736 44.9485 49.6736C45.2236 49.6736 45.5368 49.6583 45.7278 49.5818C45.9112 49.5052 46.1862 49.5358 46.4689 49.5358C46.6599 49.5435 46.851 49.5435 47.019 49.4976C47.0572 49.4899 47.0954 49.4669 47.1336 49.444C47.1795 49.4363 47.2177 49.4057 47.2483 49.3674H47.2559C47.2559 49.3674 47.2635 49.3598 47.2788 49.3598C47.2865 49.3445 47.3094 49.3215 47.317 49.3138C47.3247 49.3062 47.3247 49.2985 47.3247 49.2985C47.317 49.3062 47.2941 49.3062 47.2712 49.3062H47.2635Z"
        fill="#D87374"
      />
      <path
        d="M47.0176 49.467C47.0176 49.467 47.2391 49.4287 47.3232 49.2986L47.0176 49.467Z"
        fill="#D87374"
      />
      <path
        d="M47.3236 49.2985C47.3236 49.2985 47.3236 49.2985 47.3159 49.3139L47.2777 49.3521L47.0562 49.6813C47.0562 49.6813 47.0256 49.7272 47.0103 49.7502C46.6589 50.1559 45.5205 50.554 44.9551 50.5463C44.4279 50.5463 43.4347 50.1789 43.0374 49.7961C43.0144 49.7655 42.7317 49.421 42.7317 49.3445L42.6706 49.2909C42.6248 49.2526 43.1672 51.0439 44.6877 51.0669C44.8405 51.0669 45.1537 51.0975 45.3065 51.0745C47.0485 50.8602 47.3694 49.2603 47.3236 49.2985Z"
        fill="#C1636D"
      />
      <path
        d="M55.6434 61.922H55.6281L54.6349 68.9723H35.4424L34.4492 61.9143H34.4339L30.7207 62.9631L31.6605 70.6105L31.1409 74.7212C35.3431 76.658 40.0113 77.7373 44.9393 77.7373C49.8673 77.7373 54.6807 76.6197 58.9287 74.6294L58.4168 70.6028L59.3566 62.9554L55.6434 61.9143V61.922Z"
        fill="#B6E2FF"
      />
      <path
        d="M55.6454 61.9217H55.6301L54.6368 68.972H35.4444L34.4511 61.9141H34.4358L30.7227 62.9628L31.6624 70.6102L31.1429 74.721C35.345 76.6577 40.0133 77.7371 44.9413 77.7371C49.8693 77.7371 54.6827 76.6195 58.9307 74.6291L58.4188 70.6026L59.3585 62.9552L55.6454 61.9141V61.9217Z"
        fill="#BBD3EA"
      />
      <path
        d="M250.443 99.5759H66.128C61.5074 99.5759 57.7617 103.329 57.7617 107.958V170.291C57.7617 174.92 61.5074 178.673 66.128 178.673H250.443C255.064 178.673 258.81 174.92 258.81 170.291V107.958C258.81 103.329 255.064 99.5759 250.443 99.5759Z"
        fill="white"
      />
      <path
        d="M97.2928 168.168C113.3 168.168 126.277 155.166 126.277 139.128C126.277 123.09 113.3 110.088 97.2928 110.088C81.2852 110.088 68.3086 123.09 68.3086 139.128C68.3086 155.166 81.2852 168.168 97.2928 168.168Z"
        fill="#E8F5FD"
      />
      <path
        d="M97.2928 168.168C104.914 168.168 111.839 165.225 117.017 160.403C113.782 153.397 104.881 152.773 103.019 151.756C100.616 150.447 101.273 144.099 101.273 144.099L97.6786 144.33V144.099L97.2928 144.214L96.9069 144.099V144.33L93.3127 144.099C93.3127 144.099 93.9693 150.44 91.5663 151.756C89.7117 152.773 80.8039 153.397 77.5684 160.403C82.7398 165.218 89.671 168.168 97.2928 168.168Z"
        fill="#F4C19F"
      />
      <path
        d="M92.8105 150.121C95.6805 149.694 98.5505 149.247 101.421 148.792C101.014 146.608 101.278 144.099 101.278 144.099L97.6841 144.33V144.099L97.2983 144.214L96.9125 144.099V144.33L93.3182 144.099C93.3182 144.099 93.6973 147.782 92.8173 150.115L92.8105 150.121Z"
        fill="#DDAD92"
      />
      <path
        d="M97.2928 168.168C105.057 168.168 112.11 165.11 117.308 160.132C114.892 155.059 109.531 153.323 106.038 152.366C104.752 155.737 101.32 158.145 97.2928 158.145C93.2653 158.145 89.8335 155.737 88.5475 152.366C85.0547 153.329 79.6938 155.059 77.2773 160.132C82.4826 165.11 89.5289 168.168 97.2928 168.168Z"
        fill="#0C6EBA"
      />
      <path
        d="M97.2928 168.168C105.057 168.168 112.11 165.11 117.308 160.132C114.892 155.059 109.531 153.323 106.038 152.366C104.752 155.737 101.32 158.145 97.2928 158.145C93.2653 158.145 89.8335 155.737 88.5475 152.366C85.0547 153.329 79.6938 155.059 77.2773 160.132C82.4826 165.11 89.5289 168.168 97.2928 168.168Z"
        fill="#008CE7"
      />
      <path
        d="M90.2658 134.822C89.5551 132.624 87.8832 133.133 87.7275 133.187C87.565 133.207 85.8322 133.465 86.13 135.757C86.4279 138.063 87.7681 139.311 89.0136 138.945C90.2996 138.741 90.9765 137.039 90.2658 134.828V134.822Z"
        fill="#F4C19F"
      />
      <path
        d="M104.385 134.822C105.096 132.624 106.768 133.133 106.924 133.187C107.086 133.207 108.819 133.465 108.521 135.757C108.223 138.063 106.883 139.311 105.638 138.945C104.352 138.741 103.675 137.039 104.385 134.828V134.822Z"
        fill="#F4C19F"
      />
      <path
        d="M88.5461 131.492C89.426 121.854 96.7364 122.39 97.4268 122.465C98.1172 122.39 105.434 121.854 106.308 131.492C107.187 141.19 102.876 147.524 97.4268 147.171C91.9779 147.517 87.6661 141.19 88.5461 131.492Z"
        fill="#F4C19F"
      />
      <path
        d="M99.8983 142.356C98.6867 144.018 96.5816 143.862 94.957 142.309L99.8983 142.356Z"
        fill="white"
      />
      <path
        d="M106.687 124.059C106.193 122.078 105.015 121.583 104.454 121.454C104.129 116.361 90.5436 115.425 90.3676 116.402C90.178 117.439 95.1802 119.142 95.1802 119.142C95.1802 119.142 86.2183 116.782 86.6853 118.857C87.0711 120.546 90.6925 120.362 92.0327 120.234C90.1374 120.471 83.6732 121.509 86.5906 124.432C86.8275 124.669 87.0915 124.872 87.3622 125.055C87.0915 128.677 88.1609 137.195 88.1609 137.195H88.9123L88.628 131.336C90.09 131.044 90.7602 127.572 90.9835 126.025C93.4609 126.059 95.9451 125.218 96.8657 124.872C96.3106 125.13 95.4374 125.727 96.3918 126.615C97.3665 127.524 100.873 126.419 102.633 125.781C102.958 127.165 103.993 131.037 105.543 131.342L105.259 137.202H106.01C106.01 137.202 107.33 126.71 106.674 124.059H106.687Z"
        fill="#273653"
      />
      <path
        d="M106.688 124.059C106.194 122.078 105.016 121.583 104.454 121.454C104.393 120.525 103.892 119.732 103.107 119.067C102.687 122.519 100.163 125.456 96.9609 126.88C98.3418 127.212 101.137 126.324 102.647 125.774C102.972 127.158 104.007 131.03 105.557 131.336L105.273 137.195H106.024C106.024 137.195 107.344 126.704 106.688 124.052V124.059Z"
        fill="#273653"
      />
      <path
        d="M106.687 124.059C106.193 122.078 105.015 121.583 104.454 121.454C104.129 116.361 90.5436 115.425 90.3676 116.402C90.178 117.439 95.1802 119.142 95.1802 119.142C95.1802 119.142 86.2183 116.782 86.6853 118.857C87.0711 120.546 90.6925 120.362 92.0327 120.234C90.1374 120.471 83.6732 121.509 86.5906 124.432C86.8275 124.669 87.0915 124.872 87.3622 125.055C87.0915 128.677 88.1609 137.195 88.1609 137.195H88.9123L88.628 131.336C90.09 131.044 90.7602 127.572 90.9835 126.025C93.4609 126.059 95.9451 125.218 96.8657 124.872C96.3106 125.13 95.4374 125.727 96.3918 126.615C97.3665 127.524 100.873 126.419 102.633 125.781C102.958 127.165 103.993 131.037 105.543 131.342L105.259 137.202H106.01C106.01 137.202 107.33 126.71 106.674 124.059H106.687Z"
        fill="#15233A"
      />
      <path
        d="M106.688 124.059C106.194 122.078 105.016 121.583 104.454 121.454C104.393 120.525 103.892 119.732 103.107 119.067C102.687 122.519 100.163 125.456 96.9609 126.88C98.3418 127.212 101.137 126.324 102.647 125.774C102.972 127.158 104.007 131.03 105.557 131.336L105.273 137.195H106.024C106.024 137.195 107.344 126.704 106.688 124.052V124.059Z"
        fill="#15233A"
      />
      <path
        d="M187.472 127.951H135.434V139.691H187.472V127.951Z"
        fill="#BBD3EA"
      />
      <path
        d="M238.097 144.927H135.434V156.666H238.097V144.927Z"
        fill="#E9F6FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_9662_17235">
        <rect width="259" height="179" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
