import styled from 'styled-components';

import { Box, Button, Icon, SimpleLink } from '@hl-portals/ui';

import { colors } from '@hl-portals/constants';

import { useModal } from '@hl-portals/hooks';

import { Paragraph } from '../../../../ui/src/components/Typography/index';

const Item = styled(Paragraph)`
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 50%;
    background-color: ${colors.darkBlue};
  }
`;

const Title = styled(Paragraph)`
  font-size: 20px;

  @media (min-width: 500px) {
    font-size: 24px;
  }
`;

type Props = {
  items: string[];
  onContinue: () => void;
  onCancel?: () => void;
};

const PropertyEligibilityModal = ({ items, onContinue, onCancel }: Props) => {
  const { closeModal } = useModal();

  const handleCancel = () => {
    closeModal();
    if (onCancel) onCancel();
  };

  return (
    <Box
      id="existing-client-modal"
      maxWidth={{ xs: '100%', md: '628px' }}
      maxHeight="90vh"
      height="fit-content"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      p="16px"
      width="100%"
    >
      <Box flexDirection="column" gap="20px" mb="32px">
        <Box gap="8px" alignItems="center">
          <Icon type="exclamationCircle" size={32} />
          <Title variant="heading-3" color="darkBlue" as="header" my="8px">
            Property may not be eligible
          </Title>
        </Box>

        <Paragraph>
          Based on what we know, the property address may not be eligible for
          approval because of the following criteria:
        </Paragraph>
      </Box>

      <Box flexDirection="column" gap="12px" mb="32px">
        {items.map((item) => (
          <Item key={item}>{item}</Item>
        ))}
      </Box>

      <Box flexDirection="column" gap="20px" mb="32px">
        <Paragraph>
          If you believe this information is inaccurate and the property is
          still a good fit for the program, continue with the submission. If
          not, cancel.
        </Paragraph>
        <Paragraph>
          Learn more about{' '}
          <SimpleLink
            href="https://help.homelight.com/hc/en-us/articles/10316272759959-homelight-buy-before-you-sell-eligibility-requirements"
            target="_blank"
            fontSize="16px"
          >
            Buy Before You Sell Eligibility Requirements here.
          </SimpleLink>
        </Paragraph>
      </Box>

      <Box width="100%" gap="16px" justifyContent="flex-end">
        <Button
          size="large"
          variant="text"
          data-test="eligibility-continue-btn"
          onClick={() => {
            closeModal();
            onContinue();
          }}
        >
          Continue
        </Button>
        <Button size="large" onClick={handleCancel}>
          Cancel submission
        </Button>
      </Box>
    </Box>
  );
};

export default PropertyEligibilityModal;
