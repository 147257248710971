export const SendButtonIcon = (): React.ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" />
    <path
      d="M8.40507 17.3468L7.51321 16.455C7.13557 16.0773 7.13557 15.4667 7.51321 15.0931L15.319 7.28323C15.6967 6.90559 16.3073 6.90559 16.6809 7.28323L24.4868 15.0891C24.8644 15.4667 24.8644 16.0773 24.4868 16.451L23.5949 17.3428C23.2132 17.7245 22.5905 17.7164 22.2169 17.3267L17.609 12.4898V24.0358C17.609 24.5701 17.1791 25 16.6448 25H15.3592C14.8249 25 14.395 24.5701 14.395 24.0358V12.4898L9.78304 17.3308C9.40942 17.7245 8.78673 17.7325 8.40507 17.3468Z"
      fill="white"
    />
  </svg>
);

export const AngleLeftButtonIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06774 11.4689L14.4288 4.22027C14.7225 3.92658 15.1974 3.92658 15.4911 4.22027L15.9348 4.66393C16.2284 4.95763 16.2284 5.43253 15.9348 5.72623L9.54225 12L15.9285 18.2738C16.2222 18.5675 16.2222 19.0424 15.9285 19.3361L15.4848 19.7797C15.1911 20.0734 14.7162 20.0734 14.4226 19.7797L7.06149 12.5311C6.77404 12.2375 6.77404 11.7625 7.06774 11.4689Z"
    />
  </svg>
);

export const AngleRightButtonIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.934 12.5311L9.57291 19.7797C9.27921 20.0734 8.80431 20.0734 8.51061 19.7797L8.06695 19.3361C7.77326 19.0424 7.77326 18.5675 8.06695 18.2738L14.4595 12L8.0732 5.72623C7.77951 5.43253 7.77951 4.95763 8.0732 4.66393L8.51686 4.22027C8.81055 3.92658 9.28546 3.92658 9.57915 4.22027L16.9402 11.4689C17.2277 11.7625 17.2277 12.2375 16.934 12.5311Z"
    />
  </svg>
);
