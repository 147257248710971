import { colors } from '@homelight/particle-tokens';
import styled from 'styled-components';

import { useInView } from 'react-intersection-observer';

import { Box } from '../Box';
import { Paragraph } from '../Typography';

const TimelineItemStyles = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 72px 0;

  &::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    background: #dbdfe6;
    z-index: 0;
  }
  &:first-of-type,
  &:last-of-type {
    padding: 0px;
    &::before {
      height: 50%;
    }
  }
  &:first-of-type {
    &::before {
      top: 50%;
    }
  }
  &:last-of-type {
    &::before {
      bottom: 50%;
    }
  }
`;

const Marker = styled.div<{ inView?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ inView }) => (inView ? colors.darkBlue : '#DBDFE6')};
  background-color: white;
  z-index: 0;
  position: relative;

  &::before {
    content: '';
    width: 32px;
    height: 32px;
    transform: scale(${({ inView }) => (inView ? '1' : '0')});
    transform-origin: center;
    opacity: ${({ inView }) => (inView ? '1' : '0')};
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    border-radius: 50%;
    background-color: ${colors.darkBlue};
    transition: all 200ms ease-in-out;
    z-index: 1;
  }
`;

type Item = {
  title: string;
  description: string;
};

type TimelineItemProps = {
  item: Item;
  index: number;
  noAnimation?: boolean;
};

const TimelineItem = ({ item, index, noAnimation }: TimelineItemProps) => {
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '-200px 0px -200px 0px',
  });

  return (
    <TimelineItemStyles ref={ref}>
      <Marker inView={noAnimation ? false : inView}>
        <Paragraph
          color={!noAnimation && inView ? 'white' : 'coolGray2'}
          zIndex="2"
        >
          {index + 1}
        </Paragraph>
      </Marker>

      <Box flexDirection="column" ml="48px">
        <Paragraph fontWeight="600" mb="6px">
          {item.title}
        </Paragraph>
        <Paragraph variant="text-small" lineHeight="21px">
          {item.description}
        </Paragraph>
      </Box>
    </TimelineItemStyles>
  );
};

type HorizontalTimelineProps = {
  items: Item[];
  noAnimation?: boolean;
};

export const HorizontalTimeline = ({
  items,
  noAnimation,
}: HorizontalTimelineProps) => (
  <Box flexDirection="column">
    {items.map((item, i) => {
      return (
        <TimelineItem
          item={item}
          index={i}
          key={item.title}
          noAnimation={noAnimation}
        />
      );
    })}
  </Box>
);
