import { theme } from '@hl-portals/constants';

import { PopIn } from '../Animations';
import { Icon } from '../Icon';

export const SuccessIcon = () => (
  <PopIn
    bgcolor={theme.colors.green400}
    borderRadius="50%"
    width="56px"
    height="56px"
    justifyContent="center"
    alignItems="center"
  >
    <PopIn
      bgcolor={theme.colors.green600}
      borderRadius="50%"
      width="40px"
      height="40px"
      justifyContent="center"
      alignItems="center"
      animationDelay=".6s"
    >
      <Icon type="check" color="white" />
    </PopIn>
  </PopIn>
);
