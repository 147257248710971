import { Box, BoxTypes } from '../Box';
import Card from '../Card';
import { Icon, IconType, IconTypeProp } from '../Icon';
import { Radio } from '../Radio';
import { Paragraph } from '../Typography';

export type CardSelectOption = {
  value: string;
  label: string;
  description?: string;
  icon?: IconTypeProp;
  iconProps: Omit<IconType, 'type'>;
};

interface CardSelectProps {
  options: CardSelectOption[];
  selected: string;
  onChange: (value: string) => void;
}

export const CardSelect = ({
  options,
  selected,
  onChange,
  ...rest
}: CardSelectProps & BoxTypes): React.ReactElement => (
  <Box flexDirection="column" gap="32px" {...rest}>
    {options.map((option) => {
      const isSelected = option.value === selected;

      return (
        <Card
          key={option.value}
          p="24px 32px"
          gap="16px"
          onClick={() => onChange(option.value)}
          selected={isSelected}
        >
          <Box>
            <Radio
              checked={isSelected}
              name={option.value}
              value={option.value}
            />
          </Box>
          <Box flexDirection="column" flex="1">
            <Paragraph variant="text-bold">{option.label}</Paragraph>
            {option.description && (
              <Paragraph variant="text-small">{option.description}</Paragraph>
            )}
          </Box>
          {option.icon && (
            <Icon type={option.icon} {...(option.iconProps || {})} />
          )}
        </Card>
      );
    })}
  </Box>
);

export default CardSelect;
