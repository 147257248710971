import styled, { css } from 'styled-components';

import { media } from '@hl-portals/helpers';

import { Panel } from '../Panel';

export const TabsWrapper = styled(Panel)<{ mobileOnly?: boolean }>`
  border-radius: 0;
  border-radius: 0 0 12px 12px;

  ${({ mobileOnly }) =>
    mobileOnly
      ? css`
          border-top: none;
        `
      : media.untilDesktop`
      border: 0;
    `}

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpointsValue.md}px) {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid ${theme.colors.coolGray4};
    }
  `}
`;

export const Tab = styled.button<{
  active: boolean;
  hasOneTab: boolean;
  mobileOnly?: boolean;
}>`
  ${({ active, theme, hasOneTab, mobileOnly }) => css`
    text-align: center;
    font-size: 14px;
    line-height: 52px;
    position: relative;
    color: ${theme.colors.coolGray2};
    font-weight: regular;
    border: 0;
    cursor: pointer;
    user-select: none;
    outline: none;
    text-transform: capitalize;
    background-color: transparent;
    ${!mobileOnly &&
    media.desktop`
      width: 100%;
      color: ${theme.colors.darkBlue};
      background-color: ${theme.colors.coolGray6}80;
      line-height: 74px;
      &:focus-visible {
        outline: solid;
      }
    `}
    ${active
      ? css`
          font-weight: bold;
          -webkit-tap-highlight-color: transparent;
          cursor: auto;
          color: ${theme.colors.darkBlue};
          ${media.desktop`
            
            ${hasOneTab ? '' : 'border-bottom: 0'};
            background-color: transparent;
            position: relative;
            ::after {
              content: '';
              display: block;
              background: transparent;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -1px;
              height: 1px;
            }
          `}
          ::before {
            content: ${hasOneTab ? 'normal' : `''`};
            height: 2px;
            width: 100%;
            position: absolute;
            background-color: ${theme.colors.darkBlue};
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            ${media.tablet`
              width: 80%;
            `}
          }
        `
      : ''}
  `}
`;

export const TabsHead = styled.div<{ mobileOnly?: boolean }>`
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray4};
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.08), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.08), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;

  ${Tab}:not(:last-child) {
    margin-right: 15px;
  }
  ${({ mobileOnly }) =>
    mobileOnly
      ? media.desktop`
      padding-left: 48px
    `
      : media.desktop`
      padding: 0;
      position: static;
      justify-content: space-around;
      background: transparent;
      ${Tab}:not(:last-child) {
        margin-right: 0;
        border-right: 1px solid ${({ theme }: any) => theme.colors.coolGray4};
      }
  `}
`;
