import styled from 'styled-components';
import { Box } from '../Box';
import { Button } from '../Button';

export const ImpersonationWrapper = styled(Box)`
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: #bc3030;
`;

export const UnimpersonateButton = styled(Button)`
  margin-left: 20px;
  background-color: initial;
  border: 1px solid transparent;
  border-color: white;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  background: transparent;
  font-weight: bold;
`;

