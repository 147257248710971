import * as Sentry from '@sentry/nextjs';
import { enhancedAxios } from '@hl-portals/helpers';
import { signOut as nextSignOut } from 'next-auth/react';
import { hapiLogoutPath } from '@hl-portals/constants';

import { ImpersonationWrapper, UnimpersonateButton } from './styles';
import { Paragraph } from '../Typography';
import { useRouter } from 'next/router';

type ImpersonationHeaderProps = {
  name: string;
}

const unimpersonate = async () => {
  try {
    await enhancedAxios({
      url: hapiLogoutPath,
      method: 'DELETE',
      withCredentials: true,
    }).then(async (data) => {
      await nextSignOut({ redirect: false });

      if (data.return_url) {
        window.location.href = data.return_url;
      } else {
        const router = useRouter();
        router.replace('/');
      }
    })
  } catch (error) {
    console.error('Failed to unimpersonate!', error);
    Sentry.captureException(error);
  }
};

export const ImpersonationHeader = ({
  name
}: ImpersonationHeaderProps): React.ReactElement => (
  <ImpersonationWrapper>
    <Paragraph color="white" variant="text">Impersonating {name}</Paragraph>
    <UnimpersonateButton
      onClick={unimpersonate}
    >
      Stop Impersonating
    </UnimpersonateButton>
  </ImpersonationWrapper>
);

export default ImpersonationHeader;
