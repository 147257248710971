import styled, { css } from 'styled-components';
import { spacing as muiSpacing } from '@mui/system';
import { paragraphHelper } from '@hl-portals/helpers';

export const SwitchWrapper = styled.div<{ fluid?: boolean }>`
  ${({ fluid }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    ${fluid
      ? css`
          width: 100%;
          justify-content: space-between;
        `
      : ''}
    ${muiSpacing}
  `}
`;

export const SwitchItem = styled.button<{ active?: boolean; fluid?: boolean }>`
  ${({ theme, active, fluid }) => css`
    display: flex;
    flex-direction: row;
    ${fluid ? 'flex-grow: 1;' : ''}
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    position: relative;
    border: 0;
    ${paragraphHelper({
      theme,
      type: active ? 'text-small' : 'text-small',
    })}
    background: ${active
      ? `${theme.colors.electricBlue}14`
      : theme.colors.white};

    border: 1px solid ${theme.colors.coolGray4};

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: '';
      position: absolute;
      border: 1px solid ${theme.colors.white};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      pointer-events: none;
    }

    :not(:last-child) {
      border-right: 1px solid ${theme.colors.coolGray4};
      border-right: none;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      &::before {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &::before {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &:focus {
      outline: 1px;
    }
  `}
`;
