import styled, { css } from 'styled-components';
import { SizeState } from './types';

export const DrawerStyles = styled.div<{
  elementHeight: number;
  sizeState: SizeState;
}>`
  transition: 0.3s ease all;
  position: relative;
  z-index: 1;
  ${(props) => {
    switch (props.sizeState) {
      case 'COLLAPSING':
      case 'COLLAPSED':
        return css`
          min-height: 0px;
          max-height: 0px;
          overflow: hidden;
        `;
      case 'EXPANDING':
        return css`
          --finalHeight: ${props.elementHeight}px;
          min-height: var(--finalHeight);
          max-height: var(--finalHeight);
          overflow: hidden;
        `;
      case 'EXPANDED':
        return css`
          min-height: initial;
          max-height: initial;
          overflow: initial;
        `;
      case 'BEFORE_COLLAPSING':
        return css`
          --finalHeight: ${props.elementHeight}px;
          min-height: var(--finalHeight);
          max-height: var(--finalHeight);
          transition: 1s linear all;
          overflow: hidden;
        `;
      default:
        return '';
    }
  }}
`;

export default {};
