import { createContext, useContext, useState } from 'react';

const DEFAULT_STATE = {
  currentStepIdx: 0,
  isLastStep: true,
  isFirstStep: true,
  setCurrentStepIdx: () => {},
  onNext: () => {},
  onBack: () => {},
  steps: [],
  loading: false,
  setLoading: () => {},
};

type StepType = {
  key: string;
  title: string;
  component: React.ReactNode;
};

type StepsState = {
  currentStepIdx: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  setCurrentStepIdx: (idx: number) => void;
  onNext: () => void;
  onBack: () => void;
  steps: StepType[];
  currentStep?: StepType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const StepsContext = createContext<StepsState>({ ...DEFAULT_STATE });

export const StepsProvider = ({
  steps,
  children,
}: {
  steps: StepType[];
  children: React.ReactNode;
}) => {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [loading, setLoading] = useState(false);

  const onNext = () => {
    if (currentStepIdx + 1 >= steps.length) return;
    setCurrentStepIdx((prev) => prev + 1);
  };

  const onBack = () => {
    if (currentStepIdx - 1 < 0) return;
    setCurrentStepIdx((prev) => prev - 1);
  };

  return (
    <StepsContext.Provider
      value={{
        currentStepIdx,
        setCurrentStepIdx,
        onNext,
        onBack,
        loading,
        setLoading,
        steps,
        isLastStep: currentStepIdx === steps.length - 1,
        isFirstStep: currentStepIdx === 0,
        currentStep: steps[currentStepIdx],
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

export const useStepsContext = () => {
  const ctx = useContext(StepsContext);
  if (!ctx) throw new Error('Missing StepsProvider');

  return ctx;
};
