import styled from 'styled-components';

import { Box } from '../Box';

const ScrollableWrapper = styled(Box)`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 15px;
    background-color: ${({ theme }) => theme.colors.white};
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.coolGray3};
    border: 6px solid ${({ theme }) => theme.colors.white};
  }
`;

export default ScrollableWrapper;
