import styled from 'styled-components';

import { useId } from 'react';
import ReactTooltip from 'react-tooltip';

import { Colors, colors } from '@hl-portals/constants';

import { Box, BoxTypes } from '../Box';

type TooltipDirection = 'top' | 'bottom' | 'left' | 'right';
type Offset = { top?: number; bottom?: number; left?: number; right?: number };

interface TooltipProps {
  message?: string;
  node?: React.ReactNode;
  direction?: TooltipDirection;
  offset?: Offset;
  maxWidth?: string;
  fontWeight?: string;
  wrapperProps?: BoxTypes;
  children: React.ReactNode;
  textColor?: Colors;
  backgroundColor?: Colors;
  delay?: number;
}

const StyledReactTooltip = styled(ReactTooltip)<{
  maxWidth?: string;
  fontWeight?: string;
}>`
  &&& {
    opacity: 1;
    max-width: ${({ maxWidth }) => maxWidth || '300px'};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    border-radius: 6px;
    line-height: 120%;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.3));
    padding: 12px 16px;
  }
`;

const Tooltip = ({
  message,
  node,
  direction = 'top',
  offset,
  maxWidth,
  fontWeight,
  wrapperProps,
  textColor,
  backgroundColor,
  children,
  delay = 1000,
}: TooltipProps): React.ReactElement => {
  const id = useId();

  if (message) {
    return (
      <>
        <StyledReactTooltip
          id={id}
          offset={offset}
          effect="solid"
          place={direction}
          textColor={colors[textColor || 'white']}
          backgroundColor={colors[backgroundColor || 'darkBlue']}
          fontWeight={fontWeight}
          maxWidth={maxWidth}
          delayHide={delay}
        />
        <Box data-for={id} data-tip={message} {...wrapperProps}>
          {children}
        </Box>
      </>
    );
  } else if (node) {
    return (
      <>
        <StyledReactTooltip
          id={id}
          offset={offset}
          effect="solid"
          place={direction}
          textColor={colors[textColor || 'white']}
          backgroundColor={colors[backgroundColor || 'darkBlue']}
          fontWeight={fontWeight}
          maxWidth={maxWidth}
          delayHide={1000}
        >
          {node}
        </StyledReactTooltip>
        <Box data-for={id} data-tip {...wrapperProps}>
          {children}
        </Box>
      </>
    );
  }

  return <>{children}</>;
};

export default Tooltip;
