import { FieldError, FieldErrorsImpl } from 'react-hook-form';

import { Paragraph } from '../../../Typography';

type Props = {
  error: FieldError | FieldErrorsImpl<any>;
};
export const InputError = ({ error }: Props) => (
  <Paragraph
    variant="text-small"
    color="cadmiumRed"
    mt="8px"
    data-test="input-error"
  >
    {error?.message?.toString()}
  </Paragraph>
);
