import styled, { css } from 'styled-components';

import { Box } from '../Box';

export interface Props {
  side: 'left' | 'right';
  children: React.ReactNode;
}

const MessageBubble = styled(Box)<Props>`
  ${({ side, theme }) => css`
    background-color: ${theme.messageBubble[side].backgroundColor};
    color: ${theme.messageBubble[side].color};
    border-radius: 8px;
    position: relative;
    word-break: break-all;
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      border-bottom: 15px solid ${theme.messageBubble[side].backgroundColor};
      ${side}: -7px;
      border-${side}: 15px solid transparent;
    }
    
    p {
      word-break: initial;
    }
  `}
`;

export default MessageBubble;
