import styled from 'styled-components';

import { ellipsis } from '@hl-portals/helpers';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import SimpleLink from '../SimpleLink';
import Spinner from '../Spinner';
import { Paragraph } from '../Typography';
import { CardWrapper, FileIcon } from './styles';

type CondensedDocumentCardProps = {
  onDocumentView: (e: React.MouseEvent<HTMLElement>) => void;
  onDocumentDownload: (e: React.MouseEvent<HTMLElement>) => void;
  document: TransactionDocument;
  loading?: boolean;
} & BoxTypes;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
    background: ${theme.colors.white};
    border-radius: 50%;
  `}
`;

const CondensedDocumentCard = ({
  onDocumentView,
  onDocumentDownload,
  document,
  loading = false,
  ...extraProps
}: CondensedDocumentCardProps): React.ReactElement => {
  const { name: docName } = document;

  return (
    <CardWrapper
      data-test="transaction-document-card"
      alignItems="center"
      onClick={onDocumentView}
      padding="24px"
      width="100%"
      mb="24px"
      {...extraProps}
    >
      <Box flex="1">
        <FileIcon type="file" size={32} marginRight="16px" />
        <Paragraph
          variant="text-bold"
          color="darkBlue"
          alignItems="center"
          display="flex"
        >
          {loading ? <Spinner /> : ellipsis(docName, 30)}
        </Paragraph>
      </Box>
      <SimpleLink
        onClick={onDocumentDownload}
        fontWeight="bold"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flex=".5"
      >
        <StyledIcon
          width="32px"
          display="flex"
          height="32px"
          paddingLeft="4px"
          type="simpleDownload"
          fill="darkBlue"
          justifyContent="center"
          alignItems="center"
        />
      </SimpleLink>
    </CardWrapper>
  );
};
export default CondensedDocumentCard;
