import { UseMutationOptions, useMutation } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { CREATE_SNAPSHOT_PATH, SNAPSHOT_PATH } from '@hl-portals/constants';

export const useFetchSnapshot = ({
  onSuccess,
}: UseMutationOptions<unknown, unknown, string>) => {
  return useMutation({
    mutationFn: (uuid: string) => http.public.get(SNAPSHOT_PATH(uuid)),
    onSuccess,
  });
};

type UpdateSnapshotParams = {
  uuid: string;
  payload: Record<any, any>;
};

export const useUpdateSnapshot = ({
  onSuccess,
}: UseMutationOptions<unknown, unknown, UpdateSnapshotParams>) => {
  return useMutation({
    mutationFn: ({ uuid, payload }: UpdateSnapshotParams) =>
      http.public.put(SNAPSHOT_PATH(uuid), { payload }),
    onSuccess,
  });
};

export const useCreateSnapshot = ({
  onSuccess,
}: UseMutationOptions<unknown, unknown, Record<any, any>>) => {
  return useMutation({
    mutationFn: (data: Record<any, any>) =>
      http.public.post(CREATE_SNAPSHOT_PATH, data),
    onSuccess,
  });
};

export const useSnapshot = ({
  onFetchSuccess,
  onUpdateSuccess,
  onCreateSuccess,
}: {
  onFetchSuccess: any;
  onUpdateSuccess: any;
  onCreateSuccess: any;
}) => {
  const { mutate: fetch, isLoading: isFetchLoading } = useFetchSnapshot({
    onSuccess: onFetchSuccess,
  });

  const { mutate: create, isLoading: isCreateLoading } = useCreateSnapshot({
    onSuccess: onCreateSuccess,
  });

  const { mutate: update, isLoading: isUpdateLoading } = useUpdateSnapshot({
    onSuccess: onUpdateSuccess,
  });

  return {
    fetch,
    isFetchLoading,
    create,
    isCreateLoading,
    update,
    isUpdateLoading,
  };
};
