import { SpacingProps, spacing } from '@mui/system';
import styled, { css } from 'styled-components';

import { Colors } from '@hl-portals/constants';

const Spinner = styled.div<
  {
    xl?: boolean;
    md?: boolean;
    paused?: boolean;
    color?: Colors | string;
  } & SpacingProps
>`
  color: transparent !important;
  min-height: 16px;
  pointer-events: none;
  position: relative;
  ${spacing}

  ${({ md }) =>
    md
      ? css`
          min-height: 12px;
          margin: 6px;
          width: 12px;
          height: 12px;
        `
      : ''}

  ${({ xl }) =>
    xl
      ? css`
          min-height: 40px;
        `
      : ''}


  &::after {
    animation: loading 500ms infinite linear;
    ${({ paused }) =>
      paused &&
      css`
        animation: none;
      `}
    border: 2px solid
      ${({ theme, color }) => color || theme.colors.electricBlue};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 8px;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 8px;
    z-index: 1;

    ${({ md }) =>
      md
        ? css`
            height: 16px;
            margin-left: -10px;
            margin-top: -10px;
            width: 16px;
          `
        : ''}

    ${({ xl }) =>
      xl
        ? css`
            height: 32px;
            margin-left: -16px;
            margin-top: -16px;
            width: 32px;
          `
        : ''}
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
