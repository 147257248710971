import { Box } from '../../Box';
import { useBrandTheme } from '../../BrandTheme';
import { getActualColors } from '../helpers';
import { IconColors, IconProps } from '../types';

export const RadioSelect = ({
  colors,
  width = '332',
  height = '226',
  containerProps = {},
}: IconProps) => {
  const brandCtx = useBrandTheme();

  const defaultColors: IconColors = {
    primary: brandCtx?.colors?.primary || '#46B6FF',
    secondary: brandCtx?.colors?.secondary || '#F6AB98',
  };

  const actualColors = getActualColors(colors, defaultColors);

  return (
    <Box
      as="svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...containerProps}
    >
      <g clipPath="url(#clip0_3948_6371)">
        <g filter="url(#filter0_d_3948_6371)">
          <path
            d="M294.526 16H36.4736C27.6516 16 20.5 23.1298 20.5 31.9248V180.075C20.5 188.87 27.6516 196 36.4736 196H294.526C303.348 196 310.5 188.87 310.5 180.075V31.9248C310.5 23.1298 303.348 16 294.526 16Z"
            fill="white"
          />
        </g>
        <path d="M229.04 94.48H95.1797V106.49H229.04V94.48Z" fill="#E9F6FF" />
        <path
          d="M243.39 74.8401H95.1797V86.8501H243.39V74.8401Z"
          fill="#E9F6FF"
        />
        <path
          d="M192.24 51.3999H95.1797V63.4099H192.24V51.3999Z"
          fill="#B8D0E7"
        />
        <path d="M249.69 151.38H95.1797V163.39H249.69V151.38Z" fill="#E9F6FF" />
        <path d="M212.82 127.94H95.1797V139.95H212.82V127.94Z" fill="#B8D0E7" />
        <path
          d="M61.99 74.8401C69.3023 74.8401 75.23 68.9124 75.23 61.6001C75.23 54.2879 69.3023 48.3601 61.99 48.3601C54.6778 48.3601 48.75 54.2879 48.75 61.6001C48.75 68.9124 54.6778 74.8401 61.99 74.8401Z"
          fill={actualColors.primary || '#46B6FF'}
        />
        <path
          d="M60.7406 67.2401C60.6105 67.3701 60.4405 67.45 60.2705 67.5L60.1605 67.52H60.0505C59.8405 67.5 59.6205 67.41 59.4705 67.26L54.4405 62.23C54.1005 61.89 54.1005 61.3101 54.4405 60.97L55.7005 59.71C56.0405 59.37 56.6205 59.37 56.9605 59.71L60.0905 62.84L67.0005 55.93C67.3405 55.59 67.9205 55.59 68.2605 55.93L69.5205 57.19C69.8605 57.53 69.8605 58.11 69.5205 58.45L60.7406 67.23V67.2401Z"
          fill="white"
        />
        <path
          d="M62.2297 151.29C68.8847 151.29 74.2797 145.895 74.2797 139.24C74.2797 132.585 68.8847 127.19 62.2297 127.19C55.5747 127.19 50.1797 132.585 50.1797 139.24C50.1797 145.895 55.5747 151.29 62.2297 151.29Z"
          fill="white"
        />
        <path
          d="M62.2295 152.2C55.0795 152.2 49.2695 146.39 49.2695 139.24C49.2695 132.09 55.0795 126.28 62.2295 126.28C69.3795 126.28 75.1895 132.09 75.1895 139.24C75.1895 146.39 69.3795 152.2 62.2295 152.2ZM62.2295 128.1C56.0895 128.1 51.0895 133.1 51.0895 139.24C51.0895 145.38 56.0895 150.38 62.2295 150.38C68.3695 150.38 73.3695 145.38 73.3695 139.24C73.3695 133.1 68.3695 128.1 62.2295 128.1Z"
          fill="#C5C8CD"
        />
        <path
          d="M147.961 26.94C147.961 26.94 148.051 27.09 148.051 27.39L148.251 26.77L147.971 26.95L147.961 26.94Z"
          fill="#BBD3EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.6037 166L114.5 203.138L101.77 204.438L108.076 221.694L99.2857 225L92.9794 207.744L82.5 215.172L82.6037 166Z"
          fill="#F6AB98"
          stroke="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3948_6371"
          x="0.5"
          y="0"
          width="330"
          height="220"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.423529 0 0 0 0 0.505882 0 0 0 0 0.670588 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3948_6371"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3948_6371"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3948_6371">
          <rect
            width="331"
            height="226"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Box>
  );
};
