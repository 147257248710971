import { Colors, theme } from '@hl-portals/constants';

import { BoxTypes } from '../Box';
import { AngleDownIcon } from '../Icon';
import Spinner from '../Spinner';
import {
  AngleLeftButtonIcon,
  AngleRightButtonIcon,
  SendButtonIcon,
} from './icons';
import { ButtonIconWrapper } from './styles';

const ICONS = {
  send: SendButtonIcon,
  'angle-left': AngleLeftButtonIcon,
  'angle-right': AngleRightButtonIcon,
  'angle-down': AngleDownIcon,
};

type ButtonIcons = keyof typeof ICONS;

type ButtonIconProps = {
  icon: ButtonIcons;
  size?: number;
  fill?: Colors;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  title?: string;
} & BoxTypes;

const ButtonIcon = ({
  icon,
  size = 24,
  fill = 'darkBlue',
  onClick,
  isLoading,
  disabled,
  ...otherProps
}: ButtonIconProps): React.ReactElement => {
  const DisplayIcon = ICONS[icon];
  return (
    <ButtonIconWrapper
      type="button"
      size={size}
      fill={theme.colors[fill]}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {isLoading ? <Spinner xl /> : <DisplayIcon />}
    </ButtonIconWrapper>
  );
};

export default ButtonIcon;
