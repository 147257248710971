import { css, FlattenSimpleInterpolation } from 'styled-components';
import { Paragraphs } from '@hl-portals/constants';

interface ParagraphHelperProps {
  type: Paragraphs;
  theme: {
    paragraphs: any;
  };
}

const paragraphHelper = ({
  type,
  theme,
}: ParagraphHelperProps): string | FlattenSimpleInterpolation => {
  const paragraph = theme.paragraphs[type];
  if (typeof paragraph === 'undefined') {
    return '';
  }
  return css`
    font-size: ${paragraph.fontSize};
    ${paragraph.fontWeight ? `font-weight: ${paragraph.fontWeight};` : ''}
  `;
};

export default paragraphHelper;
