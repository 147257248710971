import { colors } from '@homelight/particle-tokens';
import { sizing, spacing } from '@mui/system';
import styled, { css } from 'styled-components';

import { Box } from '../Box';
import IconWrapper from '../Icon/styles';
import { Paragraph } from '../Typography';
import { ColorType } from './types';

const getColors = (
  theme: any
): {
  [Key in ColorType]: Record<string, string>;
} => ({
  primary: {
    background: theme.colors.eletricBlue,
    hoverBackground: theme.colors.eletricBlue,
    borderColor: theme.colors.eletricBlue,
  },
  secondary: {
    backGround: 'transparent',
    hoverBackground: theme.colors.whiteHover,
    borderColor: theme.colors.eletricBlue,
  },
  disabled: {
    background: theme.colors.coolGray5,
    borderColor: 'none',
  },
});

export const FileInputWrapper = styled(Box)<{
  active?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, active, disabled }) => css`
    background: ${active ? theme.colors.mistBlue : theme.colors.white};
    border-radius: 12px;
    outline: 2px dashed ${active ? theme.colors.aaBlue : theme.colors.coolGray4};
    transition: all 0.2s ease-out;
    width: 100%;

    &:hover {
      background-color: ${disabled ? 'transparent' : theme.colors.mistBlue};
    }
  `}
  ${sizing};
  ${spacing};
`;

export const BrowseButton = styled.a`
  ${({ theme, color }) => css`
    cursor: ${color === 'disabled' ? 'auto' : 'pointer'};
    padding: 5px 12px;
    flex: 1;
    color: ${colors.electricBlue};
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    background: ${getColors(theme)[color]['background']};
    border: 1px solid ${theme.colors.electricBlue};
    width: 100%;
    max-width: 392px;
    text-align: center;

    &:hover,
    &:focus {
      outline: none;
      background: ${getColors(theme)[color]['backgroundHover']};
    }
  `}
`;

export const UploadedFileWrapper = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.colors.coolGray3};
    margin-top: 1rem;
    width: 100%;

    ${IconWrapper} {
      display: flex;
    }

    svg {
      position: static;
    }
  `}
`;

export const FileName = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const FileProgressIconWrapper = styled(Box)`
  position: relative;
`;

export const LoadingBar = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.colors.coolGray4};
    border-radius: 8px;
    height: 10px;
    position: relative;
    width: 100%;
    overflow: hidden;
    max-width: 500px;
  `}
`;

export const LoadingBarProgress = styled.div<{
  percentage: number;
  mock: boolean;
  mockDuration: number;
}>`
  ${({ theme, percentage, mock, mockDuration }) => css`
    background: ${theme.colors.electricBlue};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    left: 0;
    position: absolute;
    transform: translateX(-${100 - percentage}%);
    transition: all ${mock ? mockDuration : '.5'}s ease-in-out;
  `}
`;

export const UploadedItemWrapper = styled(Box)`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.coolGray4};
    border-radius: 4px;
    padding: 16px 12px;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
  `}
`;
