import { ComponentPropsWithRef } from 'react';

import { Box, BoxTypes } from '../Box';
import * as svgs from './svgs';

type Props = {
  variant: keyof typeof svgs;
} & BoxTypes &
  ComponentPropsWithRef<'div'>;

export const Logo = ({ variant, ...props }: Props) => {
  const LogoSVG = svgs[variant];

  return (
    <Box {...props}>
      <LogoSVG />
    </Box>
  );
};
