export const House4Icon = () => (
  <svg
    width="362"
    height="280"
    viewBox="0 0 362 280"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8182_121305)">
      <path
        d="M89.7319 109.725L75.8684 113.186C75.4198 111.36 75.3247 109.466 75.5965 107.598C75.8684 105.731 76.5208 103.946 77.4858 102.338C78.4508 100.73 79.7284 99.3258 81.2371 98.2083C82.7458 97.0907 84.4583 96.2866 86.2796 95.8232L89.7319 109.725Z"
        fill="#008CE7"
      />
      <path
        d="M10.2075 279.006H187.633V165.97L94.4078 91.98L10.2075 165.97V279.006Z"
        fill="#E9F6FF"
      />
      <path
        d="M187.646 279.006H310.08V172.294H187.646V279.006Z"
        fill="#C7DEEE"
      />
      <path
        opacity="0.8"
        d="M35.7461 205.711V279.007H42.1749H72.1717H79.8646V205.711H35.7461Z"
        fill="#B8D0E7"
      />
      <path
        d="M10.2072 178.222L94.4075 110.024L187.633 189.739V172.294L94.4075 91.98L5.11035 164.321L10.2072 178.222Z"
        fill="#C7DEEE"
      />
      <path
        d="M221.218 81.8811H94.4076L85.043 89.6495L0 162.972V178.222H10.2073V169.473L94.2309 97.5132L187.633 177.527L316.617 177.868V165.97L221.218 81.8811Z"
        fill="#D4E6F0"
      />
      <path
        opacity="0.8"
        d="M108.679 205.766H98.5259V243.218V251.545H108.679H130.833V243.218V205.766H108.679Z"
        fill="#B8D0E7"
      />
      <path
        opacity="0.8"
        d="M145.906 205.766H135.767V243.218V251.545H145.906H168.074V243.218V205.766H145.906Z"
        fill="#B8D0E7"
      />
      <path
        d="M361.973 278.843H276.549C276.359 275.681 276.305 272.506 276.903 269.398C277.623 265.623 279.445 261.902 282.584 259.708C285.71 257.5 290.277 257.132 293.294 259.49C296.04 261.643 296.951 265.378 297.304 268.867C298.187 256.901 302.958 245.712 311.358 237.166C313.152 235.34 315.136 233.609 317.515 232.642C319.866 231.66 322.693 231.51 324.922 232.764C328.13 234.563 329.271 238.611 329.557 242.277C330.291 251.844 327.178 261.235 324.093 270.311C326.444 263.947 329.965 258.836 335.578 255.047C338.867 252.839 343.108 251.218 346.723 252.839C350.067 254.338 351.793 258.264 351.752 261.943C351.698 265.609 350.243 269.098 348.735 272.437C350.08 270.161 352.309 269.562 354.933 269.862C357.569 270.161 359.934 271.947 361.103 274.332C361.783 275.736 362.082 277.303 361.973 278.843Z"
        fill="#B8D0E7"
      />
      <path
        d="M251.092 279.552H325.819C324.066 274.523 321.538 269.753 317.814 265.977C313.22 261.344 306.614 258.4 300.172 259.354C291.364 260.649 284.922 268.676 281.918 277.071C278.289 270.543 270.093 267.299 262.863 269.098C257.562 270.407 253.145 274.509 251.092 279.538V279.552Z"
        fill="#E1EEF5"
      />
    </g>
    <defs>
      <clipPath id="clip0_8182_121305">
        <rect width="362" height="279.552" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
