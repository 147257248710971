import {
  SizingProps,
  SpacingProps,
  spacing as muiSpacing,
  sizing,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { MutableRefObject } from 'react';

import {
  CSSVariants,
  addMediaQueries,
  paragraphHelper,
} from '@hl-portals/helpers';

import { Box } from '../Box';

interface ISelectWrapper {
  disabled?: boolean;
  name?: string;
  required?: boolean;
  ref?: MutableRefObject<HTMLElement>;
}

export const SelectWrapper = styled(Box)<
  SpacingProps & ISelectWrapper & SizingProps
>`
  position: relative;
  ${muiSpacing}
  ${sizing}
`;

interface ISelectedItem {
  open: boolean;
  isPlaceholder?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

export const SelectedItem = styled(Box)<ISelectedItem & SpacingProps>`
  ${({ theme, open, isPlaceholder, isError, disabled }) => css`
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    padding: 9px 12px;
    padding-right: 32px;
    box-sizing: border-box;
    height: 100%;
    min-width: 140px;
    background-color: ${disabled ? theme.colors.coolGray6 : theme.colors.white};
    color: ${isPlaceholder ? theme.colors.coolGray2 : theme.colors.darkBlue};
    user-select: none;
    border-radius: 4px;
    ${paragraphHelper({ theme, type: 'text-small' })}
    letter-spacing: 0;
    position: relative;
    z-index: 1;
    outline: none;
    cursor: ${!disabled && 'pointer'};
    border: ${isError
      ? `2px solid ${theme.colors.cadmiumRed}`
      : `1px solid ${theme.colors.coolGray5}`};

    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      ${open
        ? `border-bottom: 4px solid ${theme.colors.darkBlue};`
        : `border-top: 4px solid ${theme.colors.darkBlue};`};
    }
    &:focus,
    &:focus-visible {
      outline: none;
    }
  `}
  ${muiSpacing}
`;

interface IOptionList {
  open: boolean;
  variant: CSSVariants<'top' | 'bottom'>;
}

const VariantCSS = () => ({
  top: `
    bottom: calc(100% - 1px);
    top: auto;
  `,
  bottom: `
    top: calc(100% - 1px);
    bottom: auto;
  `,
});

export const OptionList = styled.ul<IOptionList>`
  ${({ theme, open, variant }) => css`
    display: ${open ? 'flex' : 'none'};
    flex-direction: row;
    position: absolute;
    flex-grow: 1;
    flex-wrap: wrap;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.coolGray4};
    left: 0;
    right: 0;
    border-radius: 4px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 20vh;
    z-index: 100;

    list-style: none
    margin: 0;
    padding: 0;
    ${addMediaQueries({
      theme,
      prop: variant,
      styles: VariantCSS,
    })}
  `}
`;

interface IOption {
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
  selected?: boolean;
}

export const Option = styled.li<IOption>`
  ${({ theme, selected, disabled }) => css`
    width: 100%;
    ${paragraphHelper({ theme, type: 'text-small' })}
    color: ${selected ? theme.colors.electricBlue : theme.colors.darkBlue};
    padding: 12px;
    cursor: default;
    background-color: ${selected ? theme.colors.coolGray6 : theme.colors.white};

    ${disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  `}
`;
