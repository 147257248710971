import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import Image from 'next/image';

import { colors } from '@hl-portals/constants';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid ${colors.coolGray4}`,
  '& .MuiAccordionSummary-root': {
    padding: '16px 24px',
  },
  '&:first-of-type': {
    borderRadius: '12px 12px 0 0',
  },
  '&:last-of-type': {
    borderRadius: '0 0 12px 12px',
  },
  '&:not(:last-of-type)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <Image src="/icons/angle-down-light.svg" height={20} width={20} />
    }
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '24px',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
