import * as Sentry from '@sentry/nextjs';
import axios, { AxiosRequestConfig } from 'axios';
import Jsona from 'jsona';
import { isPublicOrTokenizedPage } from 'packages/libs/auth/src/utils';

import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';

import { signOut } from '@hl-portals/libs/auth';

import { config } from '@hl-portals/helpers';

const dataFormatter = new Jsona();
const baseURL = config.hapiHost;

// TODO: Update to an axios instance with interceptors

type SessionWithToken = Session | (null & { accessToken: string });

const httpPrivate = async <T = any>({
  translateJsona,
  bypassAuth,
  ...request
}: AxiosRequestConfig & {
  translateJsona?: boolean;
  bypassAuth?: boolean;
}): Promise<T & { _httpStatus?: number }> => {
  const session = await getSession();
  const nextAuthToken = (session as SessionWithToken)?.['accessToken'];

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${nextAuthToken}`,
  };

  return axios
    .request<T>({
      baseURL,
      withCredentials: true,
      ...request,
      headers: { ...headers, ...(request.headers ?? {}) },
    })
    .then(({ data, status }) => {
      if (translateJsona) {
        const deserializedJsona = data ? dataFormatter.deserialize(data) : data;
        const { meta } = (data as unknown as any) ?? {};
        if (meta) {
          return {
            data: deserializedJsona,
            _httpStatus: status,
            meta,
          } as unknown as T;
        }

        return deserializedJsona as T;
      }
      return { ...data, _httpStatus: status };
    })
    .catch(async (e) => {
      if (bypassAuth) return Promise.reject(e);

      const responseStatus = e?.response?.status || 500;

      if (responseStatus >= 500) {
        Sentry.captureException(e);
      }

      if (
        responseStatus === 401 &&
        !isPublicOrTokenizedPage(document?.location?.pathname)
      ) {
        await signOut();
      }

      return Promise.reject(e);
    });
};

// ===============================================================
// HTTP Public

const httpPublic = axios.create({ baseURL: config.hapiHost });

httpPublic.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status >= 500) {
    Sentry.captureException(error);
  }
  return Promise.reject(error);
});

export const http = {
  private: httpPrivate,
  public: httpPublic,
};
