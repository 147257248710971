import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { EDIT_TEAM_MEMBER_PROFILE_ENDPOINT } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { useScreenSize } from '../useScreenSize';

type AgentData = {
  teamMemberId: string;
  cellphoneNumber: string;
  officePhoneNumber: string;
  secondaryEmail: string;
};

const updateTeamMemberData = async (agentData: AgentData) => {
  return enhancedAxios({
    method: 'PUT',
    url: EDIT_TEAM_MEMBER_PROFILE_ENDPOINT(agentData.teamMemberId || ''),
    data: {
      team_member_id: agentData.teamMemberId,
      phone_mobile: agentData.cellphoneNumber,
      phone_office: agentData.officePhoneNumber,
      email2: agentData.secondaryEmail,
    },
    translateJsona: true,
  });
};

export const useEditTeamMemberData = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();

  return useMutation({
    mutationFn: updateTeamMemberData,
    onSuccess: () => {
      queryClient.invalidateQueries('current');
      toast.success(`You've updated team member information!`, {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 150 },
      });
    },
    onError: () => {
      toast.error('Sorry, something went wrong. Please try again.', {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 80 },
      });
    },
  });
};
