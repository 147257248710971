import Jsona from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

import { useQuery } from 'react-query';

import { USER_CURRENT_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

const dataFormatter = new Jsona();

export const useCurrentUser = () => {
  return useQuery<UserJsonaResponse>(['GET_CURRENT_USER'], async () => {
    const res = await enhancedAxios({
      method: 'get',
      url: USER_CURRENT_PATH,
    });

    return dataFormatter.deserialize(
      (res as TJsonApiBody) || {}
    ) as UserJsonaResponse;
  });
};
