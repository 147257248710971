import { colors } from '@homelight/particle-tokens';
import styled, { CSSProperties, css } from 'styled-components';

import { pagination } from '@hl-portals/helpers';

import { Box } from '../Box';
import { AngleLeftButtonIcon, AngleRightButtonIcon } from '../ButtonIcon/icons';

type SimplePaginationPropsType = {
  currentPage: number;
  totalPages: number;
  style?: CSSProperties;
  onPageChange: (currentPage: number) => void;
};

export default function SimplePagination({
  currentPage = 1,
  totalPages,
  onPageChange,
  style,
}: SimplePaginationPropsType) {
  const pages = pagination({ currentPage, totalPages });

  function handleNextPage() {
    onPageChange(currentPage + 1);
  }

  function handlePreviousPage() {
    onPageChange(currentPage - 1);
  }

  function handleSelectedPage(page: number) {
    onPageChange(page);
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <Box justifyContent="center" style={style}>
      <PageButton onClick={handlePreviousPage} isClickable={currentPage !== 1}>
        <AngleLeftButtonIcon />
      </PageButton>
      {pages.map((page, i) => {
        return (
          <PageButton
            key={i}
            isSelected={currentPage === page}
            isClickable={page !== '...'}
            {...(page !== '...'
              ? { onClick: () => handleSelectedPage(page) }
              : {})}
          >
            {page}
          </PageButton>
        );
      })}
      <PageButton
        onClick={handleNextPage}
        isClickable={currentPage !== totalPages}
      >
        <AngleRightButtonIcon />
      </PageButton>
    </Box>
  );
}

const PageButton = styled.button<{
  isSelected?: boolean;
  isClickable?: boolean;
}>`
  background-color: transparent;
  border: none;
  border-radius: 16px;
  color: ${colors.darkBlue};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-right: 16px;
  cursor: auto;
  pointer-events: none;
  height: 32px;
  width: 32px;

  svg {
    height: 16px;
    fill: ${({ isClickable }) =>
      isClickable ? `${colors.darkBlue}` : `${colors.coolGray4}`};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${colors.electricBlue};
      color: white;
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
      pointer-events: all;
    `}
`;
