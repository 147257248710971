import styled, { css } from 'styled-components';

import Image from 'next/image';

import { Box, BoxTypes } from '../Box';

type StepperNumberProps = {
  number: number;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
} & BoxTypes;

const StyledStep = styled(Box)<Omit<StepperNumberProps, 'number'>>`
  ${({ theme }) => css`
    color: ${theme.colors.coolGray2};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.coolGray3};
  `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.coolGray2};
      background-color: ${theme.colors.coolGray4};
      border: 1px solid ${theme.colors.coolGray3};
    `}

  ${({ theme, completed }) =>
    completed &&
    css`
      background-color: ${theme.colors.electricBlue};
      border: 1px solid ${theme.colors.electricBlue};
    `}

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.electricBlue};
      border: 1px solid ${theme.colors.electricBlue};
    `}
`;

const StepperNumber = ({
  number,
  active,
  disabled,
  completed,
  ...otherProps
}: StepperNumberProps): React.ReactElement => (
  <StyledStep
    justifyContent="center"
    alignItems="center"
    height="32px"
    width="32px"
    borderRadius="16px"
    fontWeight="bold"
    active={active}
    completed={completed}
    disabled={disabled}
    {...otherProps}
  >
    {completed ? (
      <Image src="/icons/check-circle-blue.svg" height={32} width={32} />
    ) : (
      number
    )}
  </StyledStep>
);

export default StepperNumber;
