import {
  BordersProps,
  FlexboxProps,
  PositionsProps,
  SizingProps,
  SpacingProps,
  border,
  flexbox,
  positions,
  sizing,
  spacing,
} from '@mui/system';
import styled, { css } from 'styled-components';

export const DismissAction = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  content: '';
  height: 16px;
  width: 16px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: '';
    background: rgba(38, 54, 83, 0.64);
    width: 16px;
    height: 2px;
  }
  &::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export interface PanelWrapperProps {
  children: React.ReactNode;
  type?: 'normal' | 'alert';
  noBorder?: boolean;
  contrast?: boolean;
  onClick?: () => void;
}

type PanelWrapperType = PanelWrapperProps &
  SpacingProps &
  FlexboxProps &
  SizingProps &
  BordersProps &
  PositionsProps;

export const PanelWrapper = styled.div<PanelWrapperType>`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme, contrast }) =>
    contrast
      ? theme.panel.contrastBackgroundColor
      : theme.panel.backgroundColor};
  position: relative;
  border: ${({ theme }) => theme.panel.border};
  border-radius: ${({ theme }) => theme.panel.borderRadius};

  ${spacing}
  ${flexbox}
  ${sizing}
  ${border}
  ${positions}

  ${({ type }) =>
    type === 'alert' &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: ${({ theme }) => theme.panel.alertColor};
      }
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;
