// @ts-strict
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { SEND_APP_LINKS_OVER_TEXT_PATH } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';
import { useScreenSize } from '../useScreenSize';

const sendAppLinksViaText = async () => {
  return enhancedAxios({
    method: 'POST',
    url: SEND_APP_LINKS_OVER_TEXT_PATH(),
    translateJsona: true,
  });
};

export const useSendAppLinks = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();

  return useMutation({
    mutationFn: sendAppLinksViaText,
    onSuccess: () => {
      queryClient.invalidateQueries('current');
      toast.success('Link has been sent to your number', {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 80 },
      });
    },
    onError: () => {
      toast.error('Sorry, something went wrong. Please try again.', {
        position: 'bottom-center',
        autoClose: 4000,
        style: { bottom: isDesktop ? 0 : 80 },
      });
    },
  });
};
