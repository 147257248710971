import { useEffect } from 'react';

export const useBrowserBackButton = (fn: (e: PopStateEvent) => void) => {
  useEffect(() => {
    const onBackButtonEvent = (e: PopStateEvent) => {
      e.preventDefault();
      window.history.forward();
      fn(e);
    };

    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [fn]);
};
