export const TeamIcon = () => (
  <svg
    width="252"
    height="215"
    viewBox="0 0 252 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4595_1720)">
      <path
        d="M245.915 33.4316L135.2 16.2233C134.922 16.1821 134.649 16.1563 134.377 16.146L148.594 125.51L191.401 132.165L190.064 140.795L149.762 134.531L151.017 144.182L225.304 155.73L223.966 164.36L152.19 153.204L152.936 158.926L212.835 168.236L211.498 176.866L154.108 167.943L155.914 181.846C156.428 185.79 153.651 189.404 149.721 189.914L104.028 195.884C104.934 196.554 106.004 197.023 107.192 197.209L217.907 214.417C221.827 215.026 225.499 212.335 226.106 208.406L251.913 41.6542C252.52 37.7259 249.835 34.0451 245.915 33.4367V33.4316Z"
        fill="#46B6FF"
        fill-opacity="0.2"
      />
      <path
        d="M211.498 176.862L212.835 168.232L152.936 158.921L154.108 167.938L211.498 176.862Z"
        fill="white"
      />
      <path
        d="M223.972 164.355L225.304 155.725L151.017 144.177L152.19 153.199L223.972 164.355Z"
        fill="white"
      />
      <path
        d="M190.064 140.795L191.401 132.165L148.594 125.51L149.762 134.526L190.064 140.795Z"
        fill="#B8D0E7"
      />
      <path
        d="M182.883 112.936C202.081 112.936 217.645 97.3382 217.645 78.0971C217.645 58.8559 202.081 43.2578 182.883 43.2578C163.684 43.2578 148.121 58.8559 148.121 78.0971C148.121 97.3382 163.684 112.936 182.883 112.936Z"
        fill="white"
      />
      <path
        d="M177.559 112.524C187.054 113.998 196.246 111.472 203.432 106.198C200.511 97.5735 190.238 95.2072 188.222 93.6555C185.547 91.6037 187.538 84.0048 187.538 84.0048L183.114 83.6079L183.155 83.3244L182.667 83.3914L182.219 83.18L182.178 83.4635L177.842 82.4995C177.842 82.4995 177.446 90.3458 174.272 91.4903C171.803 92.3821 160.703 91.4336 155.487 99.5428C160.739 106.281 168.459 111.111 177.554 112.524H177.559Z"
        fill="#FFDACA"
      />
      <path
        d="M176.093 89.7326C179.673 89.7532 183.248 89.7584 186.828 89.7429C186.751 87.0055 187.533 84 187.533 84L183.109 83.603L183.15 83.3195L182.662 83.3865L182.214 83.1751L182.173 83.4587L177.837 82.4946C177.837 82.4946 177.605 87.057 176.088 89.7275L176.093 89.7326Z"
        fill="#DDB9AF"
      />
      <path
        d="M178.881 83.0669C178.881 83.0669 174.822 81.3141 170.877 82.3657C166.932 83.4174 169.149 72.5037 169.498 72.1635C169.848 71.8232 177.338 68.5342 177.338 68.5342L178.881 83.0617V83.0669Z"
        fill="#774F33"
      />
      <path
        d="M186.221 84.0514C186.221 84.0514 190.629 83.8864 193.937 86.2991C197.244 88.7117 199.055 77.7207 198.854 77.2774C198.649 76.834 192.826 71.0498 192.826 71.0498L186.221 84.0514Z"
        fill="#774F33"
      />
      <path
        d="M175.8 71.6375C175.362 68.8743 173.264 69.1733 173.068 69.2093C172.868 69.2042 170.748 69.1887 170.682 71.9829C170.615 74.7976 171.978 76.535 173.542 76.3288C175.121 76.3236 176.237 74.4162 175.8 71.6375Z"
        fill="#EAC7BB"
      />
      <path
        d="M192.687 74.2665C193.942 71.7662 195.85 72.689 196.025 72.7818C196.221 72.8334 198.242 73.4675 197.46 76.1482C196.673 78.8495 194.847 80.092 193.417 79.4218C191.915 78.9372 191.427 76.7771 192.687 74.2665Z"
        fill="#EAC7BB"
      />
      <path
        d="M174.364 67.3378C177.199 55.9705 185.845 57.9759 186.653 58.1924C187.491 58.2337 196.339 58.9451 195.603 70.6424C194.862 82.4067 188.536 89.1807 182.08 87.7475C175.496 87.1495 171.515 78.7722 174.364 67.3378Z"
        fill="#FFDACA"
      />
      <path
        d="M162.21 81.1593C161.628 76.633 162.868 72.4108 167.153 70.2405C167.282 70.1631 167.477 70.0703 167.616 70.0136L168.557 69.6012C170.445 68.8692 171.674 67.1576 172.477 65.3378C174.087 61.791 174.73 56.9347 178.757 55.2593C173.49 57.8936 174.622 65.2244 170.491 68.8846C169.961 69.3383 169.354 69.7095 168.701 69.9569C168.387 70.1013 167.616 70.4003 167.328 70.5652C163.11 72.6789 161.917 76.6845 162.21 81.149V81.1593Z"
        fill="#AA7750"
      />
      <path
        d="M178.238 55.0373C172.441 58.1408 174.812 67.5594 168.845 70.3794C162.879 73.1993 162.616 78.3288 163.192 82.1694C163.398 83.5304 164.417 84.4171 165.641 84.9945C165.538 83.0355 165.641 80.2878 166.613 77.7926C166.613 77.7926 165.522 82.1385 166.299 85.2729C166.942 85.5101 167.606 85.675 168.223 85.7936C168.182 85.0048 168.223 84.0305 168.47 82.9943C168.47 82.9943 168.485 84.3914 168.732 85.8812C169.895 86.0617 170.779 86.0668 170.779 86.0668C173.326 83.3603 169.308 75.6274 173.526 73.8231C179.745 71.163 178.984 67.4306 181.144 63.76C183.31 60.0895 185.784 63.1878 185.784 63.1878C187.456 55.8209 184.04 51.9287 178.238 55.0322V55.0373Z"
        fill="#E2AE83"
      />
      <path
        d="M185.203 82.185C185.203 82.185 185.187 82.2056 185.162 82.2366V82.2469C185.136 82.283 185.095 82.3294 185.038 82.3758C185.038 82.3861 185.023 82.3964 185.002 82.4118C184.956 82.4531 184.904 82.5046 184.858 82.5562C184.776 82.6438 184.673 82.7263 184.544 82.814C184.138 83.0872 183.567 83.3295 182.842 83.2006C182.754 83.1851 182.667 83.1645 182.579 83.1336C182.518 83.1336 182.456 83.1336 182.399 83.1233C181.674 83.0253 181.206 82.6335 180.903 82.2469C180.81 82.1232 180.738 82.0046 180.676 81.8963C180.645 81.8345 180.615 81.7726 180.584 81.7159C180.568 81.6953 180.558 81.6798 180.563 81.6695C180.522 81.6076 180.501 81.5509 180.486 81.5097V81.4994C180.47 81.4633 180.465 81.4375 180.465 81.4375C180.465 81.4375 180.47 81.4409 180.481 81.4478H180.491C180.558 81.4787 180.712 81.5355 180.928 81.6076H180.949C181.047 81.6437 181.16 81.685 181.273 81.721C181.494 81.7984 181.726 81.8757 181.947 81.9324C182.168 81.9994 182.384 82.0561 182.538 82.0819C182.626 82.0974 182.723 82.1128 182.826 82.118V82.1283C182.893 82.1489 182.96 82.1592 183.022 82.1644C183.202 82.185 183.449 82.2005 183.711 82.2056C183.922 82.2159 184.133 82.2056 184.339 82.2056C184.462 82.2056 184.58 82.2056 184.683 82.2056H184.704C184.935 82.2056 185.1 82.1953 185.172 82.1902H185.182C185.182 82.1902 185.192 82.1902 185.203 82.1902V82.185Z"
        fill="#591E1E"
      />
      <path
        d="M184.114 81.972L181.349 81.542L181.283 81.9699L184.048 82.3999L184.114 81.972Z"
        fill="#591E1E"
      />
      <path
        d="M184.554 82.8089C184.148 83.0821 183.577 83.3244 182.852 83.1955C182.764 83.18 182.677 83.1594 182.589 83.1285C182.528 83.1285 182.466 83.1285 182.409 83.1182C181.684 83.0202 181.216 82.6284 180.913 82.2418C181.221 82.0665 181.576 81.9479 181.957 81.9325C182.008 81.9325 182.049 81.9325 182.101 81.9325C182.363 81.9222 182.574 81.9479 182.759 81.984V81.9737C182.97 81.9892 183.207 82.0356 183.515 82.1335C183.582 82.1541 183.659 82.1799 183.726 82.2057C184.061 82.3397 184.338 82.5562 184.565 82.8037L184.554 82.8089Z"
        fill="#AF3C3D"
      />
      <path
        d="M185.203 82.1336C185.017 82.1027 184.508 81.6645 183.68 81.484C183.587 81.4686 183.485 81.5046 183.371 81.5562C183.047 81.7108 182.682 81.649 182.42 81.4015C182.322 81.3139 182.23 81.2417 182.132 81.2314C181.288 81.1489 180.671 81.4118 180.486 81.3861C180.455 81.3861 180.434 81.3758 180.429 81.3654C180.429 81.3654 180.429 81.3758 180.434 81.3861C180.445 81.3964 180.46 81.417 180.465 81.4376C180.465 81.4376 180.472 81.4411 180.486 81.4479H180.496C180.522 81.4943 180.553 81.5253 180.604 81.5459C180.64 81.582 180.676 81.6077 180.712 81.6232C180.882 81.7005 181.077 81.7263 181.278 81.7521C181.571 81.7985 181.864 81.814 182.039 81.9171C182.204 82.015 182.482 82.0872 182.744 82.1336C183.027 82.18 183.356 82.2109 183.567 82.1645C183.768 82.1181 184.045 82.1903 184.344 82.2367C184.539 82.2779 184.735 82.3089 184.92 82.2882C184.961 82.2882 185.002 82.2728 185.048 82.247C185.1 82.247 185.146 82.2212 185.177 82.1903H185.187C185.187 82.1903 185.198 82.1851 185.208 82.1903C185.218 82.1748 185.244 82.1594 185.254 82.1491C185.264 82.1439 185.264 82.1336 185.264 82.1336C185.254 82.1439 185.234 82.1336 185.203 82.1336Z"
        fill="#D87374"
      />
      <path
        d="M184.919 82.2627C184.919 82.2627 185.161 82.2575 185.259 82.1338L184.919 82.2627Z"
        fill="#D87374"
      />
      <path
        d="M185.259 82.1334C185.259 82.1334 185.259 82.1437 185.249 82.1489L185.208 82.185L184.93 82.4891C184.93 82.4891 184.889 82.5304 184.868 82.551C184.441 82.9119 183.192 83.149 182.6 83.0511C182.055 82.9634 181.077 82.4221 180.722 81.9581C180.702 81.9221 180.47 81.5148 180.481 81.4375L180.424 81.3704C180.383 81.324 180.661 83.2779 182.24 83.546C182.399 83.5717 182.718 83.6491 182.883 83.6542C184.73 83.7109 185.321 82.0973 185.264 82.1334H185.259Z"
        fill="#C1636D"
      />
      <path
        d="M179.971 101.888C172.744 100.857 162.703 93.8101 162.703 93.8101L161.222 94.4751C161.644 99.1509 161.423 102.693 161.032 105.193C165.646 108.936 171.273 111.544 177.559 112.524C184.482 113.601 191.246 112.544 197.188 109.853C197.635 107.451 198.484 104.332 200.089 100.517L198.875 99.4293C198.875 99.4293 187.173 103.1 179.976 101.888H179.971Z"
        fill="#FF8969"
      />
      <path
        d="M179.971 101.888C172.744 100.857 162.703 93.8101 162.703 93.8101L161.222 94.4751C161.644 99.1509 161.423 102.693 161.032 105.193C165.646 108.936 171.273 111.544 177.559 112.524C184.482 113.601 191.246 112.544 197.188 109.853C197.635 107.451 198.484 104.332 200.089 100.517L198.875 99.4293C198.875 99.4293 187.173 103.1 179.976 101.888H179.971Z"
        fill="#FFA792"
      />
      <path
        d="M199.759 75.184C194.924 70.6835 200.032 62.4299 195.444 57.7077C194.955 57.2025 194.466 56.7901 193.988 56.4498C192.095 54.5475 189.888 53.6608 186.663 55.4342C182.106 52.1813 178.778 54.4238 177.723 55.3363C172.574 58.713 174.627 67.6419 168.84 70.3742C162.873 73.1941 162.611 78.3236 163.187 82.1642C163.393 83.5252 164.411 84.4119 165.635 84.9893C165.533 83.0303 165.635 80.2826 166.608 77.7874C166.608 77.7874 165.517 82.1333 166.294 85.2677C166.937 85.5049 167.6 85.6698 168.218 85.7884C168.176 84.9996 168.218 84.0253 168.465 82.9891C168.465 82.9891 168.48 84.3862 168.727 85.876C169.889 86.0565 170.774 86.0616 170.774 86.0616C173.32 83.3551 169.303 75.6222 173.521 73.8179C179.74 71.1578 178.978 67.4254 181.139 63.7548C183.304 60.0843 185.779 63.1826 185.779 63.1826C185.779 63.1671 185.784 63.1516 185.789 63.1362C185.789 63.1516 185.789 63.1671 185.789 63.1826C185.789 63.1826 189.086 60.9813 190.038 65.1364C190.989 69.2916 189.132 72.6167 194.261 77.0399C197.733 80.0351 191.57 86.1853 193.175 89.5414C193.175 89.5414 194.019 89.8043 195.181 89.9848C195.871 88.6393 196.308 87.3143 196.308 87.3143C196.231 88.3763 195.974 89.3146 195.696 90.0569C196.318 90.1343 197.002 90.1755 197.686 90.1446C199.379 87.3917 199.646 82.9221 199.646 82.9221C199.821 85.5977 199.086 88.2475 198.396 90.0827C199.739 89.9074 200.978 89.3713 201.585 88.134C203.293 84.6491 204.595 79.6846 199.759 75.184Z"
        fill="#C18B63"
      />
      <path
        d="M199.759 75.184C194.924 70.6835 200.032 62.4299 195.444 57.7077C194.955 57.2025 194.466 56.7901 193.988 56.4498C192.095 54.5475 189.888 53.6608 186.663 55.4342C182.106 52.1813 178.778 54.4238 177.723 55.3363C172.574 58.713 174.627 67.6419 168.84 70.3742C162.873 73.1941 162.611 78.3236 163.187 82.1642C163.393 83.5252 164.411 84.4119 165.635 84.9893C165.533 83.0303 165.635 80.2826 166.608 77.7874C166.608 77.7874 165.517 82.1333 166.294 85.2677C166.937 85.5049 167.6 85.6698 168.218 85.7884C168.176 84.9996 168.218 84.0253 168.465 82.9891C168.465 82.9891 168.48 84.3862 168.727 85.876C169.889 86.0565 170.774 86.0616 170.774 86.0616C173.32 83.3551 169.303 75.6222 173.521 73.8179C179.74 71.1578 178.978 67.4254 181.139 63.7548C183.304 60.0843 185.779 63.1826 185.779 63.1826C185.779 63.1671 185.784 63.1516 185.789 63.1362C185.789 63.1516 185.789 63.1671 185.789 63.1826C185.789 63.1826 189.086 60.9813 190.038 65.1364C190.989 69.2916 189.132 72.6167 194.261 77.0399C197.733 80.0351 191.57 86.1853 193.175 89.5414C193.175 89.5414 194.019 89.8043 195.181 89.9848C195.871 88.6393 196.308 87.3143 196.308 87.3143C196.231 88.3763 195.974 89.3146 195.696 90.0569C196.318 90.1343 197.002 90.1755 197.686 90.1446C199.379 87.3917 199.646 82.9221 199.646 82.9221C199.821 85.5977 199.086 88.2475 198.396 90.0827C199.739 89.9074 200.978 89.3713 201.585 88.134C203.293 84.6491 204.595 79.6846 199.759 75.184Z"
        fill="#BC723F"
      />
      <path
        d="M199.759 75.184C194.924 70.6835 200.032 62.4299 195.444 57.7077C194.955 57.2025 194.466 56.7901 193.988 56.4498C192.095 54.5475 189.888 53.6608 186.663 55.4342C182.106 52.1813 178.778 54.4238 177.723 55.3363C172.574 58.713 174.627 67.6419 168.84 70.3742C162.873 73.1941 162.611 78.3236 163.187 82.1642C163.393 83.5252 164.411 84.4119 165.635 84.9893C165.533 83.0303 165.635 80.2826 166.608 77.7874C166.608 77.7874 165.517 82.1333 166.294 85.2677C166.937 85.5049 167.6 85.6698 168.218 85.7884C168.176 84.9996 168.218 84.0253 168.465 82.9891C168.465 82.9891 168.48 84.3862 168.727 85.876C169.889 86.0565 170.774 86.0616 170.774 86.0616C173.32 83.3551 169.303 75.6222 173.521 73.8179C179.74 71.1578 178.978 67.4254 181.139 63.7548C183.304 60.0843 185.779 63.1826 185.779 63.1826C185.779 63.1671 185.784 63.1516 185.789 63.1362C185.789 63.1516 185.789 63.1671 185.789 63.1826C185.789 63.1826 189.086 60.9813 190.038 65.1364C190.989 69.2916 189.132 72.6167 194.261 77.0399C197.733 80.0351 191.57 86.1853 193.175 89.5414C193.175 89.5414 194.019 89.8043 195.181 89.9848C195.871 88.6393 196.308 87.3143 196.308 87.3143C196.231 88.3763 195.974 89.3146 195.696 90.0569C196.318 90.1343 197.002 90.1755 197.686 90.1446C199.379 87.3917 199.646 82.9221 199.646 82.9221C199.821 85.5977 199.086 88.2475 198.396 90.0827C199.739 89.9074 200.978 89.3713 201.585 88.134C203.293 84.6491 204.595 79.6846 199.759 75.184Z"
        fill="#C98B61"
      />
      <path
        d="M147.164 174.104L145.359 160.2L144.186 151.178L143.44 145.456L142.267 136.434L141.012 126.784L139.84 117.762L125.622 8.40858L125.411 6.77436C124.897 2.83059 121.296 0.0519019 117.361 0.567428L6.25506 15.0692C2.32005 15.5847 -0.45246 19.1985 0.06192 23.1423L21.8151 190.477C22.3294 194.421 25.9301 197.199 29.8651 196.684L95.2737 188.142L140.966 182.172C144.901 181.656 147.674 178.048 147.159 174.104H147.164Z"
        fill="#46B6FF"
        fill-opacity="0.2"
      />
      <path
        d="M69.472 101.249C88.4659 101.249 103.863 85.8173 103.863 66.7811C103.863 47.7449 88.4659 32.313 69.472 32.313C50.4781 32.313 35.0806 47.7449 35.0806 66.7811C35.0806 85.8173 50.4781 101.249 69.472 101.249Z"
        fill="white"
      />
      <path
        d="M97.4542 86.8198C91.9246 79.3034 81.1381 79.9117 78.6794 78.958C75.3873 77.6795 75.1661 69.4414 75.1661 69.4414L70.5984 70.3384L70.5624 70.0394L70.0892 70.2508L69.5748 70.1683L69.6108 70.4673L64.966 70.7715C64.966 70.7715 66.864 78.7879 64.0041 80.8706C61.854 82.4378 51.1034 84.6494 47.7754 93.5319C53.9171 98.5325 61.7614 101.409 70.1046 101.244C71.3906 101.213 72.6868 101.115 73.9882 100.95C83.8077 99.6461 92.1047 94.3052 97.4491 86.8198H97.4542Z"
        fill="#C4866A"
      />
      <path
        d="M65.3313 78.5662C68.9371 77.5352 72.5429 76.4886 76.1384 75.4267C75.2588 72.6892 75.1714 69.4414 75.1714 69.4414L70.6037 70.3384L70.5676 70.0394L70.0944 70.2508L69.58 70.1683L69.616 70.4673L64.9712 70.7715C64.9712 70.7715 66.072 75.4318 65.3261 78.5662H65.3313Z"
        fill="#96624D"
      />
      <path
        d="M59.5191 59.3831C58.2486 56.6818 56.1859 57.6149 55.9956 57.7128C55.7898 57.7644 53.614 58.383 54.3753 61.27C55.1468 64.1724 57.0603 65.5488 58.6035 64.8683C60.2186 64.3889 60.7999 62.1 59.5191 59.3831Z"
        fill="#C4866A"
      />
      <path
        d="M77.6146 57.0225C78.1547 54.084 80.3871 54.4603 80.5928 54.5016C80.8089 54.5016 83.067 54.5377 83.067 57.5226C83.067 60.5281 81.565 62.3479 79.8984 62.0901C78.2164 62.0437 77.0693 59.9765 77.6146 57.0225Z"
        fill="#C4866A"
      />
      <path
        d="M79.518 52.4287C76.7866 40.2211 67.5072 42.1388 66.6379 42.345C66.5607 42.345 66.4219 42.3553 66.2315 42.3708C64.1997 42.5461 56.3246 43.9638 56.7669 55.3982C56.7669 55.4652 56.7721 55.5322 56.7772 55.6044C57.3276 68.0543 63.8705 75.3593 70.7581 74.0086C77.7999 73.5447 82.2699 64.7086 79.5231 52.4287H79.518Z"
        fill="#C4866A"
      />
      <path
        d="M79.9555 48.4438C79.2045 42.6648 73.3251 38.6643 66.8285 39.5149L65.8769 39.6438C65.8306 39.6489 65.7792 39.6541 65.7329 39.6593C59.2363 40.5099 54.576 45.8816 55.327 51.6607C55.363 51.9442 55.4144 52.2175 55.4761 52.4958C55.8928 56.4551 56.9987 65.2396 58.9636 69.7866C60.9131 74.2923 64.9767 75.0501 64.9767 75.0501C64.9767 75.0501 67.6927 75.5759 70.9127 75.2254L69.457 64.0385C67.7801 64.0797 63.0684 64.3581 63.4079 66.2759C63.8194 68.606 64.7504 70.0392 63.8091 70.1629C62.8678 70.2867 59.1642 68.8432 58.1869 56.419C58.1869 56.419 60.0953 55.5271 59.5346 51.2019L59.3134 47.8149C59.3134 47.8149 68.7626 48.0211 75.1255 45.7476L75.7787 49.0779C76.3394 53.4032 78.4123 53.7744 78.4123 53.7744C80.6448 66.0387 77.435 68.3844 76.4937 68.5081C75.5524 68.6318 76.0873 67.0028 75.8867 64.6468C75.7221 62.7084 71.0979 63.6467 69.4673 64.0385L70.923 75.2254C74.1276 74.7408 76.6172 73.5345 76.6172 73.5345C76.6172 73.5345 80.3516 71.7559 81.082 66.9048C81.8227 62.0021 80.6396 53.2279 80.0326 49.2944C80.0224 49.016 80.0018 48.7325 79.9658 48.449L79.9555 48.4438Z"
        fill="#352836"
      />
      <path
        d="M79.9555 48.4438C79.2045 42.6648 73.3251 38.6643 66.8285 39.5149L65.8769 39.6438C65.8306 39.6489 65.7792 39.6541 65.7329 39.6593C59.2363 40.5099 54.576 45.8816 55.327 51.6607C55.363 51.9442 55.4144 52.2175 55.4761 52.4958C55.8928 56.4551 56.9987 65.2396 58.9636 69.7866C60.9131 74.2923 64.9767 75.0501 64.9767 75.0501C64.9767 75.0501 67.6927 75.5759 70.9127 75.2254L69.457 64.0385C67.7801 64.0797 63.0684 64.3581 63.4079 66.2759C63.8194 68.606 64.7504 70.0392 63.8091 70.1629C62.8678 70.2867 59.1642 68.8432 58.1869 56.419C58.1869 56.419 60.0953 55.5271 59.5346 51.2019L59.3134 47.8149C59.3134 47.8149 68.7626 48.0211 75.1255 45.7476L75.7787 49.0779C76.3394 53.4032 78.4123 53.7744 78.4123 53.7744C80.6448 66.0387 77.435 68.3844 76.4937 68.5081C75.5524 68.6318 76.0873 67.0028 75.8867 64.6468C75.7221 62.7084 71.0979 63.6467 69.4673 64.0385L70.923 75.2254C74.1276 74.7408 76.6172 73.5345 76.6172 73.5345C76.6172 73.5345 80.3516 71.7559 81.082 66.9048C81.8227 62.0021 80.6396 53.2279 80.0326 49.2944C80.0224 49.016 80.0018 48.7325 79.9658 48.449L79.9555 48.4438Z"
        fill="#504351"
      />
      <path
        d="M66.2266 67.2813L73.7211 66.0234C73.7211 66.0234 71.1852 70.5755 66.2266 67.2813Z"
        fill="white"
      />
      <path
        d="M98.2361 85.6754C94.0182 80.9171 81.9354 79.7365 78.7977 78.4116C78.6023 78.5663 78.3914 78.7055 78.1856 78.8498L72.2754 87.5828L61.7306 81.4223C57.6515 83.7061 48.9431 87.5003 46.4175 92.3566C52.7752 98.1099 61.2316 101.461 70.2693 101.239C71.5038 101.203 72.7486 101.105 73.9985 100.945C84.3067 99.574 92.9432 93.7588 98.2361 85.6702V85.6754Z"
        fill="#273653"
      />
      <path
        d="M98.2361 85.6754C94.0182 80.9171 81.9354 79.7365 78.7977 78.4116C78.6023 78.5663 78.3914 78.7055 78.1856 78.8498L72.2754 87.5828L61.7306 81.4223C57.6515 83.7061 48.9431 87.5003 46.4175 92.3566C52.7752 98.1099 61.2316 101.461 70.2693 101.239C71.5038 101.203 72.7486 101.105 73.9985 100.945C84.3067 99.574 92.9432 93.7588 98.2361 85.6702V85.6754Z"
        fill="#06162D"
      />
      <path
        d="M69.4671 83.4479L72.2036 87.469L73.114 82.4375L74.5697 84.6388L72.1418 88.0103L68.5669 85.5564L69.4671 83.4479Z"
        fill="#E8EEF9"
      />
      <path
        d="M61.1494 81.479L68.1141 89.2015L69.4875 83.4431L62.564 78.1074L61.1494 81.479Z"
        fill="white"
      />
      <path
        d="M80.1351 78.3341L75.876 87.6548L73.1138 82.4377L78.3347 75.5142L80.1351 78.3341Z"
        fill="white"
      />
      <path
        d="M123.201 139.268L26.9043 151.837L28.0296 160.497L124.326 147.928L123.201 139.268Z"
        fill="white"
      />
      <path
        d="M131.686 123.761L25.0654 137.678L26.1907 146.338L132.811 132.421L131.686 123.761Z"
        fill="white"
      />
      <path
        d="M92.5636 110.643L22.7397 119.756L23.865 128.416L93.6889 119.303L92.5636 110.643Z"
        fill="#B8D0E7"
      />
    </g>
    <defs>
      <clipPath id="clip0_4595_1720">
        <rect
          width="252"
          height="214"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
