import { colors } from '@homelight/particle-tokens';
import styled, { css } from 'styled-components';

import { PropsWithChildren } from 'react';

import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import { Paragraph, ParagraphProps } from '../Typography';

type ColorScheme = 'light' | 'dark' | 'blue100';

export type TimelineProps = {
  direction?: 'horizontal' | 'vertical';
  colorScheme?: ColorScheme;
  variant?: 'default' | 'compact';
};

function getMarkerBoxShadow(colorScheme?: ColorScheme) {
  switch (colorScheme) {
    case 'light':
      return 'white';
    case 'dark':
      return '#273653';
    case 'blue100':
      return '#F2F9FE';
    default:
      return 'white';
  }
}

function getMarkerBG(colorScheme?: ColorScheme) {
  switch (colorScheme) {
    case 'light':
      return 'white';
    case 'dark':
      return '#273653';
    case 'blue100':
      return 'white';
    default:
      return 'white';
  }
}

const TimelineStyles = styled(Box)<TimelineProps>`
  ${({ colorScheme, direction, variant }) => css`
    display: flex;
    flex-direction: column;

    .timeline__item {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        background-color: #dbdfe6;
        z-index: 0;
      }

      &::after {
        content: '';
        width: 2px;
        height: 0px;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        background-color: ${colorScheme === 'dark'
          ? '#dbdfe6'
          : colors.electricBlue};
        z-index: 0;
        transition: all 200ms ease-in-out;
      }

      &:not(:last-of-type) {
        padding-bottom: 60px;
      }

      &:last-of-type {
        &::before,
        &::after {
          display: none;
        }
      }

      &--is-active {
        &::after {
          height: 100%;
        }

        .timeline__marker {
          border-color: ${colors.electricBlue};
          background-color: ${colors.electricBlue};

          &::before {
            transform: scale(1);
            opacity: 1;
          }

          .timeline__marker__text {
            color: ${colors.white};
          }
        }
      }
    }

    .timeline__marker {
      width: 32px;
      height: 32px;
      display: flex;
      flex: 0 0 32px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #c5c8cd;
      box-shadow: 0 0 0 6px ${getMarkerBoxShadow(colorScheme)};
      background-color: ${getMarkerBG(colorScheme)};
      z-index: 2;
      position: relative;

      &--tiny {
        width: 10px;
        height: 10px;
      }

      &::before {
        content: '';
        width: 32px;
        height: 32px;
        transform: scale(0);
        transform-origin: center;
        opacity: 0;
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        border-radius: 50%;
        background-color: ${colors.electricBlue};
        transition: all 200ms ease-in-out;
        z-index: 1;
      }

      .timeline__marker__text {
        color: ${colors.coolGray2};
        z-index: 3;
      }
    }

    ${variant === 'compact' &&
    css`
      .timeline__item {
        &:not(:last-of-type) {
          padding-bottom: 28px;
        }
      }

      .timeline__marker {
        width: 10px;
        height: 10px;
        flex: 0 0 10px;
        margin-left: 12px;
        background-color: #dbdfe6;
        border: none;
        box-shadow: 0 0 0 4px #f8f8fb;
      }
    `}

    ${direction === 'horizontal' &&
    css`
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .timeline__item {
        &::before {
          width: calc(100% - (100% - 48px));
          height: 2px;
          left: calc(100% - 48px);
          top: 50%;
        }

        &::after {
          width: 0%;
          height: 2px;
          left: calc(100% - 48px);
          top: 50%;
        }

        &:not(:last-of-type) {
          padding-bottom: 0;
          padding-right: 60px;
        }

        &--is-active {
          &::after {
            width: calc(100% - (100% - 48px));
          }
        }
      }
    `}
  `}
`;

export const Root = ({
  direction = 'vertical',
  colorScheme = 'light',
  variant = 'default',
  children,
}: PropsWithChildren<TimelineProps>) => (
  <TimelineStyles
    colorScheme={colorScheme}
    direction={direction}
    variant={variant}
  >
    {children}
  </TimelineStyles>
);

const Item = ({
  children,
  isActive,
  ...props
}: PropsWithChildren<{ isActive?: boolean } & BoxTypes>) => (
  <Box
    {...props}
    className={`timeline__item ${
      isActive ? 'timeline__item--is-active' : ''
    }`.trim()}
  >
    {children}
  </Box>
);

type MarkerProps = BoxTypes & {
  isActive?: boolean;
  showIcon?: boolean;
};

const Marker = ({ children, isActive, showIcon, ...props }: MarkerProps) => (
  <Box {...props} className="timeline__marker">
    {showIcon ? (
      <Icon width="16px" type="check" color="white" fill="white" zIndex="3" />
    ) : (
      <Paragraph className="timeline__marker__text">{children}</Paragraph>
    )}
  </Box>
);

type LabelProps = PropsWithChildren<
  ParagraphProps & {
    isActive?: boolean;
  }
>;

const Label = ({ children, isActive, ...props }: LabelProps) => (
  <Paragraph ml="12px" fontWeight={isActive ? 'bold' : 'regular'} {...props}>
    {children}
  </Paragraph>
);

export const Timeline = {
  Root,
  Item,
  Marker,
  Label,
};
