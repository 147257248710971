import styled, { css } from 'styled-components';

import { Box } from '../Box';

const Card = styled(Box)<{ inactive?: boolean; selected?: boolean }>`
  ${({ theme, inactive, selected }) => css`
    transition: all 0.2s ease-out;
    border-radius: 8px;
    border: 1px solid
      ${selected ? theme.colors.electricBlue : theme.colors.coolGray4};
    color: ${inactive ? theme.colors.coolGray2 : theme.colors.darkBlue};
    background-color: ${inactive
      ? theme.colors.coolGray6
      : selected
      ? theme.colors.blue100
      : theme.colors.white};
    transition: all 0.25s;

    &:hover {
      ${!inactive &&
      css`
        cursor: pointer;
      `}
      box-shadow: ${inactive ? 'none' : '0px 4px 20px rgba(55, 106, 206, 0.1)'};
    }
  `}
`;

export default Card;
