export const localStorageKeys = {
  SELF_SERVICE_LOCAL_UUID: 'SELF_SERVICE_LOCAL_UUID',
  EXPRESS_APPROVAL_CLIENT_TOKEN: 'EXPRESS_APPROVAL_CLIENT_TOKEN',
  EXPRESS_APPROVAL_EVENTS: {
    ADDRESS: 'EXPRESS_APPROVAL_EVENTS_ADDRESS',
    CONTACT: 'EXPRESS_APPROVAL_EVENTS_CONTACT',
    HOME_INFO: 'EXPRESS_APPROVAL_EVENTS_HOME_INFO',
    IDENTITY_CHECK: 'EXPRESS_APPROVAL_EVENTS_IDENTITY_CHECK',
    UPLOAD_PHOTOS: 'EXPRESS_APPROVAL_EVENTS_UPLOAD_PHOTOS',
  },
  DOCUMENT_CENTER: {
    GREETING_MODAL_SEEN: 'GREETING_MODAL_SEEN',
  },
};
