import { useEffect, useMemo, useState } from 'react';

import { Box } from '../../Box';
import { Paragraph } from '../../Typography';
import { LoadingBar, LoadingBarProgress } from '../styles';

export interface FileLoadingProps {
  labelText: string;
  progress?: number;
  mockDuration?: number;
}

export const FileLoading = ({
  labelText,
  progress,
  mockDuration = 2,
}: FileLoadingProps): React.ReactElement => {
  const [progressWidth, setProgressWidth] = useState(0);
  const mock = useMemo(() => typeof progress === 'undefined', [progress]);

  // if progress is manually controlled
  useEffect(() => {
    // pull out of call stack to allow files added by browser picker to show
    // progress bar transition animation
    const timeout = setTimeout(
      () => setProgressWidth(mock ? 99 : progress || 0),
      0
    );
    return () => timeout && clearTimeout(timeout);
  }, [progress, mock]);

  return (
    <Box flexDirection="column" alignItems="center" width="100%">
      <LoadingBar m="12px">
        <LoadingBarProgress
          percentage={progressWidth}
          mock={mock}
          mockDuration={mockDuration}
        />
      </LoadingBar>
      <Paragraph variant="text-small" color="coolGray2">
        {labelText}
      </Paragraph>
    </Box>
  );
};
