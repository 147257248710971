import { formatCurrency } from '@hl-portals/helpers';

export function getEquityUnlockRange(
  low_end_range: number,
  high_end_range: number
) {
  const highRange = formatCurrency({
    value: high_end_range > 0 ? high_end_range : 0,
  });

  const lowRange = formatCurrency({
    value: low_end_range > 0 ? low_end_range : 0,
  });

  const range =
    low_end_range <= 0 && high_end_range <= 0
      ? '$0'
      : `${lowRange} - ${highRange}`;

  return range;
}
