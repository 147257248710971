import styled, { css } from 'styled-components';

const PaginationButton = styled.div<{ active?: boolean; isLink?: boolean }>`
  ${({ theme, active, isLink }) => css`
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: ${active ? theme.colors.white : theme.colors.darkBlue};
    font-size: 14px;

    background: ${active ? theme.colors.electricBlue : theme.colors.white};
    border-radius: 16px;
    margin: 0px 4px;
    font-weight: 700;
    user-select: none;
    outline: none;
    ${isLink ? 'cursor: pointer;' : ''}
  `}
`;

export default PaginationButton;
