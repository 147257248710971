type Obj = Record<string, any>;

function getNestedValue<T extends object>(key: string, obj: T): any {
  const props = key.split('.');
  const [firstProp, ...rest] = props;
  const value = obj[firstProp as keyof T];

  if (props.length === 1) return value;
  if (!Object.hasOwn(obj, firstProp)) return;
  if (Array.isArray(value)) return;
  if (typeof value === 'string') return value;

  return getNestedValue(rest.join('.'), value as object);
}

type SortByPriorityArgs<T, P> = {
  key: string;
  priorityList: P[];
  listToBeSorted: T[];
};

type TempObj<T, P extends string> = Record<P | '_other', T[]>;

export function sortByPriority<T extends Obj, P extends string>({
  key,
  priorityList,
  listToBeSorted,
}: SortByPriorityArgs<T, P>): T[] {
  const sortedObj = listToBeSorted.reduce((obj, item) => {
    const tempObj = { ...obj };
    const p = getNestedValue(key, item);
    const isOther = !p || !key || !priorityList.includes(p);

    if (!Object.entries(tempObj).length) {
      [...priorityList, '_other'].forEach((li) => {
        tempObj[li as keyof TempObj<T, P>] = [];
      });
    }

    if (isOther) {
      return { ...tempObj, _other: [...tempObj._other, item] };
    }

    return { ...tempObj, [p]: [...tempObj[p as keyof TempObj<T, P>], item] };
  }, {} as TempObj<T, P>);

  return Object.keys(sortedObj).flatMap(
    (k) => sortedObj[k as keyof TempObj<T, P>]
  );
}
