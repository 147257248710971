import {
  BordersProps,
  FlexboxProps,
  PositionsProps,
  SizingProps,
  SpacingProps,
} from '@mui/system';

import { DismissAction, PanelWrapper } from './styles';

export type PanelProps = {
  children: React.ReactNode;
  type?: 'normal' | 'alert';
  noBorder?: boolean;
  contrast?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
} & FlexboxProps &
  SpacingProps &
  SizingProps &
  BordersProps &
  PositionsProps;

export const Panel = ({
  onClick,
  onDismiss,
  children,
  type = 'normal',
  contrast,
  ...extraProps
}: PanelProps): React.ReactElement => {
  const hasDismissAction = typeof onDismiss !== 'undefined';
  return (
    <PanelWrapper
      type={type}
      contrast={contrast}
      onClick={onClick}
      {...extraProps}
    >
      {hasDismissAction && (
        <DismissAction data-test="panel-dismiss-button" onClick={onDismiss} />
      )}
      {children}
    </PanelWrapper>
  );
};
