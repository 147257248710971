import { ComponentRef, useRef } from 'react';

export const useScrollIntoView = () => {
  const targetRef = useRef<ComponentRef<'div'>>(null);

  const scrollIntoView = () => {
    targetRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return { targetRef, scrollIntoView };
};
