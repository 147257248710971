import {
  DisplayProps,
  FlexboxProps,
  SizingProps,
  SpacingProps,
  display,
  flexbox,
  sizing,
  spacing,
} from '@mui/system';
import styled from 'styled-components';

import { Colors } from '@hl-portals/constants';

const Divider = styled.div<
  DisplayProps & SpacingProps & SizingProps & FlexboxProps & { color?: Colors }
>`
  height: 1px;
  width: 100%;
  background-color: ${({ theme, color }) => theme.colors[color ?? 'coolGray4']};
  ${display};
  ${spacing};
  ${sizing};
  ${flexbox};
`;

export default Divider;
