import { theme } from '@hl-portals/constants';

import { useScreenSize } from '@hl-portals/hooks';

import { Box, BoxTypes } from '../Box';
import { Icon, IconType, IconTypeProp } from '../Icon';

type TabType = {
  value: string;
  label: string;
  icon?: IconTypeProp;
  iconProps?: Omit<IconType, 'type'>;
};
export type SelectTabsProps = {
  tabs: TabType[];
  selected: string;
  onChange: (value: string) => void;
  name: string;
  maxRowLength?: number;
} & BoxTypes;

export const SelectTabs = ({
  selected,
  tabs,
  onChange,
  name,
  maxRowLength = 2,
  ...props
}: SelectTabsProps): React.ReactElement => {
  const { isDesktop } = useScreenSize();

  const onTabClick = (tab: TabType) => () => {
    onChange(tab.value);
  };

  const columnFormat = !isDesktop && tabs.length > maxRowLength;

  const selectedProps = {
    boxShadow: '0px 2px 4px 0px rgba(108, 129, 171, 0.30)',
    bgcolor: theme.colors.white,
    borderRadius: '8px',
  };

  return (
    <Box
      p="8px"
      border={`1px solid ${theme.colors.coolGray6}`}
      bgcolor={theme.colors.gray50}
      borderRadius="12px"
      gap="10px"
      width="100%"
      flexDirection={columnFormat ? 'column' : 'row'}
      {...props}
    >
      {tabs.map((tab) => (
        <Box
          key={tab.value}
          onClick={onTabClick(tab)}
          width="100%"
          gap="12px"
          cursor="pointer"
          p="12px 16px"
          transition="all .2s ease-out"
          alignItems="center"
          justifyContent="center"
          {...(tab.value === selected ? selectedProps : {})}
        >
          {tab.icon && <Icon type={tab.icon} {...(tab.iconProps || {})} />}
          {tab.label}
        </Box>
      ))}
    </Box>
  );
};
