import { css } from 'styled-components';
import { SIZES_BREAKPOINTS } from './mediaQueries';

export const buildPropertyRules =
  (property: string, valueList: string[]) => () =>
    valueList.reduce(
      (acc, value) => ({
        ...acc,
        [value]: [property, ':', value, ';'],
      }),
      {}
    );

export const addMediaQueries = ({ styles, prop, theme }) => {
  const stylesMap = styles(theme);
  if (typeof prop === 'string') {
    return stylesMap[prop];
  }
  const entries = Object.entries(prop);
  const mediaQueries = entries
    .map(
      ([mediaKey, option]) =>
        SIZES_BREAKPOINTS[mediaKey]`${stylesMap[option as string]}`
    )
    .join('')
    .split(',')
    .join('');

  return css`
    ${mediaQueries}
  `;
};
