/* eslint-disable import/no-cycle */
import { SizingProps, SpacingProps } from '@mui/system';

import { TextareaHTMLAttributes, forwardRef } from 'react';

import { Box } from '../Box';
import { Paragraph } from '../Typography';
import { TextAreaInput } from './styles';

export type TextAreaProps = {
  value?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isError?: boolean;
  containerWidth?: string;
  maxLength?: number;
  flex?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  SizingProps &
  SpacingProps;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      value,
      onClick,
      isError,
      containerWidth,
      maxLength,
      maxWidth,
      flex,
      ...otherProps
    },
    forwardedRef
  ): React.ReactElement => (
    <Box
      flexDirection="column"
      width={containerWidth}
      maxWidth={maxWidth}
      flex={flex}
    >
      <TextAreaInput
        ref={forwardedRef}
        onClick={onClick}
        value={value}
        isError={isError}
        maxLength={maxLength}
        {...otherProps}
      />
      {maxLength && (
        <Paragraph
          variant="text-small"
          color="coolGray2"
          mt="4px"
          textAlign="right"
        >
          {value ? value.length : 0} / {maxLength}
        </Paragraph>
      )}
    </Box>
  )
);
