import { AxiosError } from 'axios';

import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import {
  GET_PROPERTY_UUID,
  GET_PROPERTY_UUID_PUBLIC,
} from '@hl-portals/constants';

import { enhancedAxios, handleErrors } from '@hl-portals/helpers';

type PropertyUUIDResponse = {
  uuid: string;
};

export const USE_PROPERTY_UUID_QUERY_KEY = 'USE_PROPERTY_UUID_QUERY_KEY';

export const usePropertyUUID = (
  fullAddress: string,
  options?: UseQueryOptions<string> & {
    isPublic?: boolean;
  }
) => {
  const encodedAddress = encodeURIComponent(fullAddress);
  const propertyUUIDUrl = GET_PROPERTY_UUID(encodedAddress);
  const propertyUUIDPublicUrl = GET_PROPERTY_UUID_PUBLIC(encodedAddress);

  return useQuery<string>(
    [USE_PROPERTY_UUID_QUERY_KEY, fullAddress],
    async ({ signal }) => {
      if (options?.isPublic) {
        const {
          data: {
            data: {
              attributes: { uuid },
            },
          },
        } = await http.public.get(propertyUUIDPublicUrl);

        return uuid;
      } else {
        const { uuid } = await enhancedAxios<PropertyUUIDResponse>({
          url: options?.isPublic ? propertyUUIDPublicUrl : propertyUUIDUrl,
          translateJsona: true,
          signal,
        });
        return uuid;
      }
    },
    {
      ...options,
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
        handleErrors(error as AxiosError);
      },
    }
  );
};
