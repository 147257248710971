import { colors, fontWeights, spacings } from '@homelight/particle-tokens';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import { useModal } from '@hl-portals/hooks';

import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Modal } from '../Modal';
import BureauCard from './BureauCard';
import bureauList from './bureauList';

const Header = styled(Box)`
  height: 56px;
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  justify-content: flex-end;
`;

const Content = styled(Box)`
  height: auto;
  flex-direction: column;
  padding: 0 40px;
  overflow-y: scroll;
`;

const TitleContainer = styled(Box)`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  svg {
    position: initial;
  }
`;

const Footer = styled(Box)`
  padding: 16px 36px 40px;
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  justify-content: flex-end;
  min-height: 90px;
`;

type FrozenCreditModalProps = {
  experian: boolean;
  equifax: boolean;
  transunion: boolean;
};

const getBureauNames = ({
  equifax,
  experian,
  transunion,
}: {
  equifax: boolean;
  experian: boolean;
  transunion: boolean;
}): string => {
  const bureauArr: Array<string> = [];

  if (equifax) bureauArr.push('Equifax');
  if (experian) bureauArr.push('Experian');
  if (transunion) bureauArr.push('TransUnion');

  if (bureauArr.length) {
    return bureauArr.reduce((acc, curr, idx, arr) => {
      if (arr.length - 1 === idx) {
        return acc + ` and ${curr}`;
      }
      return acc + `, ${curr}`;
    });
  }

  return '';
};

const FrozenCreditModal = ({
  experian,
  equifax,
  transunion,
}: FrozenCreditModalProps) => {
  const { closeModal } = useModal();

  return (
    <Modal
      borderRadius={4}
      maxWidth={659}
      flexDirection={'column'}
      height={'auto'}
    >
      <Header>
        <Icon type="times" size={24} onClick={() => closeModal()} />
      </Header>
      <Content>
        <TitleContainer>
          <Icon type="exclamationCircle" size={36} />
          <Typography
            fontSize={24}
            color={colors.darkBlue}
            fontWeight={fontWeights.fontWeightBold}
            marginLeft={`${spacings.spacingNano}px`}
          >
            Your credit is frozen
          </Typography>
        </TitleContainer>

        <div style={{ marginBottom: spacings.spacingXs }}>
          <Typography color={colors.darkBlue} lineHeight={'24px'}>
            We aren't able to continue with your Express Approval application
            because it appears that your credit score is frozen. <br />
            <br />
            Please contact{' '}
            {getBureauNames({
              experian,
              equifax,
              transunion,
            })}{' '}
            to unfreeze your credit.
          </Typography>
        </div>
        <ol style={{ listStyle: 'initial', marginTop: 0 }}>
          {bureauList
            .filter(
              (bureau) =>
                (bureau.type === 'equifax' && equifax) ||
                (bureau.type === 'experian' && experian) ||
                (bureau.type === 'transunion' && transunion)
            )
            .map((bureau) => (
              <li>
                <BureauCard
                  name={bureau.name}
                  phone={bureau.phone}
                  mail={bureau.mail}
                  website={bureau.website}
                />
              </li>
            ))}
        </ol>
      </Content>
      <Footer>
        <Button
          width={63}
          height={48}
          alignSelf={'flex-end'}
          onClick={() => closeModal()}
        >
          Ok
        </Button>
      </Footer>
    </Modal>
  );
};

export default FrozenCreditModal;
