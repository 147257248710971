import { usePublicEvent } from '@hl-portals/hooks';

import { Flow } from '..../../../apps/equity-app/modules/lead-submission/metadata/types';

type Device = 'mobile_web' | 'desktop_web';

export type CoreEventData = {
  snapshot_uuid: string;
  flow: Flow;
  breadcrumb: string[];
  device: Device;
};

export type LeadSubmissionEvent = {
  event_action: string;
  event_payload: CoreEventData & {
    [key: string]: string | number | boolean | Array<any> | Record<any, any>;
  };
};

export function useLeadSubmissionEvent() {
  const { recordPublicEvent } = usePublicEvent();

  function logLeadSubmissionEvent({
    event_action,
    event_payload,
  }: LeadSubmissionEvent) {
    recordPublicEvent({
      source: 'bbys_lead_submission',
      event_action,
      event_payload,
    });
  }

  return { logLeadSubmissionEvent };
}
