/* eslint-disable @typescript-eslint/no-shadow */
import { DisplayProps, display } from '@mui/system';
import styled, { css } from 'styled-components';

import {
  media,
  paragraphHelper,
  slideInFromRight,
  slideInFromTop,
} from '@hl-portals/helpers';

import { AvatarWrapper } from '../Avatar/styles';

interface INavbarItem {
  active?: boolean;
  isElite?: boolean;
  cursor?: 'pointer' | 'auto';
  isSearchOpen?: boolean;
  withBorderTop?: boolean;
}

export const NavbarSection = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 12px 0;
    text-align: left;

    &::before,
    &::after {
      content: '';
      width: calc(100% - 32px);
      height: 1px;
      margin-right: 16px;
      margin-left: 16px;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.coolGray4};
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &:nth-child(3) {
      &::before {
        display: none;
      }
    }
  `}
`;

export const NavbarItem = styled.div<INavbarItem & DisplayProps>`
  ${({ theme, active, isElite, isSearchOpen }) => css`
    box-sizing: border-box;
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
    color: ${theme.colors.darkBlue};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 16px;

    ${display}
    ${media.desktop`
      width: auto;
      position: relative;
      ${
        active && !isSearchOpen
          ? `border-bottom: 3px solid ${theme.colors.darkBlue};`
          : ''
      }
      ${
        isElite && active && !isSearchOpen
          ? `border-bottom: 3px solid ${theme.colors.electricBlue};`
          : ''
      }
    `}
    > a,
    > span {
      color: ${isElite ? theme.colors.silver : theme.colors.darkBlue};
      ${paragraphHelper({ type: 'text-small', theme })}
      ${active ? `color: ${theme.colors.darkBlue};` : ''};
      ${active && isElite ? `color: ${theme.colors.white};` : ''};
      ${active ? 'font-weight: 600;' : ''}
      text-decoration: none;

      ${media.desktop`
        &:hover {
          color: ${theme.colors.electricBlue};
          &::before,
          &::after {
            background-color: ${theme.colors.electricBlue};
          }
        }
      `}
    }

    a svg {
      display: none;
      cursor: pointer;
      fill: ${isElite ? theme.colors.silver : theme.colors.coolGray3};
      width: 20px;
      height: 20px;

      ${media.desktop`
        display: block;
          &:hover {
            fill: ${theme.colors.electricBlue};
         }
      `}
    }

    &:hover {
      background-color: ${theme.colors.coolGray6};
      a {
        color: ${theme.colors.darkBlue};
      }
    }
  `}
`;

interface INavbarDropdown {
  isOpen: boolean;
}

export const NavbarDropdownWrapper = styled.nav<INavbarDropdown>`
  ${({ isOpen }) => css`
    width: 100%;
    height: ${isOpen ? '100%' : '0px'};
    display: ${isOpen ? 'block' : 'none'};
    opacity: ${isOpen ? '1' : '0'};
  `}
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  transition: opacity 0.3s ease-out;
  background: #fff;
  z-index: 5;
  cursor: auto;
  animation: 0.3s ease 0s 1 normal ${slideInFromRight};

  ${media.desktop`
    width: 288px;
    height: auto;
    position: absolute;
    border-radius: 12px;
    overflow: visible;
    display: ${({ isOpen }: INavbarDropdown) => (isOpen ? 'block' : 'none')};
    animation: 0.3s ease 0s 1 normal ${slideInFromTop};
    left: auto;
    right: 0;
    top: calc(100% + 20px);
    background-color: white;
    box-shadow: 0px 14px 48px -10px rgba(108, 129, 171, 0.2);

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: -6px;
      right: 44px;
      transform: rotate(45deg);
      background-color: white;
      border-radius: 2px;
    }
  `}
`;

const ArrowDown = css`
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #666;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: rotate(45deg) translateY(-50%);
  }
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: #666;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: rotate(-45deg) translateY(-50%);
  }
`;

export const DropdownButton = styled.span<{ isElite: boolean }>`
  ${({ theme, isElite }) => css`
    cursor: pointer;
    position: relative;

    ${media.desktop`
      padding-right: 20px !important;
      margin-right: 15px;

      ${ArrowDown}
      ${AvatarWrapper} {
        display: flex;
      }
      > span { display: none}
    `}

    &:focus {
      outline: none;
    }

    svg {
      display: none;
      fill: ${isElite ? theme.colors.silver : theme.colors.coolGray3};
      width: 20px;
      height: 20px;
      ${media.desktop`
        display: block;
      `}
    }
    &:hover {
      svg {
        fill: ${theme.colors.electricBlue};
      }
      ::before,
      ::after {
        background-color: ${theme.colors.electricBlue};
      }
    }
  `}
`;

export const NavbarFooterWrapper = styled.footer`
  padding: 12px 0;
`;

export const NavbarHeaderWrapper = styled.header`
  padding-bottom: 22px;
`;

export const NavbarProfile = styled.div`
  padding: 22px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkBlue};
  overflow: hidden;
`;
