/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useSWR from 'swr';
import { MutatorCallback } from 'swr/dist/types';

import { http } from '@hl-portals/libs/http';

import { MAPBOX_PLACES_SEARCH_API } from '@hl-portals/constants';

import { config, getGeoinfoFromPlaces } from '@hl-portals/helpers';

import { useDebounce } from '@hl-portals/hooks';

type UseMapboxPlaces = (
  term: string,
  options?: { bbox?: string; filterByStateCode?: StateCode; enabled?: boolean },
  searchType?: string
) => {
  isError: boolean;
  isLoading: boolean;
} & (
  | {
      data?: TransformedMapboxPlace[];
      isSuccessful: true;
      mutate: (
        data?:
          | void
          | MapboxGeocodingPlacesResponse
          | Promise<void | MapboxGeocodingPlacesResponse>
          | MutatorCallback<void | MapboxGeocodingPlacesResponse>,
        shouldRevalidate?: boolean
      ) => Promise<
        | void
        | MapboxGeocodingPlacesResponse
        | Promise<void | MapboxGeocodingPlacesResponse>
        | MutatorCallback<void | MapboxGeocodingPlacesResponse>
        | undefined
      >;
    }
  | {
      data: undefined;
      isSuccessful: false;
    }
);

const getMapboxPlaces =
  ({
    debouncedTerm,
    bbox,
    searchType,
  }: {
    debouncedTerm: string;
    bbox?: string;
    searchType?: string;
  }) =>
  async (): Promise<MapboxGeocodingPlacesResponse | void> =>
    http.public.get(MAPBOX_PLACES_SEARCH_API({ search: debouncedTerm }), {
      params: {
        autocomplete: true,
        access_token: config.mapboxAccessToken,
        country: 'us',
        types: searchType,
        proximity: '',
        ...(bbox ? { bbox } : {}),
      },
    });

const useMapboxPlaces: UseMapboxPlaces = (
  term,
  options = { enabled: true },
  searchType = 'address'
) => {
  const debouncedTerm = useDebounce(term, 500);
  const { data, error, isValidating, mutate } = useSWR(
    () =>
      term.length > 2 && debouncedTerm && options.enabled
        ? ['MAPBOX_INFO_PLACES', debouncedTerm]
        : null,
    getMapboxPlaces({ debouncedTerm, bbox: options.bbox, searchType }),
    {
      revalidateOnFocus: false,
    }
  );

  if (!data) {
    return {
      data: undefined,
      isSuccessful: false,
      isError: Boolean(error),
      isLoading: isValidating,
    };
  }

  const features = data?.data?.features;
  const transformedResult = features
    ? getGeoinfoFromPlaces({ features })
    : undefined;

  const finalResult = options.filterByStateCode
    ? transformedResult?.filter(
        (tr) => tr.stateCode === options.filterByStateCode
      )
    : transformedResult;

  return {
    data: finalResult,
    isSuccessful: true,
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};

export default useMapboxPlaces;
