import styled, { css } from 'styled-components';
import { Colors } from '@hl-portals/constants';

interface CircleBackgroundProps {
  color: Colors;
  size: number;
}

const CircleBackground = styled.div<CircleBackgroundProps>`
  ${({ theme, color, size }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors[color] || theme.colors.coolGray6};
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
  `}
`;

export default CircleBackground;
